import makeValidationSchema from './StatusNotificationMakeValidationSchema'
import React, { useEffect, useContext } from 'react'
import { Grid } from '@material-ui/core'
import {
  components,
  constants
} from 'cng-web-lib'
import { useFormContext, useWatch } from "react-hook-form";
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { AesTranslationContext } from '../contexts/AESTranslationContext'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngSelectField,
      CngLookupAutocompleteField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem,
} = components

const {
  filter: { EQUAL }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  headerId: null,
  name: "",
  email: "",
  phoneNumber: "",
  statusNotificationType: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { getTranslatedText } = useContext(AesTranslationContext)
  const { setValue, getValues, reset } = useFormContext();

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  return (

    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.name}>
        <CngTextField
          required
          name="name"
          label={getTranslatedText('AES', 'snName')}
          disabled={disabled}
          onChange={(e) => {
            setValue("name", e.target.value.toUpperCase(), { shouldValidate: true })
          }}
          size='small'
          onBlur={(e) => {
            if (e.target.value !== undefined) {
              setValue('name', e.target.value.trim(), {
                shouldValidate: true
              })
            }
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.statusNotificationType}>
        <CngCodeMasterAutocompleteField
          required
          name="statusNotificationType"
          label={getTranslatedText('AES', 'snType')}
          disabled={disabled}
          codeType='AES_STATUS_NOTIFICATION_TYPE'
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.phoneNumber}>
        <CngTextField
          name="phoneNumber"
          label={getTranslatedText('AES', 'snPhoneNumber')}
          disabled={disabled}
          onChange={(e) => {
            setValue("phoneNumber", e.target.value.toUpperCase(), { shouldValidate: true })
          }}
          onBlur={(e) => {
            if (e.target.value !== undefined) {
              setValue('phoneNumber', e.target.value.trim(), {
                shouldValidate: true
              })
            }
          }}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.email}>
        <CngTextField
          required
          name="email"
          label={getTranslatedText('AES', 'snEmail')}
          disabled={disabled}
          onChange={(e) => {
            setValue("email", e.target.value.toUpperCase(), { shouldValidate: true })
          }}
          onBlur={(e) => {
            if (e.target.value !== undefined) {
              setValue('email', e.target.value.trim(), {
                shouldValidate: true
              })
            }
          }}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const StatusNotificationFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default StatusNotificationFormProperties
