import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import AesShipmentKeys from 'src/constants/locale/key/AesShipment'
import UsedVehicleDetailsEntry from './UsedVehicleDetailsEntry'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const errMsgAlphaNumericSpace = translate(
    Namespace.AES_SHIPMENT,
    AesShipmentKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_SPACE
  )
  const errMsgAlphaNumericWithSpecialChar = translate(
    Namespace.AES_SHIPMENT,
    AesShipmentKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_SPECIAL_CHAR
  )

  const errMsgNumeric = translate(
    Namespace.AES_SHIPMENT,
    AesShipmentKeys.ValidationMessage.ERROR_MSG_NUMERIC
  )

  const errMsgMinLength = translate(
    Namespace.AES_SHIPMENT,
    AesShipmentKeys.ValidationMessage.ERROR_MSG_MIN_LENGTH
  )
  const errMsgMaxLength = translate(
    Namespace.AES_SHIPMENT,
    AesShipmentKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )

  const regexNumeric = '^[0-9]*$'
  const regexAlphaNumericSpace = '^[a-zA-Z0-9 ]*$'
  const regexAlphaNumericWithSpecialChar =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

  return Yup.object({
    headerId: Yup.string().nullable(),
    productId: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(25, errMsgMaxLength + " 25"),
    commodityDescription: Yup.string().nullable().required(requiredMessage).matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(45, errMsgMaxLength + " 45"),
    htsNumber: Yup.string().nullable().required(requiredMessage).matches(regexAlphaNumericSpace, errMsgAlphaNumericSpace).max(10, errMsgMaxLength + " 10"),
    originOfGoods: Yup.string().nullable(),
    quantity1: Yup.string().nullable().matches(regexNumeric, errMsgNumeric).max(10, errMsgMaxLength + " 10"),
    uom1: Yup.string().nullable(),
    quantity2: Yup.string().nullable().matches(regexNumeric, errMsgNumeric).max(10, errMsgMaxLength + " 10"),
    uom2: Yup.string().nullable(),
    valueOfGoods: Yup.string().nullable().matches(regexNumeric, errMsgNumeric).max(10, errMsgMaxLength + " 10"),
    exportInformationCode: Yup.string().nullable().required(requiredMessage),
    shippingWeight: Yup.string().nullable().matches(regexNumeric, errMsgNumeric).max(10, errMsgMaxLength + " 10"),
    licenseExemptionCode: Yup.string().nullable().required(requiredMessage),
    eccn: Yup.string().nullable().matches(regexAlphaNumericSpace, errMsgAlphaNumericSpace).max(5, errMsgMaxLength + " 5"),
    exportLicenseNumber: Yup.string().nullable().required(requiredMessage).matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(14, errMsgMaxLength + " 14"),
    licenseValue: Yup.string().nullable().matches(regexNumeric, errMsgNumeric).max(10, errMsgMaxLength + " 10"),
    itarExemptionCode: Yup.string().nullable(),
    registrationNumber: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(6, errMsgMaxLength + " 6"),
    eligiblePartyCertificationInd: Yup.string().nullable(),
    usmlCategoryCode: Yup.string().nullable(),
    ddtcQuantity: Yup.string().nullable().matches(regexNumeric, errMsgNumeric).max(7, errMsgMaxLength + " 7"),
    ddtcUom: Yup.string().nullable(),
    significantMilitaryEquipmentInd: Yup.string().nullable(),
    actionCode: Yup.string().nullable(),
    status: Yup.string().nullable(),
    additionalPgaDataRequiredInd: Yup.string().nullable(),
    deliveryDetails: Yup.string().nullable(),
    usedVehicleDetails: Yup.array(
      Yup.object({
        vehicleIdType: Yup.string().nullable().required(requiredMessage),
        vehicleIdNumber: Yup.string().nullable().required(requiredMessage).matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(25, errMsgMaxLength + " 25"),
        vehicleTitleNumber: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(15, errMsgMaxLength + " 15"),
        vehicleTitleState: Yup.string().nullable()
      })
    ),
    pgaDetails: Yup.array(
      Yup.object({
        pgaId: Yup.string().nullable().required(requiredMessage),
        amsCertificateNumber: Yup.string()
          .when('pgaId', {
            is: (value) => value == 'AM1',
            then: Yup.string().nullable().required(requiredMessage).matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(13, errMsgMaxLength + " 13"),
            otherwise: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(13, errMsgMaxLength + " 13")
          }).nullable(),
        epaLicenseRequired: Yup.string().nullable(),
        epaConsentNumber: Yup.string()
          .when('epaLicenseRequired', {
            is: (value) => value == 'true',
            then: Yup.string().nullable().required(requiredMessage).matches(regexAlphaNumericSpace, errMsgAlphaNumericSpace).max(12, errMsgMaxLength + " 12"),
            otherwise: Yup.string().nullable().matches(regexAlphaNumericSpace, errMsgAlphaNumericSpace).max(12, errMsgMaxLength + " 12")
          })
          .nullable(),
        rcraHazardousWasteTrackingNumber: Yup.string()
          .when('epaLicenseRequired', {
            is: (value) => value == 'true',
            then: Yup.string().nullable().required(requiredMessage).matches(regexAlphaNumericSpace, errMsgAlphaNumericSpace).max(12, errMsgMaxLength + " 12"),
            otherwise: Yup.string().nullable().matches(regexAlphaNumericSpace, errMsgAlphaNumericSpace).max(12, errMsgMaxLength + " 12")
          })
          .nullable(),
        epaNetQuantity: Yup.string()
          .when('epaLicenseRequired', {
            is: (value) => value == 'true',
            then: Yup.string().nullable().required(requiredMessage).matches(regexNumeric, errMsgNumeric).max(10, errMsgMaxLength + " 10"),
            otherwise: Yup.string().nullable().matches(regexNumeric, errMsgNumeric).max(10, errMsgMaxLength + " 10")
          })
          .nullable(),
        epaUom: Yup.string()
          .when('epaLicenseRequired', {
            is: (value) => value == 'true',
            then: Yup.string().nullable().required(requiredMessage),
            otherwise: Yup.string().nullable()
          })
          .nullable(),
      })
    ),
  })
}

export default makeValidationSchema
