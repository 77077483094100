import React, { useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AnnouncementKeys from 'src/constants/locale/key/Announcement'
import { Grid, Card, Typography } from '@material-ui/core'
import {
  components,
  DataFlattener,
  DateTimeFormatter,
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import AnnouncementWhiteLabelGroupFormProperties from './AnnouncementWhiteLabelGroupFormProperties'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'
import CngSection from '../../components/cngcomponents/CngSection'
import LocalTable from '../../components/aciacehighway/LocalTable'
import DialogForm from '../../components/aciacehighway/DialogForm'
import AlertDialog from '../../components/aciacehighway/AlertDialog'

const {
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngSwitchField
    },
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  content: "",
  startDate: "",
  endDate: "",
  priority: "0",
  active: true,
  createdBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
  version: "",
  announcementWhiteLabelGroup: [],
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, isView, showNotification, shouldHideMap }) {
  const [formDialog, setFormDialog] = useState({ open: false, whiteLabelGroup: null })
  const [confirmDialog, setConfirmDialog] = useState({ open: false, whiteLabelGroup: null })
  const { getValues, setValue, watch } = useFormContext()
  const announcementWhiteLabelGroup = watch('announcementWhiteLabelGroup')
  const { translate } = useTranslation(Namespace.ANNOUNCEMENT)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let announcement = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.TITLE
    )
    let id = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.ID
    )
    let content = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.CONTENT
    )
    let startDate = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.START_DATE
    )
    let endDate = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.END_DATE
    )
    let priority = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.PRIORITY
    )
    let active = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.ACTIVE
    )
    let createdBy = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.UPDATED_DATE
    )
    let version = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.VERSION
    )
    let announcementWhiteLabelGroup = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.AnnouncementWhiteLabelGroup.TITLE
    )
    let headerId = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.AnnouncementWhiteLabelGroup.HEADER_ID
    )
    let whiteLabelGroup = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.AnnouncementWhiteLabelGroup.WHITE_LABEL_GROUP
    )

    return {
      announcement,
      id,
      content,
      startDate,
      endDate,
      priority,
      active,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      version,
      announcementWhiteLabelGroup,
      headerId,
      whiteLabelGroup
    }
  }

  function handleAddWhiteLabelGroup(data) {
    const announcementWhiteLabelGroup = [...getValues('announcementWhiteLabelGroup'), data]

    setValue('announcementWhiteLabelGroup', announcementWhiteLabelGroup)
    setFormDialog({ open: false, whiteLabelGroup: null })
  }

  function handleDeleteWhiteLabelGroup() {
    if (!confirmDialog.whiteLabelGroup) return

    const announcementWhiteLabelGroup = [
      ...getValues('announcementWhiteLabelGroup')
    ].filter(
      (group) => group._id !== confirmDialog.whiteLabelGroup._id
    )

    setValue('announcementWhiteLabelGroup', announcementWhiteLabelGroup)
    setConfirmDialog({ open: false, announcementWhiteLabelGroup: null })
  }

  function handleEditWhiteLabelGroup(data) {
    const announcementWhiteLabelGroup = [...getValues('announcementWhiteLabelGroup')]
    const index = announcementWhiteLabelGroup.findIndex((group) => group._id === data._id)

    announcementWhiteLabelGroup[index] = data

    setValue('announcementWhiteLabelGroup', announcementWhiteLabelGroup)
    setFormDialog({ open: false, whiteLabelGroup: null })
  }

	return (
    <>
      <CngSection title={translatedTextsObject.announcement}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <CngGridItem xs={12} shouldHide={shouldHideMap.content}>
                <CngTextField
                  name="content"
                  label={translatedTextsObject.content}
                  disabled={disabled}
                  isRequired
                  multiline
                  rows={10}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.startDate}>
                <CngDateField
                  name="startDate"
                  label={translatedTextsObject.startDate}
                  isRequired
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.endDate}>
                <CngDateField
                  name="endDate"
                  label={translatedTextsObject.endDate}
                  isRequired
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.priority}>
                <CngTextField
                  name="priority"
                  label={translatedTextsObject.priority}
                  isRequired
                  disabled={disabled}
                  type= "numeric"
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.active}>
                <CngSwitchField
                  name="active"
                  label={translatedTextsObject.active}
                  disabled={disabled}
                />    
              </CngGridItem>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card variant='outlined'>
              <LocalTable
                columns={[
                  {
                    field: 'whiteLabelGroup',
                    title: translatedTextsObject.whiteLabelGroup
                  }
                ]}
                data={announcementWhiteLabelGroup}
                header={
                  <Typography style={{ fontWeight: 600 }}>
                    {translatedTextsObject.announcementWhiteLabelGroup}
                  </Typography>
                }
                {...(!isView && {
                  actions: [
                    {
                      color: 'primary',
                      buttonProps: { color: 'secondary' },
                      label: 'Add White Label Group',
                      icon: ['fal', 'plus-circle'],
                      onClick: () => setFormDialog({ open: true, whiteLabelGroup: null })
                    }
                  ],
                  rowActions: [
                    {
                      icon: ['fal', 'pen'],
                      onClick: (rowData) =>
                        setFormDialog({ open: true, whiteLabelGroup: rowData }),
                      tooltip: 'Edit'
                    },
                    {
                      icon: ['fal', 'trash'],
                      onClick: (rowData) =>
                        setConfirmDialog({ open: true, whiteLabelGroup: rowData }),
                      tooltip: 'Delete'
                    }
                  ]
                })}
              />
            </Card>
          </Grid>
        </Grid>
      </CngSection>
      <DialogForm
        formProperties={AnnouncementWhiteLabelGroupFormProperties}
        fullWidth
        isView={isView}
        maxWidth='md'
        open={formDialog.open}
        onClose={() => setFormDialog({ open: false, whiteLabelGroup: null })}
        onSubmit={
          formDialog.whiteLabelGroup
            ? handleEditWhiteLabelGroup
            : handleAddWhiteLabelGroup
        }
        title={`${formDialog.whiteLabelGroup ? 'Edit' : 'Add New'} White Label Group`}
        value={formDialog.whiteLabelGroup}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, whiteLabelGroup: null })}
        onCancel={() => setConfirmDialog({ open: false, whiteLabelGroup: null })}
        onConfirm={handleDeleteWhiteLabelGroup}
        title='Delete'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
	)
}

function toClientDataFormat(serverData) {
  let localData = { ...serverData }

  localData.announcementWhiteLabelGroup.forEach((group, index) => {
    group['_id'] = index
  })

  localData.startDate = DateTimeFormatter.toClientDate(
    localData.startDate
  );
  localData.endDate = DateTimeFormatter.toClientDate(
    localData.endDate
  );
  localData.createdDate = DateTimeFormatter.toClientDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toClientDate(
    localData.updatedDate
  );
  return localData;
}

function toServerDataFormat(localData) {
  localData.startDate = DateTimeFormatter.toServerDate(
    localData.startDate
  );
  localData.endDate = DateTimeFormatter.toServerDate(
    localData.endDate
  );
  localData.createdDate = DateTimeFormatter.toServerDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toServerDate(
    localData.updatedDate
  );
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
