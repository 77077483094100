import React, { useContext } from 'react'
import { Grid, Typography } from '@material-ui/core'
import {
  components
} from 'cng-web-lib'
import { AesTranslationContext } from '../../contexts/AESTranslationContext'
import Switch from 'src/components/Switch'
import CngSection from 'src/components/cngcomponents/CngSection'

const {
  form: {
    field: {
      CngSelectField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem,
} = components

function ShipmentActionCodeSection({ disabled, showNotification, shouldHideMap }) {
  const { getTranslatedText } = useContext(AesTranslationContext)

  return (
    <Grid item xs={12}>
      <CngSection
        subheader={
          <Typography color='textSecondary' variant='caption'>
            <Typography variant='inherit' color='error'>*</Typography>
            {getTranslatedText('AES', 'mandatoryField')}
          </Typography>
        }
        title={getTranslatedText('AES', 'actionCode')}
        id="shipment-action-code"
      >
        <Grid container spacing={1}>
          <CngGridItem xs={12} shouldHide={shouldHideMap.actionCode}>
          <CngCodeMasterAutocompleteField
              name="actionCode"
              label={getTranslatedText('AES', 'actionCode')}
              disabled={disabled}
              size='small'
              codeType='AES_ACTION_CODE'
              lookupProps={{
                label: (record) => {
                  return `${record.code};${record.descriptionEn}`
                }
              }}
            />
          </CngGridItem>
        </Grid>
      </CngSection>
    </Grid>
  )
}

export default ShipmentActionCodeSection
