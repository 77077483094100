import React from 'react'
import { Box, Card } from '@material-ui/core'
import withFormState from 'src/components/aciacehighway/hocs/withFormState'
import Stepper from 'src/components/aciacehighway/Stepper'
import A6AddPage from './A6AddPage'

function AddPage({ history, showNotification, onSetLoading }) {
  return (
    <Card>
      <Stepper steps={['Create A6']} activeStep={0} />
      <Box padding={2}>
        <A6AddPage
          history={history}
          showNotification={showNotification}
          onSetLoading={onSetLoading}
        />
      </Box>
    </Card>
  )
}


export default withFormState(AddPage)
