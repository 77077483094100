import React, { useState, useContext, useEffect } from 'react'
import { components, useTranslation, constants } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayShipmentKeys from 'src/constants/locale/key/AceHighwayShipment'
import TinyChip from 'src/components/aciacehighway/TinyChip'
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { AesTranslationContext } from '../contexts/AESTranslationContext'

const {
  button: { CngButton, CngIconButton },
  table: { useFetchCodeMaintenanceLookup },
} = components

const {
  filter: { LIKE, EQUAL, IN }
} = constants

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      padding: '12px 16px',
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        cursor: 'pointer',
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

function PartyDetailsTable(props) {
  const {
    partyDetails,
    onViewPartyDetails,
    onAddPartyDetails,
    onDeletePartyDetails,
    onEditPartyDetails
  } = props

  const { getTranslatedText } = useContext(AesTranslationContext)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const [searchTerm, setSearchTerm] = useState('')
  const classes = useStyles()

  const [lookups, setLookups] = useState(null)
  const filtered =
    searchTerm !== ''
      ? partyDetails.filter((party) =>
        party.name.includes(searchTerm)
        )
      : partyDetails

      useEffect(() => {
        Promise.all([
            fetchCodeMaintenanceLookup(
            constants.CodeMaintenanceType.CODE_MASTER,
            undefined,
            [{ field: 'codeType', operator: EQUAL, value: 'AES_PARTY_TYPE' }],
            undefined,
            'code'
            ),
            fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER,
              undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'AES_PARTY_IDENTIFIER' }],
              undefined,
              'code'
              )
        ]).then(([partyType, partyIdentifierType]) => { setLookups({ partyType,partyIdentifierType }) })
    }, [])

    function getLookupValue(name, value) {
        if (!lookups) return value
        return lookups[name] && lookups[name][value] ? lookups[name][value] : value
      }

  return (
    <>
      <Box marginBottom={1}>
        <Grid container spacing={2}>
          <Grid item xs>
            {partyDetails && partyDetails.length > 0 && (
              <TextField
                fullWidth
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder='Search in your entries'
                size='small'
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <FontAwesomeIcon icon={['fal', 'search']} />
                    </InputAdornment>
                  )
                }}
              />
            )}
          </Grid>
          {onAddPartyDetails && (
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                onClick={onAddPartyDetails}
                size='medium'
                startIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
              >
                {getTranslatedText('AES', 'addPartyButton')}
              </CngButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              <TableCell>{getTranslatedText('AES', 'partyType')}</TableCell>
              <TableCell>{getTranslatedText('AES', 'partyIdentifierType')}</TableCell>
              <TableCell>{getTranslatedText('AES', 'partyIdentifier')}</TableCell>
              <TableCell>{getTranslatedText('AES', 'name')}</TableCell>
              <TableCell>{getTranslatedText('AES', 'contactPhoneNumber')}</TableCell>
              {(onEditPartyDetails || onDeletePartyDetails) && (
                <TableCell align='right'>Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered && filtered.length > 0 ? (
              filtered.map((partyDetails) => (
                <TableRow
                  key={partyDetails._id || partyDetails.id}
                  hover
                  onClick={() => {
                    if (onViewPartyDetails) {
                      onViewPartyDetails(partyDetails)
                    }
                    if (onEditPartyDetails) {
                      onEditPartyDetails(partyDetails)
                    }
                  }}
                >
                  <TableCell>{getLookupValue("partyType",partyDetails.partyType)}</TableCell>
                  <TableCell>{getLookupValue("partyIdentifierType",partyDetails.partyIdentifierType)}</TableCell>
                  <TableCell>{partyDetails.partyIdentifier}</TableCell>
                  <TableCell>{partyDetails.name}</TableCell>
                  <TableCell>{partyDetails.contactPhoneNumber}</TableCell>
                  {(onEditPartyDetails || onDeletePartyDetails) && (
                    <TableCell>
                      <Grid container spacing={1} justify='flex-end'>
                        {onEditPartyDetails && (
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'pen']}
                              onClick={(event) => {
                                event.stopPropagation()
                                
                                if(partyDetails!=null){
                                    Object.keys(partyDetails).forEach(function(k){
                                      if(k!="baseFilterDTO" && k!= "errorMessages" && k!= "customData" && partyDetails[k]==null){
                                        partyDetails[k] = ''
                                      }
                                  });
                                }
                                onEditPartyDetails(partyDetails)
                              }}
                              size='small'
                            />
                          </Grid>
                        )}
                        {onDeletePartyDetails && (
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'trash']}
                              onClick={(event) => {
                                event.stopPropagation()
                                onDeletePartyDetails(partyDetails)
                              }}
                              size='small'
                            />
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={
                    3 + (onEditPartyDetails || onDeletePartyDetails ? 1 : 0)
                  }
                >
                  <Typography variant='body2' color='textSecondary'>
                    {getTranslatedText('AES', 'noRecordToDisplay')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default PartyDetailsTable
