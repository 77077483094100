import React, { useContext } from 'react'
import { useFormContext } from "react-hook-form"
import { components } from 'cng-web-lib'
import { Grid } from '@material-ui/core'
import { A6TranslationContext } from './contexts/A6TranslationContext'
import makeValidationSchema from './TaciOcnA6SNMakeValidationSchema'
import _ from 'lodash'

const {
  form: {
    field: { CngTextField, CngCodeMasterAutocompleteField },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  email: "",
  phno: "",
  snTyp: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { getTranslatedText } = useContext(A6TranslationContext)
  const { setValue } = useFormContext();

  return (
    <Grid container spacing={2}>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.name}>
        <CngTextField
            name="name"
            label={getTranslatedText('A6', 'name')}
            disabled={disabled}
            inputProps={{ maxLength: 50 }}
            required
            size="small"
          />
      </CngGridItem>  
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.email}>
        <CngTextField
          name="email"
          label={getTranslatedText('A6', 'email')}
          inputProps={{ maxLength: 250 }}
          disabled={disabled}
          onBlur={(e) => { setValue("email", e.target.value.toUpperCase()) }}
          required
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.phno}>
        <CngTextField
          name="phno"
          label={getTranslatedText('A6', 'phno')}
          inputProps={{ maxLength: 15 }}
          disabled={disabled}
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.snTyp}>
        <CngCodeMasterAutocompleteField
          name="snTyp"
          label={getTranslatedText('A6', 'snTyp')}
          disabled={disabled}
          codeType='ACIOCN_STATUS_NOTIFI_TYPE'
          required
          size="small"
        />
      </CngGridItem>
    </Grid>
	)
}

const TaciOcnA6SNFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default TaciOcnA6SNFormProperties
