import makeValidationSchema from './AnnouncementWhiteLabelGroupMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AnnouncementKeys from 'src/constants/locale/key/Announcement'
import Grid from '@material-ui/core/Grid'

const {
  form: {
    field: { CngCodeMasterAutocompleteField },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  headerId: "",
  whiteLabelGroup: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.ANNOUNCEMENT)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let whiteLabelGroup = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.AnnouncementWhiteLabelGroup.WHITE_LABEL_GROUP
    )

    return { whiteLabelGroup }
  }

	return (
    <Grid container spacing={2}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.whiteLabelGroup}>
        <CngCodeMasterAutocompleteField
          name="whiteLabelGroup"
          label={translatedTextsObject.whiteLabelGroup}
          isRequired
          disabled={disabled}
          codeType="WHITE_LABEL_GROUP"
        />
      </CngGridItem>
    </Grid>
	)
}

const AnnouncementWhiteLabelGroupFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default AnnouncementWhiteLabelGroupFormProperties
