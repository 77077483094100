import React, { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { components } from 'cng-web-lib'
import { Grid, Typography } from '@material-ui/core'
import { A6TranslationContext } from '../contexts/A6TranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import moment from 'moment-timezone'


const {
    form: {
        field: { CngTextField, CngDateField, }
    },
    CngGridItem,
} = components


function A6CertificatesSection(props) {
    const { disabled, shouldHideMap } = props
    const { setValue } = useFormContext();
    const { getTranslatedText } = useContext(A6TranslationContext)

    return (
        <Grid item xs={12}>
            <CngSection title={getTranslatedText('A6', 'certificates')}
                subheader={
                    <Typography color='textSecondary' variant='caption'>
                        <Typography variant='inherit' color='error'>*</Typography>
                        Mandatory fields
                    </Typography>
                }>
                <Grid container spacing={1}>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.safetycertNo}>
                        <CngTextField
                            name='safetycertNo'
                            label={getTranslatedText('A6', 'safetycertNo')}
                            inputProps={{ maxLength: 25 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('safetycertNo', e.target.value.toUpperCase()) }}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.safetycertExpiryDate}>
                        <CngDateField
                            name='safetycertExpiryDate'
                            label={getTranslatedText('A6', 'safetycertExpiryDate')}
                            shouldDisableDate={(safetycertExpiryDate) => (moment().isAfter(moment(safetycertExpiryDate)))}
                            disabled={disabled}
                            format='YYYY-MM-DD'
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.radiocertNo}>
                        <CngTextField
                            name='radiocertNo'
                            label={getTranslatedText('A6', 'radiocertNo')}
                            inputProps={{ maxLength: 25 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('radiocertNo', e.target.value.toUpperCase()) }}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.radiocertExpiryDate}>
                        <CngDateField
                            name='radiocertExpiryDate'
                            label={getTranslatedText('A6', 'radiocertExpiryDate')}
                            shouldDisableDate={(radiocertExpiryDate) => (moment().isAfter(moment(radiocertExpiryDate)))}
                            disabled={disabled}
                            format='YYYY-MM-DD'
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.equipcertNo}>
                        <CngTextField
                            name='equipcertNo'
                            label={getTranslatedText('A6', 'equipcertNo')}
                            inputProps={{ maxLength: 25 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('equipcertNo', e.target.value.toUpperCase()) }}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.equipcertExpiryDate}>
                        <CngDateField
                            name='equipcertExpiryDate'
                            label={getTranslatedText('A6', 'equipcertExpiryDate')}
                            shouldDisableDate={(equipcertExpiryDate) => (moment().isAfter(moment(equipcertExpiryDate)))}
                            disabled={disabled}
                            format='YYYY-MM-DD'
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.loadlinecertNo}>
                        <CngTextField
                            name='loadlinecertNo'
                            label={getTranslatedText('A6', 'loadlinecertNo')}
                            inputProps={{ maxLength: 25 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('loadlinecertNo', e.target.value.toUpperCase()) }}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.loadlinecertExpiryDate}>
                        <CngDateField
                            name='loadlinecertExpiryDate'
                            label={getTranslatedText('A6', 'loadlinecertExpiryDate')}
                            shouldDisableDate={(loadlinecertExpiryDate) => (moment().isAfter(moment(loadlinecertExpiryDate)))}
                            disabled={disabled}
                            format='YYYY-MM-DD'
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.deratcertNo}>
                        <CngTextField
                            name='deratcertNo'
                            label={getTranslatedText('A6', 'deratcertNo')}
                            inputProps={{ maxLength: 25 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('deratcertNo', e.target.value.toUpperCase()) }}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.deratcertExpiryDate}>
                        <CngDateField
                            name='deratcertExpiryDate'
                            label={getTranslatedText('A6', 'deratcertExpiryDate')}
                            shouldDisableDate={(deratcertExpiryDate) => (moment().isAfter(moment(deratcertExpiryDate)))}
                            disabled={disabled}
                            format='YYYY-MM-DD'
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.healthcertNo}>
                        <CngTextField
                            name='healthcertNo'
                            label={getTranslatedText('A6', 'healthcertNo')}
                            inputProps={{ maxLength: 25 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('healthcertNo', e.target.value.toUpperCase()) }}
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.healthcertExpiryDate}>
                        <CngDateField
                            name='healthcertExpiryDate'
                            label={getTranslatedText('A6', 'healthcertExpiryDate')}
                            shouldDisableDate={(healthcertExpiryDate) => (moment().isAfter(moment(healthcertExpiryDate)))}
                            disabled={disabled}
                            format='YYYY-MM-DD'
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.civilcertNo}>
                        <CngTextField
                            name='civilcertNo'
                            label={getTranslatedText('A6', 'civilcertNo')}
                            inputProps={{ maxLength: 25 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('civilcertNo', e.target.value.toUpperCase()) }}
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.civilcertExpiryDate}>
                        <CngDateField
                            name='civilcertExpiryDate'
                            label={getTranslatedText('A6', 'civilcertExpiryDate')}
                            shouldDisableDate={(civilcertExpiryDate) => (moment().isAfter(moment(civilcertExpiryDate)))}
                            disabled={disabled}
                            format='YYYY-MM-DD'
                            size='small'
                        />
                    </CngGridItem>
                </Grid>
            </CngSection>
        </Grid>
    )
}

export default A6CertificatesSection