import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
  useTranslation
} from 'cng-web-lib'
import AnnouncementKeys from 'src/constants/locale/key/Announcement'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  let errMsgAlphaNumeric = translate(
    Namespace.ANNOUNCEMENT,
    AnnouncementKeys.Validations.ERROR_MSG_ALPHA_NUMERIC
  )

  let errMsgNumeric = translate(
    Namespace.ANNOUNCEMENT,
    AnnouncementKeys.Validations.ERROR_MSG_NUMERIC
  )

  let errMsgMaxLength = translate(
    Namespace.ANNOUNCEMENT,
    AnnouncementKeys.Validations.ERROR_MSG_MAX_LENGTH
  )


  let regexNumeric = "^[0-9]*$";
  let regexAlphaNumeric= "^[a-zA-Z0-9 ,+\\-$#@%&()!'`^_:;.?\\[\\]\\{\\}\\/=\"\\|\\\\ ><]*$"

  return Yup.object({
    id: Yup.string(),
    content: Yup.string().nullable().required(requiredMessage).max(4000,errMsgMaxLength+" 4000"),
    startDate: Yup.date().nullable().validFormat().typeError(dateTypeErrorMessage).required(requiredMessage),
    endDate: Yup.date().nullable().validFormat().typeError(dateTypeErrorMessage).required(requiredMessage),
    priority: Yup.string().nullable().required(requiredMessage).matches(regexNumeric,errMsgNumeric).max(3,errMsgMaxLength+" 3"),
    active: Yup.string(),
    createdBy: Yup.string(),
    createdDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    updatedBy: Yup.string(),
    updatedDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    version: Yup.string(),
    announcementWhiteLabelGroup: Yup.array(),
  })
}

export default makeValidationSchema
