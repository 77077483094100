import React, { useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Grid, Typography, } from '@material-ui/core'
import { A6TranslationContext } from '../contexts/A6TranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import TaciOcnA6ContainerDialog from '../TaciOcnA6ContainerDialog'
import TaciOcnA6ContainerTable from '../TaciOcnA6ContainerTable'

function A6ContainerSection(props) {
    const { showNotification } = props
    const { setValue, getValues } = useFormContext();
    const { getTranslatedText } = useContext(A6TranslationContext)

    const [dialog, setDialog] = useState({ open: false, container: null })

    const taciOcnA6Container = useWatch({ name: 'taciOcnA6Container' })

    function handleRemoveContainer(data) {
        const taciOcnA6Container = [...getValues('taciOcnA6Container')].filter((container) =>
            container.id ? container.id !== data.id : container._id !== data._id
        )

        setValue('taciOcnA6Container', taciOcnA6Container, { shouldValidate: true })
    }

    return (
        <Grid item xs={12}>
            <CngSection
                title={
                    <Typography variant='h5' style={{ fontWeight: 700 }}>
                        {getTranslatedText('A6', 'taciOcnA6Container') + ' '}
                        <Typography color='textSecondary' component='span' variant='caption'>
                            {`/ ${taciOcnA6Container.length} Entries`}
                        </Typography>
                    </Typography>
                }
                alignItems='flex-end'
            >

                <TaciOcnA6ContainerTable
                    data={taciOcnA6Container}
                    onAddContainer={() => setDialog({ open: true, container: null })}
                    onEditContainer={(container) => setDialog({ open: true, container })}
                    onDeleteContainer={handleRemoveContainer}
                />

                <TaciOcnA6ContainerDialog
                    onClose={() => setDialog({ open: false, container: null })}
                    open={dialog.open}
                    container={dialog.container}
                    showNotification={showNotification}
                />
            </CngSection>
        </Grid>
    )
}

export default A6ContainerSection