import React, { useEffect, useState, useContext } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import AesShipmentReasonApiUrls from 'src/apiUrls/AesShipmentReasonApiUrls'
import moment from 'moment-timezone'
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography
} from '@material-ui/core'
import clsx from 'clsx'
import { AesTranslationContext } from '../contexts/AESTranslationContext'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL, IN }
} = constants

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

function ShipmentResponse(props) {
  const { showNotification, id, isViewOnly = false } = props
  const { getTranslatedText } = useContext(AesTranslationContext)

  const [data, setData] = useState([])
  const [lookups, setLookups] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { fetchRecords } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const classes = useStyles()

  useEffect(() => {
    Promise.all([
      fetchRecords.execute(
        AesShipmentReasonApiUrls.GET,
        {
          filters: [
            {
              field: 'headerId',
              operator: EQUAL,
              value: id
            }
          ],
          sorts : [
            {field: "createdDate", direction: "desc"}
          ]
        },
        (res) => res.content
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'AES_SEVERITY_INDICATOR'
          }
        ],
        undefined,
        'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'AES_FINAL_DESTINATION'
          }
        ],
        undefined,
        'code'
      )
    ]).then(([data, aesSeverityIndicator, aesfinalDestination]) => {
      setData(data)
      setLookups({ aesSeverityIndicator, aesfinalDestination })
    })
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value
    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const columns = [
    {
      field: 'id',
      title: getTranslatedText('AES', 'responsedId'),
    },
    {
      title: getTranslatedText('AES', 'processingDate'),
      render: (data) =>
        data.processingDate &&
        moment(data.processingDate).tz("Canada/Eastern").format('D MMM YYYY')
    },
    { field: 'aesInternalTransactionNumber', title: getTranslatedText('AES', 'aesInternalTransactionNumber') },
    { field: 'responseCode', title: getTranslatedText('AES', 'responseCode') },
    { field: 'responseText', title: getTranslatedText('AES', 'responseText') },
    { field: 'shipmentReferenceNumber', title: getTranslatedText('AES', 'shipmentReferenceNumber') },
    {
      title: getTranslatedText('AES', 'responseDate'),
      render: (data) =>
        data.responseDate &&
        moment(data.responseDate).tz("Canada/Eastern").format('D MMM YYYY - HH:mm')
    },
    { field: 'reasonCode', title: getTranslatedText('AES', 'reasonCode') },
    {
      title: getTranslatedText('AES', 'finalDestinationInd'),
      render: (data) =>
        data.finalDestinationInd && getLookupValue("aesfinalDestination", data.finalDestinationInd)

    },
    {
      title: getTranslatedText('AES', 'severityIndicator'),
      render: (data) =>
        data.severityIndicator && getLookupValue("aesSeverityIndicator", data.severityIndicator)
    }
  ]
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }

  return (
    <>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((d) => (
                  <TableRow key={d.id}>
                    {columns.map((column, index) => (
                      <TableCell key={index}>
                        {column.render ? column.render(d) : d[column.field]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={columns.length}
                >
                  <Typography variant='body2' color='textSecondary'>
                    {getTranslatedText('AES', 'noRecordToDisplay')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box padding={2}>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100, 500, 1000]}
          component='div'
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
    </>
  )
}

export default ShipmentResponse
