import AesShipmentApiUrls from 'src/apiUrls/AesShipmentApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useContext, useState, useRef, useEffect } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import { AesTranslationContext } from '../contexts/AESTranslationContext'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import pathMap from '../../../paths/pathMap'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import { useFormContext } from 'react-hook-form';
import ChargeTypeDialog from '../../common/ChargeTypeDialog'

const {
  button: { CngButton },
  form: { CngEditForm },
  table: { useFetchCodeMaintenanceLookup },
} = components

const { filter: { EQUAL } } = constants

function EditPage({ history, showNotification }) {
  const [lookups, setLookups] = useState(null)
  const { id } = useParams()
  const { getTranslatedText } = useContext(AesTranslationContext)
  const { securedSendRequest } = useServices()

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.countryStates[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  const [loading, setLoading] = useState(false)
  const [submitDialog, setSubmitDialog] = useState({ open: false, items: [] })
  const [isSuperParty, setSuperParty] = useState(false);
  const [chargeTypes, setChargeTypes] = useState([])
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const serverDataRef = useRef()

  useEffect(() => {
    Promise.all([
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined,
        'code'
      ),
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      )
    ]).then(
      ([billingChargeTypes, billingSuperParty]) => {
        compileChargeTypes(billingChargeTypes)

        Object.keys(billingSuperParty).forEach(e => {
          if (e == GetLoggedInUserPartyName()) {
            setSuperParty(true);
          }
        })
      }
    )
  }, [])

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }

  function preSubmit() {
    const values = { ...serverDataRef.current, id }

    if (isSuperParty) {
      setSubmitDialog({ open: true, items: values })
    } else {
      values.chargeAs = 'REGULAR'
      serverDataRef.current = values
      handleSubmitShipment()
    }
  }

  function handleSubmitShipment() {
    setLoading(true)

    try {
      let fileForUserDetails = FileForUserGetUserDetails();
      if (fileForUserDetails != null && fileForUserDetails != undefined) {
        serverDataRef.current.fileForUserId = fileForUserDetails.fileForUserId;
        serverDataRef.current.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
        serverDataRef.current.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
      }

      serverDataRef.current = FormProperties.toServerDataFormat(serverDataRef.current)

      securedSendRequest.execute('POST', AesShipmentApiUrls.SUBMIT_ALL, [serverDataRef.current],
        (response) => {
          setLoading(false)
          const data = response.data
          var errorFlag = false

          for (var key in data) {
            var obj = data[key]
            if (obj.errorMessages != null && obj.errorMessages.length > 0) {
              for(let error of obj.errorMessages){
                showNotification('error', error);
              }
              errorFlag = true
            }
          }

          if (!errorFlag) {
            showNotification('success', getTranslatedText('AES','submitSuccessMessage'))
            history.push(pathMap.AES_SHIPMENT_LIST_VIEW)
          }else{
            serverDataRef.current = FormProperties.toClientDataFormat(serverDataRef.current)
          }
        },
        error => {
          setLoading(false)
          console.log(error)
          showNotification('error',  getTranslatedText('AES','submitErrorMessage'))
          serverDataRef.current = FormProperties.toClientDataFormat(serverDataRef.current)
        }
      )
    } catch (error) {
      console.log(error)
      setLoading(false)
      serverDataRef.current = FormProperties.toClientDataFormat(serverDataRef.current)
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngEditForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
              getLookupValue={getLookupValue}
              getCountryStateLabel={getCountryStateLabel}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: AesShipmentApiUrls.GET
          }}
          update={{
            url: AesShipmentApiUrls.PUT,
            onPostSubmitSuccess: (e) => {
              let fileForUserDetails = FileForUserGetUserDetails();
              if (fileForUserDetails != null && fileForUserDetails != undefined) {
                e.fileForUserId = fileForUserDetails.fileForUserId;
                e.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
                e.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
              }
            }
          }}
          id={id}
          renderButtonSection={() => (
            <ButtonSection
              history={history}
              loading={loading}
              getTranslatedText={getTranslatedText}
              onSubmitShipment={(data) => {
                serverDataRef.current = data
                preSubmit(data)
              }}
            />
          )}
        />
        <ChargeTypeDialog
          chargeTypes={chargeTypes}
          onClose={() => setSubmitDialog({ open: false, items: [] })}
          onSubmit={(data) => {
            serverDataRef.current = data
            handleSubmitShipment()
          }}
          open={submitDialog.open}
          items={submitDialog.items}
        />
      </Grid>
    </Grid>
  )
}

export default EditPage

function ButtonSection(props) {
  const { history, loading, getTranslatedText, onSubmitShipment } = props
  const [confirmDialog, setConfirmDialog] = useState(false)
  const { formState: { isSubmitting }, getValues, trigger } = useFormContext()

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton color='secondary' disabled={isSubmitting || loading} onClick={() => setConfirmDialog(true)} size='medium'>{getTranslatedText('AES', 'discardButton')}</CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton color='primary' disabled={isSubmitting || loading} type='submit' size='medium'>{getTranslatedText('AES', 'updateButton')}</CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={isSubmitting || loading}
                shouldShowProgress={loading}
                onClick={async () => {
                  let result = await trigger()
                  if (result) {
                    onSubmitShipment(getValues())
                  }
                }}
              >
                {getTranslatedText('AES', 'submitButton')}
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AlertDialog
        cancelLabel={getTranslatedText('AES', 'continueEditing')}
        confirmLabel={getTranslatedText('AES', 'yesDiscard')}
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={() => history.push(pathMap.AES_SHIPMENT_LIST_VIEW)}
        title={getTranslatedText('AES', 'discardButton')}
      >
        {getTranslatedText('AES', 'discardMessage')}
      </AlertDialog>
    </>
  )
}
