import React, { useRef, useState, useEffect, useContext } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { Chip, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { A6TranslationContext } from './contexts/A6TranslationContext'
import pathMap from 'src/paths/pathMap'
import AcioceanApiUrls from 'src/apiUrls/AcioceanApiUrls'
import Table from 'src/components/aciacehighway/Table'
import ConfirmDialog from 'src/views/common/ConfirmDialog'
import CngBackdrop from 'src/components/cngcomponents/CngBackDrop';
import { getStatusMetadata, DisableSubmitButton } from '../../../common/NACommon'
import { FileForUserGetPartyId, FileForUserGetUserDetails,GetFilterByUserIdCriteria,FileForUserGetCurrentLoginId } from 'src/common/FileForUserCommon'
import { format } from 'date-fns'
import moment from 'moment-timezone'
import _ from 'lodash'
import UserProfileApiUrls from "src/apiUrls/UserProfileApiUrls";
import FileForUserContext from 'src/contexts/FileForUserContext'

const { table: { useFetchCodeMaintenanceLookup }, CngGridItem } = components
const { filter: { EQUAL, IN, LIKE, BETWEEN } } = constants

function TablePage(props) {
  const { history, location, showNotification } = props
  const { createRecord, deleteRecord, securedSendRequest } = useServices()
  const { getTranslatedText } = useContext(A6TranslationContext)

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()


  const tableRef = useRef(null)

  const [lookups, setLookups] = useState(null)
  const [loading, setLoading] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState({ open: false, a6: null })
  const [confirmMassDelete, setConfirmMassDelete] = useState({ open: false, a6: [] })
  const [confirmSubmit, setConfirmSubmit] = useState({ open: false, a6: [] })
  const [sbPartyIdList, setSBPartyIdList] = useState([])
  const [sbPartyIdFetched, setSBPartyIdFetched] = useState(false)
  const [codeMaintenanceFetched, setCodeMaintenanceFetched] = useState(false)
  const [hqAdmin, setHqAdmin] = useState(false)
  const { partyConfigFetched } = useContext(FileForUserContext)

  let filter = GetFilterByUserIdCriteria("ACIOCN")

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  useEffect(() => {
    Promise.all([
      // Customs Procedure
      fetchCodeMaintenanceLookup(constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'A6_CUSTOM_PROCEDURE' }],
        undefined, 'code'
      ),
      // Message Function
      fetchCodeMaintenanceLookup(constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{
          field: 'codeType', operator: IN,
          value: 'MESSAGE_FUNCTION'
        }], undefined, 'code'
      ),
      //HQ_ADMINISTRATOR
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'HQ_ADMINISTRATOR' },
          { field: 'code', operator: EQUAL, value: FileForUserGetCurrentLoginId() }
        ],
        undefined,
        'code'
      )
    ]).then(([customsProc, messageFunction,hqAdmin]) => {
      setLookups({ customsProc, messageFunction })

      if(hqAdmin && hqAdmin[FileForUserGetCurrentLoginId()] == "true"){
       setHqAdmin(true)
      }
      setCodeMaintenanceFetched(true);
    })

    let loginId = "";
    let userPreference = localStorage.getItem("userPreference");
    let fileForUserDetails = sessionStorage.getItem("fileForUserDetails");
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setSBPartyIdList([])
      setSBPartyIdFetched(true);
    } else {
      let userPreferenceJSON = JSON.parse(userPreference);
      loginId = userPreferenceJSON.tprUserDetail.userProfile.loginId;
    }
    console.log("loginId: " + JSON.stringify(loginId));

    if (loginId !== "") {
      function onSuccess(response) {
        setSBPartyIdList(response.data)
        setSBPartyIdFetched(true);
        console.log("sbPartyIdList: " + JSON.stringify(sbPartyIdList));
      }

      function onError(error) {
        console.log("sbPartyIdList Error: " + JSON.stringify(error));
      }

      function onComplete() { }
      const config = {}

      securedSendRequest.execute('POST', UserProfileApiUrls.SB_PARTYID_LIST, { loginId }, onSuccess, onError, onComplete, config, null);

    }
  }, [])


  let columns = [
    {
      field: 'voyageNo',
      sortKey: 'voyageNo',
      title: getTranslatedText('A6', 'voyageNo')
    },
    {
      field: 'convRefno',
      sortKey: 'convRefno',
      title: getTranslatedText('A6', 'convRefno')
    },
    {
      field: 'customsProc',
      sortKey: 'customsProc',
      title: getTranslatedText('A6', 'customsProc'),
      render: (data) => (
        <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
          {getLookupValue('customsProc', data.customsProc)}
        </Typography>
      )
    },
    {
      field: 'vesselName',
      sortKey: 'vesselName',
      title: getTranslatedText('A6', 'vesselName')
    },
    {
      field: 'a6aStatus',
      sortKey: 'a6aStatus',
      title: getTranslatedText('A6', 'a6aStatus'),
      render: (data) => {
        const status = getStatusMetadata(data.a6aStatus)

        return (
          <Chip label={status.label} size='small'
            style={{ backgroundColor: status.color, color: status.contrastColor }}
          />
        )
      }
    },
    {
      field: 'messageFunction',
      sortKey: 'messageFunction',
      title: getTranslatedText('A6', 'messageFunction'),
      render: (data) => (
        <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
          {getLookupValue('messageFunction', data.messageFunction)}
        </Typography>
      )
    },
    {
      field: 'createdDate',
      sortKey: 'createdDate',
      title: getTranslatedText('A6', 'creationdatetime'),
      render: (data) => (moment(data.createdDate).tz('Canada/Eastern').format('DD/MM/YYYY HH:mm:ss'))
    }
  ]

  let hqAdminColumns = [
    {
      field: 'voyageNo',
      sortKey: 'voyageNo',
      title: getTranslatedText('A6', 'voyageNo')
    },
    {
      field: 'convRefno',
      sortKey: 'convRefno',
      title: getTranslatedText('A6', 'convRefno')
    },
    {
      field: 'customsProc',
      sortKey: 'customsProc',
      title: getTranslatedText('A6', 'customsProc'),
      render: (data) => (
        <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
          {getLookupValue('customsProc', data.customsProc)}
        </Typography>
      )
    },
    {
      field: 'vesselName',
      sortKey: 'vesselName',
      title: getTranslatedText('A6', 'vesselName')
    },
    {
      field: 'a6aStatus',
      sortKey: 'a6aStatus',
      title: getTranslatedText('A6', 'a6aStatus'),
      render: (data) => {
        const status = getStatusMetadata(data.a6aStatus)

        return (
          <Chip label={status.label} size='small'
            style={{ backgroundColor: status.color, color: status.contrastColor }}
          />
        )
      }
    },
    {
      field: 'messageFunction',
      sortKey: 'messageFunction',
      title: getTranslatedText('A6', 'messageFunction'),
      render: (data) => (
        <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
          {getLookupValue('messageFunction', data.messageFunction)}
        </Typography>
      )
    },
    {
      field: 'createdByLoginId',
      sortKey: 'createdByLoginId',
      title: getTranslatedText('A6', 'createdByLoginId')
    },
    {
      field: 'createdDate',
      sortKey: 'createdDate',
      title: getTranslatedText('A6', 'creationdatetime'),
      render: (data) => (moment(data.createdDate).tz('Canada/Eastern').format('DD/MM/YYYY HH:mm:ss'))
    }
  ]

  const filters = [
    {
      label: getTranslatedText('A6', 'voyageNo'),
      type: 'textfield',
      name: 'voyageNo',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('A6', 'convRefno'),
      type: 'textfield',
      name: 'convRefno',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('A6', 'customsProc'),
      type: 'checkbox',
      name: 'customsProc',
      operatorType: IN,
      options: [
        { label: 'Inward Report', value: 'inward', filterValue: { value: '21' } },
        { label: 'Outward Report', value: 'outward', filterValue: { value: '22' } },
        { label: 'In-Transit Report', value: 'in_transit', filterValue: { value: '23' } },
      ]
    },
    {
      label: getTranslatedText('A6', 'vesselName'),
      type: 'textfield',
      name: 'vesselName',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('A6', 'a6aStatus'),
      type: 'checkbox',
      name: 'a6aStatus',
      operatorType: IN,
      options: [
        { label: 'CBSA Recieved and Processing', value: 'processing', filterValue: { value: '1' } },
        { label: 'Send Successfully', value: 'sent', filterValue: { value: '1048' } },
        { label: 'Send Failed', value: 'send_failed', filterValue: { value: '1049' } },
        { label: 'Accepted', value: 'accepted', filterValue: { value: '1000' } },
        { label: 'Rejected', value: 'rejected', filterValue: { value: '1001' } },
        { label: 'Cancel Accepted', value: 'cancelled', filterValue: { value: '1003' } },
        { label: 'Cancel Rejected', value: 'cancel_rejected', filterValue: { value: '1004' } },
        { label: 'Draft', value: 'draft', filterValue: { value: '1005' } },
        { label: 'Functional Acknowledgement Message Received', value: 'func_received', filterValue: { value: '17' } },
        { label: 'Conveyance Arrived', value: 'arrived', filterValue: { value: '200' } },
        { label: 'Risk Assessment', value: 'risk_assessment', filterValue: { value: '25' } },
        { label: 'Reject Type', value: 'reject_type', filterValue: { value: '14' } },
        { label: 'Batch Error', value: 'batch_error', filterValue: { value: '28' } },
        { label: 'Data Error', value: 'data_error', filterValue: { value: '29' } },
        { label: 'Admin Error', value: 'admin_error', filterValue: { value: '20' } },
        { label: 'Enforce Error', value: 'enforce_error', filterValue: { value: '21' } },
        { label: 'Syntax Error', value: 'syntax_error', filterValue: { value: '22' } },
        { label: 'Supplementary Match Yes', value: 'supp_yes', filterValue: { value: '32' } },
        { label: 'Supplementary Match No', value: 'supp_no', filterValue: { value: '33' } }
      ]
    },
    {
      label: getTranslatedText('A6', 'messageFunction'),
      type: 'checkbox',
      name: 'messageFunction',
      operatorType: IN,
      options: [
        { label: 'Original', value: 'original', filterValue: { value: '9' } },
        { label: 'Change', value: 'change', filterValue: { value: '4' } },
        { label: 'Cancel', value: 'cancel', filterValue: { value: '1' } },
      ]
    },
    {
      label: getTranslatedText('A6', 'createdByLoginId'),
      type: 'textfield',
      name: 'createdByLoginId',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('A6', 'creationdatetime'),
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    }
  ]

  function handleCloneA6(data) {
    createRecord.execute(
      AcioceanApiUrls.CLONE, data,
      (data) => { history.push(`${location.pathname}/add`, data) },
      (error) => { console.log(error) }
    )
  }

  function handlePrintA6(data) {
    const config = { responseType: 'blob' }

    function onSuccess(response) {
      let blob = new Blob([response.data])

      let first = 'A6Report'
      let curDate = format(new Date(), 'yyyyMMddHHmmss')
      let filename = first + curDate + '.pdf'
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = filename
      a.click()
    }

    function onError(error) {
      console.error(error);
    }

    function onComplete() { }

    securedSendRequest.execute('POST', AcioceanApiUrls.PRINT, data, onSuccess, onError, onComplete, config, null);
  }

  function handleDownloadXMLA6(data) {
    const config = { responseType: 'blob' }
    
    function onSuccess(response) {
      let blob = new Blob([response.data])
      let fileForUserDetails = FileForUserGetUserDetails();
      const loginId = 'A6Download';
      if (fileForUserDetails != null && fileForUserDetails != undefined) {
        loginId = fileForUserDetails.fileForUserLoginId;
      }
      let first = loginId
      let curDate = format(new Date(), 'yyyyMMddHHmmss')
      let filename = first + curDate + '.xml'
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = filename
      a.click()
    }

    function onError(error) {
      console.error(error);
    }

    function onComplete() { }

    securedSendRequest.execute('POST', AcioceanApiUrls.DOWNLOADXML, data, onSuccess, onError, onComplete, config, null);
  }

  function handleSubmitA6() {
    if (_.isEmpty(confirmSubmit.a6)) return

    setLoading(true)
    let data = [...confirmSubmit.a6]
    const fileForUserDetails = FileForUserGetUserDetails()

    data.forEach((a6) => {
      if (fileForUserDetails) {
        const { fileForUserId, fileForUserPartyId, fileForUserLoginId } = fileForUserDetails

        a6['fileForUserId'] = fileForUserId
        a6['fileForUserPartyId'] = fileForUserPartyId
        a6['fileForUserLoginId'] = fileForUserLoginId
      }
    })

    try {
      securedSendRequest.execute('POST', AcioceanApiUrls.SUBMIT_ALL, data,
        (response) => {
          const { errorMessages } = response

          if (!_.isEmpty(errorMessages)) {
            errorMessages.forEach((message) => showNotification('error', message))
          } else {
            showNotification('success', getTranslatedText('A6', 'submitSuccessMsg'))
            setConfirmSubmit({ open: false, a6: [] })

            tableRef.current.performRefresh()
          }
        },
        () => showNotification('error', getTranslatedText('A6', 'submitErrorMsg')),
        () => setLoading(false)
      )
    } catch (error) {
      showNotification('error', getTranslatedText('A6', 'submitErrorMsg'))
      setLoading(false)
    }
  }

  function handleDeleteA6() {
    if (!confirmDelete.a6) return

    setConfirmDelete({ open: false })

    deleteRecord.execute(AcioceanApiUrls.DELETE, confirmDelete.a6,
      () => {
        showNotification('success', 'Delete succeeded')
        setConfirmDelete({ open: false, a6: null })
        tableRef.current.performRefresh()
      },
      (error) => { console.log(error) }
    )

  }

  function handleMassDeleteA6() {
    if (_.isEmpty(confirmMassDelete.a6)) return

    setLoading(true)
    try {
      deleteRecord.execute(AcioceanApiUrls.DELETE_ALL, confirmMassDelete.a6,
        () => {
          showNotification('success', 'Mass delete successful')
          setConfirmMassDelete({ open: false, a6: [] })

          tableRef.current.performRefresh()
        },
        () => showNotification('error', 'Something went wrong. Mass delete failed.'),
        () => setLoading(false)
      )
    } catch (error) {
      showNotification('error', 'Something went wrong. Mass delete failed.')
      setLoading(false)
    }
  }

  function DisableEditButton(rowData) {
    return rowData.a6aStatus == 1048 || rowData.a6aStatus == 1
  }

  function DisableDeleteButton(rows) {
    let shouldDisable = false

    rows.forEach((rowData) => {
      if (rowData.a6aStatus != 1005) {
        shouldDisable = true
      }
    })
    return shouldDisable
  }

  function DisableSubmitButton(rows) {
    let shouldDisable = false

    rows.forEach((rowData) => {
      if (rowData.a6aStatus == 1048 || rowData.a6aStatus == 1) {
        shouldDisable = true
      }
    })

    return shouldDisable
  }

  if (loading) {
    return (
      <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
        <CngBackdrop loading={loading} />
      </CngGridItem>
    )
  }

  return (
    <>
    {sbPartyIdFetched && codeMaintenanceFetched && partyConfigFetched && (
      <Table
        actions={[
          {
            label: 'Create A6',
            buttonProps: {
              color: 'primary', size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.ACIOCEAN_ADD_VIEW)
            }
          }
        ]}
        columns={hqAdmin ? hqAdminColumns : columns}
        compact
        checkboxSelection
        fetch={{ url: AcioceanApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: IN, value: sbPartyIdList.length > 0 ? sbPartyIdList : FileForUserGetPartyId() },
          ...filter
        ]}
        filters={filters}
        onRowClick={(rowData) => {
          if (DisableEditButton(rowData)) {
            history.push(`${location.pathname}/view/${rowData.id}`)
          } else {
            history.push(`${location.pathname}/edit/${rowData.id}`)
          }
        }}
        rowActions={[
          {
            label: getTranslatedText('A6', 'editButton'),
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            disabled: (rowData) => DisableEditButton(rowData),
            onClick: (rowData) => history.push(`${location.pathname}/edit/${rowData.id}`),
            tooltip: (rowData) => DisableEditButton(rowData) ? getTranslatedText('A6', 'editSentRecords') : null
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: getTranslatedText('A6', 'cloneButton'),
            onClick: handleCloneA6
          },
          {
            label: getTranslatedText('A6', 'deleteButton'),
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            disabled: (rowData) => rowData.a6aStatus != 1005,
            onClick: (rowData) => setConfirmDelete({ open: true, a6: rowData })
          },
          {
            label: 'Print',
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            onClick: handlePrintA6
          },
          {
            label: 'Download XML',
            icon: <FontAwesomeIcon icon={['fal', 'download']} />,
            onClick: handleDownloadXMLA6
          }
        ]}
        selectActions={[
          {
            label: getTranslatedText('A6', 'submitButton'),
            icon: <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />,
            buttonProps: { color: 'secondary', size: 'medium' },
            disabled: (rows) => DisableSubmitButton(rows),
            onClick: (rows) => setConfirmSubmit({ open: true, a6: rows }),
            tooltip: (rows) => DisableSubmitButton(rows) ? getTranslatedText('A6', 'submitSentRecord') : null
          },
          {
            label: getTranslatedText('A6', 'deleteButton'),
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            buttonProps: { color: 'secondary', size: 'medium' },
            disabled: (rows) => DisableDeleteButton(rows),
            onClick: (rows) => setConfirmMassDelete({ open: true, a6: rows }),
            tooltip: (rows) => DisableDeleteButton(rows) ? getTranslatedText('A6', 'deleteMultipleRecords') : null
          }
        ]}
        showNotification={showNotification}
        sortConfig={{ type: 'column', defaultField: 'createdDate', defaultDirection: 'DESC' }}
        persistSettings
        tableRef={tableRef}
        tablePreference={{ module: 'ACIOCN_A6', key: 'a6_table_visible_column' }}
      />
    )}
      <ConfirmDialog
        isConfirmDialogOpen={confirmDelete.open}
        closeDialog={() => setConfirmDelete({ open: false, a6: null })}
        confirmDialog={handleDeleteA6}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={getTranslatedText('A6', 'deleteButton')}
      />

      <ConfirmDialog
        isConfirmDialogOpen={confirmMassDelete.open}
        closeDialog={() => setConfirmMassDelete({ open: false, a6: [] })}
        confirmDialog={handleMassDeleteA6}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={getTranslatedText('A6', 'deleteButton')}
      />

      <ConfirmDialog
        isConfirmDialogOpen={confirmSubmit.open}
        closeDialog={() => setConfirmSubmit({ open: false, a6: [] })}
        confirmDialog={handleSubmitA6}
        content='Are you sure you want to submit the record(s) for Processing?'
        okMsg='Submit'
        cancelMsg='Cancel'
        title={getTranslatedText('A6', 'submitButton')}
      />
    </>
  )
}

export default TablePage
