import React, { useContext } from 'react'
import { components, constants } from 'cng-web-lib'
import { useFormContext } from 'react-hook-form'
import { Box, Grid, Typography, Card, useTheme } from '@material-ui/core'
import { AesTranslationContext } from '../contexts/AESTranslationContext'
import CngField from '../../../components/cngcomponents/CngField'
import CngSection from 'src/components/cngcomponents/CngSection'

const {
  button: { CngButton},
  CngDialog
} = components

function PartyScreeningDialog(props) {
  const {
    partyScreening,
    isView,
    onClose,
    open,
    showNotification,
  } = props

  const { getTranslatedText } = useContext(AesTranslationContext)

  const theme = useTheme()

  if (!open) {
    return null
  }

  let viewContent = partyScreening && (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} lg={4}>
            <CngField label={getTranslatedText('AES', 'name')}>
              {partyScreening.name}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} lg={8}>
            <CngField label={getTranslatedText('AES', 'remarks')}>
              {partyScreening.remarks}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <CngField label={getTranslatedText('AES', 'source')}>
              {partyScreening.source}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} lg={8}>
            <CngField label={getTranslatedText('AES', 'sourceInformationUrl')}>
              {partyScreening.sourceInformationUrl}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <CngField label={getTranslatedText('AES', 'sourceListUrl')}>
              {partyScreening.sourceListUrl}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <CngField label={getTranslatedText('AES', 'standardOrder')}>
              {partyScreening.standardOrder}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <CngField label={getTranslatedText('AES', 'federalRegisterNotice')}>
              {partyScreening.federalRegisterNotice}
            </CngField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CngSection
          title={getTranslatedText('AES', 'addressDetails')}
        >
          {partyScreening.partyScreeningAddressDTOList && partyScreening.partyScreeningAddressDTOList.length > 0 ? (
            <Grid container spacing={2}>
              {partyScreening.partyScreeningAddressDTOList.map((partyScreeningAddress) => {
                return (
                  <Grid key={partyScreeningAddress.id || partyScreeningAddress._id} item xs={12}>
                    <Card variant='outlined' style={{ backgroundColor: theme.palette.grey[50] }}>
                      <Box padding={2}>
                        <Grid container spacing={2}>
                          
                          <Grid xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText('AES', 'address')}>
                              {partyScreeningAddress.address}
                            </CngField>
                          </Grid>
                          <Grid xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText('AES', 'city')}>
                              {partyScreeningAddress.city}
                            </CngField>
                          </Grid>
                          <Grid xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText('AES', 'state')}>
                              {partyScreeningAddress.state}
                            </CngField>
                          </Grid>
                          <Grid xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText('AES', 'postalCode')}>
                              {partyScreeningAddress.postalCode}
                            </CngField>
                          </Grid>
                          <Grid xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText('AES', 'country')}>
                              {partyScreeningAddress.country}
                            </CngField>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>
                )
              })}
            </Grid>
          ) : (
            <Typography variant='body2' color='textSecondary'>
              {getTranslatedText('AES', 'noRecordToDisplay')}
            </Typography>
          )}
            </CngSection>
            </Grid>
    </Grid>
  )

  return (
    <CngDialog
      dialogContent={
        partyScreening && (
          viewContent
        )
      }
      dialogTitle={getTranslatedText('AES', 'partyScreeningDetails')}
      dialogAction={
        isView ? <CngButton onClick={onClose}>{getTranslatedText('AES', 'okButton')}</CngButton> : null
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default PartyScreeningDialog
