import React, { useState,useEffect,useContext } from 'react'
import { components, useServices, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import { Box, Grid, Paper } from '@material-ui/core'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import AesShipmentApiUrls from 'src/apiUrls/AesShipmentApiUrls'
import ErrorMessageSection from 'src/views/common/ErrorMessageSection'
import { AesTranslationContext } from '../../contexts/AESTranslationContext'

const {
  button: { CngButton },
  dropzone: { CngFileUpload },
  CngDialog
} = components

function ShipmentCSVUploadDialog(props) {
  const { onClose, open, showNotification } = props

  const [files, setFiles] = useState([])
  const [errorMessages, setErrorMessages] = useState([])
  const [loading, setLoading] = useState(false)
  const { securedSendRequest } = useServices()
  const { getTranslatedText } = useContext(AesTranslationContext)

  useEffect(() => {
    setFiles([])
    setErrorMessages([])
  }, [open]);

  function handleUploadShipment() {

    if(files.length>0){
      try {
        setLoading(true)

        let fileForUserDetails = FileForUserGetUserDetails();
        const formData = new FormData()

        formData.append('file', files[0].file)
        if (fileForUserDetails != null && fileForUserDetails != undefined) {
          formData.append("fileForUserDetails", JSON.stringify(fileForUserDetails))
        } else {
          formData.append("fileForUserDetails", "{}")
        }

        securedSendRequest.execute(
          'POST',
          AesShipmentApiUrls.UPLOAD,
          formData,
          (response) => {
            console.log(response)
            if (response.status === 200) {
              if (response.data) {
                const errorMessages = response.data.errorMessages

                if (errorMessages) {
                  setErrorMessages(errorMessages)
                  setFiles([])
                }
              }else{
                showNotification('success', getTranslatedText('AES', 'uploadSuccessMsg'))
                onClose()
                open.tableRef.current.performRefresh()
              }
            }
          },
          () => {
            showNotification('error', getTranslatedText('AES', 'uploadErrorMsg'))
            setFiles([])
          },
          () => setLoading(false)
        )
      } catch (error) {
        console.log(error)
      }
    }else{
      showNotification('error', getTranslatedText('AES', 'fileEmptyMsg'))
    }
  }

  return (
    <CngDialog
      dialogTitle={getTranslatedText('AES', 'uploadDialogTitle')}
      dialogContent={
        <Paper variant='outlined'>
          <Box padding={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CngFileUpload
                  accept={['.csv']}
                  files={files}
                  maxFiles={1}
                  maxSize={5.3e+7}
                  onFileSelect={(file) => setFiles(file)}
                  showFormFields={false}
                  moreActions={[
                    {
                      action: 'remove',
                      name: 'Remove',
                      icon: ['fal', 'trash']
                    }
                  ]}
                  onDropRejected={(errorMessages) => showNotification('error', errorMessages)}
                />
              </Grid>

              <ErrorMessageSection errorMessages={errorMessages} />

            </Grid>
          </Box>
        </Paper>
      }
      dialogAction={
        <>
          <CngButton color='secondary' disabled={loading} onClick={onClose}>
            {getTranslatedText('AES', 'discardButton')}
          </CngButton>
          <CngButton
            color='primary'
            disabled={loading}
            onClick={handleUploadShipment}
            shouldShowProgress={loading}
          >
            {getTranslatedText('AES', 'saveButton')}
          </CngButton>
        </>
      }
      fullWidth
      maxWidth='lg'
      open={open.open}
      onClose={onClose}
      shouldShowCloseButton
    />
  )
}

export default ShipmentCSVUploadDialog
