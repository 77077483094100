import React, { useEffect, useState, useContext } from "react";
import { constants, useServices } from 'cng-web-lib'
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core'
import { A6TranslationContext } from './contexts/A6TranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import A6ResponseApiUrls from "src/apiUrls/A6ResponseApiUrls";
import ErrorResponse from './ErrorResponse';
import D4Response from './D4Response'
import clsx from 'clsx'
import moment from 'moment'

const { filter: { EQUAL } } = constants

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

function A6Response({ showNotification, a6Id, ccnValue }) {
  const { fetchRecords } = useServices()
  const { getTranslatedText } = useContext(A6TranslationContext)

  const [data, setData] = useState([])

  function fetchA6Response() {
    return fetchRecords.execute(A6ResponseApiUrls.GET,
      {
        filters: [
          { field: 'headerId', operator: EQUAL, value: a6Id }
        ]
      }, (res) => res.content)
  }

  function showA6Response() {
    if (a6Id) {
      Promise.all([fetchA6Response()])
        .then(([respData]) => setData(
          respData.sort(function (a, b) {
            return new Date(b.cbsaProcessingDate) - new Date(a.cbsaProcessingDate);
          })
        ))
    }
  }

  useEffect(() => { showA6Response() }, [])

  const columns = [
    {
      field: "convRefNo",
      title: getTranslatedText('A6', 'convRefno')
    },
    {
      field: "cbsaProcessingDate",
      title: "CBSA Processed DateTime",
      render: (rowData) =>
        rowData.cbsaProcessingDate &&
        moment(rowData.cbsaProcessingDate).tz("Canada/Eastern").format('DD/MM/YYYY HH:mm:ss')
    },
    {
      field: "receivedDate",
      title: "Received DateTime",
      render: (rowData) =>
        rowData.receivedDate &&
        moment(rowData.receivedDate).tz("Canada/Eastern").format('DD/MM/YYYY HH:mm:ss')
    },
    {
      field: "errorCode",
      title: "Error Information",
      render: (rowData) => (renderErrorMessage(rowData)),
      cellStyle: () => { return { width: '40%' } }
    }
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CngSection title="A6 Response">
          <A6ResponseTable data={data} columns={columns} />
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={getTranslatedText('A6', 'd4title')}>
          <D4Response showNotification={showNotification} a6Id={a6Id} ccnValue={ccnValue} />
        </CngSection>
      </Grid>
    </Grid>
  )
}
export default A6Response;

function A6ResponseTable(props) {
  const { data, columns } = props

  const classes = useStyles()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }

  return (
    <>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((d) => (
                  <TableRow key={d.id}>
                    {columns.map((column, index) => (
                      <TableCell key={index}>
                        {column.render ? column.render(d) : d[column.field]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell align='center' colSpan={columns.length}>
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50, 100, 500, 1000]}
        component='div'
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  )

}

function renderErrorMessage(row) {
  return (
    <span>
      {row.errorResponses.map(responseErrorDTOs => (
        <div>
          <ErrorResponse errorCode={responseErrorDTOs.errorCode}
            fieldDesc={responseErrorDTOs.responseErrorCodeDTO ? responseErrorDTOs.responseErrorCodeDTO.fieldDesc : ''}
            rejectType={responseErrorDTOs.rejectType}
            errorDesc={responseErrorDTOs.errorDesc}
            responseText={responseErrorDTOs.responseText} />
        </div>))}
    </span>
  )
}