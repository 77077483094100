import React, { useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Grid, Typography, } from '@material-ui/core'
import { A6TranslationContext } from '../contexts/A6TranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import TaciOcnA6SNDialog from '../TaciOcnA6SNDialog'
import TaciOcnA6SNTable from '../TaciOcnA6SNTable'

function A6SNSection() {
    const { setValue, getValues } = useFormContext();
    const { getTranslatedText } = useContext(A6TranslationContext)

    const [dialog, setDialog] = useState({ open: false, sn: null })

    const taciOcnA6SN = useWatch({ name: 'taciOcnA6SN' })

    function handleRemoveSN(data) {
        const taciOcnA6SN = [...getValues('taciOcnA6SN')].filter((sn) =>
            sn.id ? sn.id !== data.id : sn._id !== data._id
        )

        setValue('taciOcnA6SN', taciOcnA6SN, { shouldValidate: true })
    }

    return (
        <Grid item xs={12}>
            <CngSection
                title={
                    <Typography variant='h5' style={{ fontWeight: 700 }}>
                        {getTranslatedText('A6', 'taciOcnA6SN') + ' '}
                        <Typography color='textSecondary' component='span' variant='caption'>
                            {`/ ${taciOcnA6SN.length} Entries`}
                        </Typography>
                    </Typography>
                }
                alignItems='flex-end'
            >

                <TaciOcnA6SNTable
                    data={taciOcnA6SN}
                    onAddSN={() => setDialog({ open: true, sn: null })}
                    onEditSN={(sn) => setDialog({ open: true, sn })}
                    onDeleteSN={handleRemoveSN}
                />

                <TaciOcnA6SNDialog
                    onClose={() => setDialog({ open: false, sn: null })}
                    open={dialog.open}
                    sn={dialog.sn}
                />
            </CngSection>
        </Grid>
    )
}

export default A6SNSection