import makeValidationSchema from './PgaDetailsMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AesShipmentKeys from 'src/constants/locale/key/AesShipment'
import { Card, CardContent, Grid, Tooltip } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'
import PgaDetailsFormProperties from './PgaDetailsFormProperties'

const {
  button: { CngIconButton }
} = components

const { initialValues } = PgaDetailsFormProperties.formikProps

function PgaDetailsEntry({
  index,
  isLast,
  onAddPgaDetails,
  onDeletePgaDetails,
  pgaDetails,
  showNotification
}) {

  return (

    <Grid container spacing={2}>
      <Grid item xs={12} md>
        <PgaDetailsFormProperties.Fields index={index} showNotification={showNotification} />
      </Grid>
      <Grid item xs={12} md='auto'>
        <Grid container spacing={1}>
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title='Add new'>
              <span>
                <CngIconButton
                  icon={['fal', 'plus']}
                  onClick={() => onAddPgaDetails(initialValues)}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title='Clone'>
              <span>
                <CngIconButton
                  icon={['fal', 'copy']}
                  onClick={() => onAddPgaDetails({ ...pgaDetails, id: undefined })}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
          {!isLast && (
            <Grid item xs='auto'>
              <Tooltip placement='bottom' title='Delete'>
                <span>
                  <CngIconButton
                    icon={['fal', 'trash']}
                    onClick={() => onDeletePgaDetails(pgaDetails)}
                    size='small'
                    type='outlined'
                  />
                </span>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PgaDetailsEntry
