import React, { useContext } from 'react'
import { components } from 'cng-web-lib'
import { Grid } from '@material-ui/core'
import makeValidationSchema from './TaciOcnA6ContainerMakeValidationSchema'
import { A6TranslationContext } from './contexts/A6TranslationContext'
import _ from 'lodash'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngTextField, CngCodeMasterAutocompleteField },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  containerSize: "",
  containerSizeOth: "",
  containerStatus: "",
  noOfContainers: "",
  othContainerSize: false
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { getTranslatedText } = useContext(A6TranslationContext)
  const { setValue, watch } = useFormContext()
  const containerSize = watch('containerSize')

  return (
    <Grid container spacing={2}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.containerSize}>
        <Grid container spacing={2}>
          <CngGridItem xs={12} sm={containerSize === 'OTHR' ? 6 : 12} shouldHide={shouldHideMap.containerSize} >
            <CngCodeMasterAutocompleteField
              key={containerSize}
              name='containerSize'
              label={getTranslatedText('A6', 'containerSize')}
              disabled={disabled}
              codeType='A6_CONTAINER_SIZE'
              required
              size="small"
            />
          </CngGridItem>

          {containerSize === 'OTHR' && <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.containerSize} ><CngTextField
            name='containerSizeOth'
            label={getTranslatedText('A6', 'containerSize')}
            disabled={disabled}
            required
            size="small"
            inputProps={{ maxLength: 4 }}
            helperText="4 characters valid container size (eg: 20GP)"
          /></CngGridItem>}
        </Grid>
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.containerStatus}>
        <CngCodeMasterAutocompleteField
          name='containerStatus'
          label={getTranslatedText('A6', 'containerStatus')}
          disabled={disabled}
          codeType='A6_CONTAINER_STATUS'
          required
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.noOfContainers}>
        <CngTextField
          name="noOfContainers"
          inputProps={{ maxLength: 4 }}
          label={getTranslatedText('A6', 'noOfContainers')}
          disabled={disabled}
          required
          size="small"
        />
      </CngGridItem>
    </Grid >
  )
}

const TaciOcnA6ContainerFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default TaciOcnA6ContainerFormProperties
