import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  return Yup.object({
    containerSize: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9]*$/, "Cannot contain special characters.").min(4, "Min length allowed is 4").max(4, "Max length allowed is 4").nullable(),
    containerSizeOth: Yup.string().when('containerSize', {
      is: (value) => (value === 'OTHR'),
      then: Yup.string().matches(/^[a-zA-Z0-9]*$/, "Cannot contain special characters.").min(4, "Min length allowed is 4").max(4, "Max length allowed is 4").nullable(),
    }).nullable(),
    containerStatus: Yup.string().required(requiredMessage).nullable(),
    noOfContainers: Yup.string().required(requiredMessage).matches(/^[1-9]|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9]+$/,"Must be numeric value between 1 to 9999").nullable(),
  })
}

export default makeValidationSchema
