import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    name: Yup.string().nullable().required(requiredMessage).matches("[a-zA-Z]$", "Only alpha values are allowed.").max(50,"Max Length allowed is 50"),
    email:  Yup.string().nullable().required(requiredMessage).max(300,"Max 300 characters are allowed ").min(5,"Min 5 characters are allowed").matches("^[A-Za-z0-9+_.-]+@(.+)$", "Please enter valid email id"),
    phno: Yup.lazy((value) => value === '' ? Yup.string() : Yup.string().matches(/^\d+$/, "Only numbers are allowed!").nullable()),
    snTyp: Yup.string().required(requiredMessage).nullable(),
  })
}

export default makeValidationSchema
