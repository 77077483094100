import {
  components,
  constants,
  useTranslation,
  useServices
} from 'cng-web-lib'

import AesProductMasterApiUrls from 'src/apiUrls/AesProductMasterApiUrls'
import React, { useRef, useContext, useState, useEffect } from 'react'
import Table from 'src/components/aciacehighway/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import pathMap from 'src/paths/pathMap'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { AesTranslationContext } from '../contexts/AESTranslationContext'
import ConfirmDialog from '../../common/ConfirmDialog'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateRangeFilter: CngDateRangeFilter,
    useDefaultNotification,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: { BETWEEN, EQUAL, IN, LIKE }
} = constants

function TablePage(props) {
  const {
    location,
    showNotification,
    history
  } = props

  const notification = useDefaultNotification(showNotification)
  const { getTranslatedText } = useContext(AesTranslationContext)
  const [confirmDialog, setConfirmDialog] = useState({ open: false, shipment: null })
  const { createRecord, deleteRecord, fetchRecords, securedSendRequest } = useServices()
  const [uploadCSVShipmentDialog, setUploadCSVShipmentDialog] = useState({ open: false, tableRef: null });
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const [lookups, setLookups] = useState(null)

  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'AES_LICENSE_EXEMPTION' }],
        undefined,
        'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'AES_ITAR_EXEMPTION' }],
        undefined,
        'code'
      )
    ]).then(
      ([aesLicenseExemption, aesItarExemption]) => {

        setLookups({
          aesLicenseExemption,
          aesItarExemption
        });
      }
    )
  }, []);

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const tableRef = useRef(null)

  const columns = [
    {
      field: "productId",
      sortKey: 'productId',
      title: getTranslatedText('AES', 'productId'),
    },
    {
      field: "htsNumber",
      sortKey: 'htsNumber',
      title: getTranslatedText('AES', 'htsNumber'),
    },
    {
      field: "licenseExemptionCode",
      sortKey: 'licenseExemptionCode',
      title: getTranslatedText('AES', 'licenseExemptionCode'),
      render: (data) => {
        return data.licenseExemptionCode && data.licenseExemptionCode+";"+getLookupValue('aesLicenseExemption',data.licenseExemptionCode)
      }
    },
    {
      field: "itarExemptionCode",
      sortKey: 'itarExemptionCode',
      title: getTranslatedText('AES', 'itarExemptionCode'),
      render: (data) => {
        return data.itarExemptionCode && data.itarExemptionCode+";"+getLookupValue('aesItarExemption',data.itarExemptionCode)
      }
    }
  ]

  const filters = [
    {
      label: getTranslatedText('AES', 'productId'),
      type: 'textfield',
      name: 'productId',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('AES', 'htsNumber'),
      type: 'textfield',
      name: 'htsNumber',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('AES', 'licenseExemptionCode'),
      type: 'textfield',
      name: 'licenseExemptionCode',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('AES', 'tritarExemptionCodeipNo'),
      type: 'textfield',
      name: 'itarExemptionCode',
      operatorType: LIKE
    },

  ]

  function handleDeleteShipment() {
    if (confirmDialog.shipment) {
      deleteRecord.execute(
        AesProductMasterApiUrls.DELETE,
        confirmDialog.shipment,
        (res) => {
          showNotification('success', getTranslatedText('AES', 'deleteSuccessMsg'))
          setConfirmDialog({ open: false, shipment: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          console.log(error)
          setConfirmDialog({ open: false, shipment: null })

          if (error.response) {
            const errorMessages = error.response.data.errorMessages
            errorMessages.forEach((message) =>
              showNotification('error', message)
            )
          }
        }
      )
    }
  }

  function handleCloneProductMaster(data) {
    createRecord.execute(
      AesProductMasterApiUrls.CLONE,
      data,
      (commodity) => {
       
        if (commodity) {
            const usedVehicleData = commodity.usedVehicleDetails && commodity.usedVehicleDetails.map(
              (usedVehicleDetails, index) => ({ ...usedVehicleDetails, _id: index + 1 }))
            commodity.usedVehicleDetails = usedVehicleData

            const pgaData = commodity.pgaDetails && commodity.pgaDetails.map(
              (pgaDetails, index) => ({ ...pgaDetails, _id: index + 1 }))
            commodity.pgaDetails = pgaData
        }
        history.push({
          pathname: pathMap.AES_PRODUCT_MASTER_ADD_VIEW,
          state: commodity
        })
      },
      (error) => {
        console.log(error)
      }
    )
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.AES_PRODUCT_MASTER_ADD_VIEW)
            },
            label: getTranslatedText('AES', 'createButton')
          },
        ]}
        columns={columns}
        compact
        exportData={{ url: AesProductMasterApiUrls.EXPORT }}
        fetch={{ url: AesProductMasterApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) => {
          history.push(`${location.pathname}/edit/${rowData.id}`)
        }
        }
        rowActions={[
          {
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: getTranslatedText('AES', 'editButton'),
            onClick: (rowData) =>
              history.push(pathMap.AES_PRODUCT_MASTER + `/edit/${rowData.id}`),
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: getTranslatedText('AES', 'cloneButton'),
            onClick: handleCloneProductMaster
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: getTranslatedText('AES', 'deleteButton'),
            onClick: (rowData) =>
              setConfirmDialog({ open: true, shipment: rowData }),
          }
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
        tablePreference={{ module: 'AES', key: 'product_master_table_visible_column' }}
        selectActions={[
        ]}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, houseBill: null })}
        confirmDialog={handleDeleteShipment}
        content={getTranslatedText('AES', 'deleteConfirmationMsg')}
        okMsg={getTranslatedText('AES', 'deleteConfirmButton')}
        cancelMsg={getTranslatedText('AES', 'cancelActionButton')}
        title={getTranslatedText('AES', 'deleteButton')}
      />

    </>
  )
}

export default TablePage
