import React, { useContext } from "react";
import MaterialTable from "material-table"
import { A6TranslationContext } from './contexts/A6TranslationContext'

function ErrorResponse({ errorCode,fieldDesc, rejectType, errorDesc, responseText }) {
  const { getTranslatedText } = useContext(A6TranslationContext)

  return (
    <span>
      <MaterialTable
        options={{
          paging: false,
          showTitle: false,
          search: false,
          sorting: false,
          draggable: false,
          toolbar: false
        }}
        columns={[
          { title: getTranslatedText('A6', 'errorCode'), field: "code" },
          { title: getTranslatedText('A6', 'fieldDesc'), field: "fieldDesc" },
          { title: getTranslatedText('A6', 'rejectType'), field: "rejectType" },
          { title: getTranslatedText('A6', 'errorDesc'), field: "description" },
          { title: getTranslatedText('A6', 'responseText'), field: "text" },
        ]}
        data={[
          {
            code: errorCode,
            fieldDesc: fieldDesc,
            rejectType: rejectType,
            description: errorDesc,
            text: responseText
          }
        ]}
      />
    </span>
  )
}
export default ErrorResponse;