import React, { useRef, useContext } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import CommodityDetailsFormProperties from './CommodityDetailsFormProperties'
import { useFormContext } from 'react-hook-form'
import { Box, Divider, Grid, Typography, Card, useTheme } from '@material-ui/core'
import { AesTranslationContext } from '../contexts/AESTranslationContext'
import CngField from '../../../components/cngcomponents/CngField'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import CngSection from 'src/components/cngcomponents/CngSection'

const {
  button: { CngButton, CngPrimaryButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

function CommodityDetailsDialog(props) {
  const {
    commodityDetails,
    isView,
    onClose,
    open,
    showNotification,
    getLookupValue,
    getCountryStateLabel,
    modeOfTransport,
    calculateTotalWeight
  } = props

  const { getTranslatedText } = useContext(AesTranslationContext)

  const { initialValues, makeValidationSchema } = CommodityDetailsFormProperties.formikProps
  const theme = useTheme()

  const lastIndex = useRef(isView ? null : 0)
  const formContext = useFormContext()

  function handleAddCommodityDetails(data) {
    const commodityDetails = [...formContext.getValues('commodityDetails'), data].map(
      (commodityDetails) => ({
        ...commodityDetails,
        _id: ++lastIndex.current
      })
    )

    formContext.setValue('commodityDetails', commodityDetails, { shouldValidate: true })
    calculateTotalWeight(commodityDetails)
    onClose()
  }

  function handleEditCommodityDetails(data) {
    const commodityDetails = [...formContext.getValues('commodityDetails')]
    const index = commodityDetails.findIndex((commodityDetails) =>
      data.id ? commodityDetails.id === data.id : commodityDetails._id === data._id
    )

    formContext.setValue(`commodityDetails.${index}`, data, {
      shouldValidate: true
    })
    calculateTotalWeight(formContext.getValues('commodityDetails'))
    onClose()
  }

  if (!open) {
    return null
  }

  let viewContent = commodityDetails && (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} lg={4}>
            <CngField label={getTranslatedText('AES', 'productId')}>
              {commodityDetails.productId}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} lg={8} >
            <CngField label={getTranslatedText('AES', 'commodityDescription')}>
              {commodityDetails.commodityDescription}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} >
            <CngField label={getTranslatedText('AES', 'htsNumber')}>
              {commodityDetails.htsNumber}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} >
            <CngField label={getTranslatedText('AES', 'quantity1')}>
              {commodityDetails.quantity1}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <CngField label={getTranslatedText('AES', 'uom1')}>
              <Typography component='div' variant='inherit'>
                {getLookupValue('aesUom', commodityDetails.uom1)}
                <TinyChip label={commodityDetails.uom1} variant='outlined' />
              </Typography>
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <CngField label={getTranslatedText('AES', 'valueOfGoods')}>
              {commodityDetails.valueOfGoods}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <CngField label={getTranslatedText('AES', 'quantity2')}>
              {commodityDetails.quantity2}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <CngField label={getTranslatedText('AES', 'uom2')}>
              <Typography component='div' variant='inherit'>
                {getLookupValue('aesUom', commodityDetails.uom2)}
                <TinyChip label={commodityDetails.uom2} variant='outlined' />
              </Typography>
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <CngField label={getTranslatedText('AES', 'shippingWeight')}>
              {commodityDetails.shippingWeight}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <CngField label={getTranslatedText('AES', 'originOfGoods')}>
              <Typography component='div' variant='inherit'>
                {getLookupValue('aesOriginOfGoods', commodityDetails.originOfGoods)}
                <TinyChip label={commodityDetails.originOfGoods} variant='outlined' />
              </Typography>
            </CngField>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <CngSection
          title={getTranslatedText('AES', 'licenseDetails')}
        >
          <Grid container spacing={1}>
            <Grid xs={12} sm={6} lg={4} >
              <CngField label={getTranslatedText('AES', 'licenseExemptionCode')}>
                <Typography component='div' variant='inherit'>
                  {getLookupValue('aesLicenseExemption', commodityDetails.licenseExemptionCode)}
                  <TinyChip label={commodityDetails.licenseExemptionCode} variant='outlined' />
                </Typography>
              </CngField>
            </Grid>
            <Grid xs={12} sm={6} lg={4}>
              <CngField label={getTranslatedText('AES', 'exportLicenseNumber')}>
                {commodityDetails.exportLicenseNumber}
              </CngField>
            </Grid>
            <Grid xs={12} sm={6} lg={4} >
              <CngField label={getTranslatedText('AES', 'eccn')}>
                {commodityDetails.eccn}
              </CngField>
            </Grid>
            <Grid xs={12} sm={6} lg={4} >
              <CngField label={getTranslatedText('AES', 'exportInformationCode')}>
                <Typography component='div' variant='inherit'>
                  {getLookupValue('aesExportInformation', commodityDetails.exportInformationCode)}
                  <TinyChip label={commodityDetails.exportInformationCode} variant='outlined' />
                </Typography>
              </CngField>
            </Grid>
            <Grid xs={12} sm={6} lg={4}>
              <CngField label={getTranslatedText('AES', 'licenseValue')}>
                {commodityDetails.licenseValue}
              </CngField>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>

      <Grid item xs={12}>
        <CngSection
          title={getTranslatedText('AES', 'ddtcDetails')}
        >
          <Grid container spacing={1}>
            <Grid xs={12} sm={6} lg={4}>
              <CngField label={getTranslatedText('AES', 'itarExemptionCode')}>
                <Typography component='div' variant='inherit'>
                  {getLookupValue('aesItarExemption', commodityDetails.itarExemptionCode)}
                  <TinyChip label={commodityDetails.itarExemptionCode} variant='outlined' />
                </Typography>
              </CngField>
            </Grid>
            <Grid xs={12} sm={6} lg={4}>
              <CngField label={getTranslatedText('AES', 'usmlCategoryCode')}>
                <Typography component='div' variant='inherit'>
                  {getLookupValue('aesUsmlCategory', commodityDetails.usmlCategoryCode)}
                  <TinyChip label={commodityDetails.usmlCategoryCode} variant='outlined' />
                </Typography>
              </CngField>
            </Grid>
            <Grid xs={12} sm={6} lg={4}>
              <CngField label={getTranslatedText('AES', 'registrationNumber')}>
                {commodityDetails.registrationNumber}
              </CngField>
            </Grid>
            <Grid xs={12} sm={6} lg={4}>
              <CngField label={getTranslatedText('AES', 'ddtcQuantity')}>
                {commodityDetails.ddtcQuantity}
              </CngField>
            </Grid>
            <Grid xs={12} sm={6} lg={4}>
              <CngField label={getTranslatedText('AES', 'ddtcUom')}>
                <Typography component='div' variant='inherit'>
                  {getLookupValue('aesUOM', commodityDetails.ddtcUom)}
                  <TinyChip label={commodityDetails.ddtcUom} variant='outlined' />
                </Typography>
              </CngField>
            </Grid>
            <Grid xs={12} sm={6} lg={4}>
              <CngField label={getTranslatedText('AES', 'eligiblePartyCertificationInd')}>
                {commodityDetails.eligiblePartyCertificationInd != null && commodityDetails.eligiblePartyCertificationInd != undefined && commodityDetails.eligiblePartyCertificationInd != "" ? commodityDetails.eligiblePartyCertificationInd ? getTranslatedText('AES', 'yes') : getTranslatedText('AES', 'no') : ""}
              </CngField>
            </Grid>
            <Grid xs={12} sm={6} lg={4}>
              <CngField label={getTranslatedText('AES', 'significantMilitaryEquipmentInd')}>
                {commodityDetails.significantMilitaryEquipmentInd != null && commodityDetails.significantMilitaryEquipmentInd != undefined && commodityDetails.significantMilitaryEquipmentInd != "" ? commodityDetails.significantMilitaryEquipmentInd ? getTranslatedText('AES', 'yes') : getTranslatedText('AES', 'no') : ""}
              </CngField>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection
          title={getTranslatedText('AES', 'usedVehicleDetails')}
        >
          {commodityDetails.usedVehicleDetails.length > 0 ? (
            <Grid container spacing={2}>
              {commodityDetails.usedVehicleDetails.map((usedVehicle) => {
                return (
                  <Grid key={usedVehicle.id || usedVehicle._id} item xs={12}>
                    <Card variant='outlined' style={{ backgroundColor: theme.palette.grey[50] }}>
                      <Box padding={2}>
                        <Grid container spacing={2}>
                          <Grid xs={12} sm={6} lg={4} >
                            <CngField label={getTranslatedText('AES', 'vehicleIdType')}>
                              <Typography component='div' variant='inherit'>
                                {getLookupValue('aesVehicleIdType', usedVehicle.vehicleIdType)}
                                <TinyChip label={usedVehicle.vehicleIdType} variant='outlined' />
                              </Typography>
                            </CngField>
                          </Grid>
                          <Grid xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText('AES', 'vehicleIdNumber')}>
                              {usedVehicle.vehicleIdNumber}
                            </CngField>
                          </Grid>
                          <Grid xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText('AES', 'vehicleTitleNumber')}>
                              {usedVehicle.vehicleTitleNumber}
                            </CngField>
                          </Grid>
                          <Grid xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText('AES', 'vehicleTitleState')}>
                            <Typography component='div' variant='inherit'>
                              {getCountryStateLabel("US", usedVehicle.vehicleTitleState)}
                              <TinyChip label={usedVehicle.vehicleTitleState} variant='outlined' />
                              </Typography>
                            </CngField>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>
                )
              })}
            </Grid>
          ) : (
            <Typography variant='body2' color='textSecondary'>
              {getTranslatedText('AES', 'noRecordToDisplay')}
            </Typography>
          )}
        </CngSection>
      </Grid>

      <Grid item xs={12}>
        <CngSection
          title={getTranslatedText('AES', 'pgaDetails')}
        >
          {commodityDetails.pgaDetails.length > 0 ? (
            <Grid container spacing={2}>
              {commodityDetails.pgaDetails.map((pga) => {
                return (
                  <Grid key={pga.id || pga._id} item xs={12}>
                    <Card variant='outlined' style={{ backgroundColor: theme.palette.grey[50] }}>
                      <Box padding={2}>
                        <Grid container spacing={2}>
                          <Grid xs={12} sm={6} lg={4} >
                            <CngField label={getTranslatedText('AES', 'pgaId')}>
                              <Typography component='div' variant='inherit'>
                                {getLookupValue('pgaId', pga.pgaId)}
                                <TinyChip label={pga.pgaId} variant='outlined' />
                              </Typography>
                            </CngField>
                          </Grid>
                          {pga.pgaId == "AM1" && (
                            <Grid xs={12} sm={6} lg={4}>
                              <CngField label={getTranslatedText('AES', 'amsCertificateNumber')}>
                                {pga.amsCertificateNumber}
                              </CngField>
                            </Grid>
                          )}
                          {pga.pgaId == "EP1" && (
                            <>
                              <Grid xs={12} sm={6} lg={4}>
                                <CngField label={getTranslatedText('AES', 'epaLicenseRequired')}>
                                  {pga.epaLicenseRequired ? getTranslatedText('AES', 'yes') : getTranslatedText('AES', 'no')}
                                </CngField>
                              </Grid>
                              {pga.epaLicenseRequired == true && (
                                <>
                                  <Grid xs={12} sm={6} lg={4}>
                                    <CngField label={getTranslatedText('AES', 'epaConsentNumber')}>
                                      {pga.epaConsentNumber}
                                    </CngField>
                                  </Grid>
                                  <Grid xs={12} sm={6} lg={4}>
                                    <CngField label={getTranslatedText('AES', 'rcraHazardousWasteTrackingNumber')}>
                                      {pga.rcraHazardousWasteTrackingNumber}
                                    </CngField>
                                  </Grid>
                                  <Grid xs={12} sm={6} lg={4}>
                                    <CngField label={getTranslatedText('AES', 'epaNetQuantity')}>
                                      {pga.epaNetQuantity}
                                    </CngField>
                                  </Grid>
                                  <Grid xs={12} sm={6} lg={4}>
                                    <CngField label={getTranslatedText('AES', 'epaUom')}>
                                      <Typography component='div' variant='inherit'>
                                        {getLookupValue('aesUOM', pga.epaUom)}
                                        <TinyChip label={pga.epaUom} variant='outlined' />
                                      </Typography>
                                    </CngField>
                                  </Grid>
                                </>
                              )}
                            </>
                          )}
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>
                )
              })}
            </Grid>
          ) : (
            <Typography variant='body2' color='textSecondary'>
              {getTranslatedText('AES', 'noRecordToDisplay')}
            </Typography>
          )}

        </CngSection>
      </Grid>
    </Grid>
  )

  return (
    <CngDialog
      dialogContent={
        commodityDetails && isView ? (
          viewContent
        ) : (
          <CngForm
            fieldLevel='form'
            formikProps={{
              initialValues: commodityDetails || initialValues,
              makeValidationSchema: makeValidationSchema,
              onSubmit: (data) => {
                if (commodityDetails) {
                  handleEditCommodityDetails(data)
                } else {
                  handleAddCommodityDetails(data)
                }
              }
            }}
            formState={FormState.COMPLETED}
            innerForm={true}
            renderBodySection={(labelMap, shouldHideMap) => (
              <CommodityDetailsFormProperties.Fields
                showNotification={showNotification}
                shouldHideMap={shouldHideMap}
                modeOfTransport={modeOfTransport}
              />
            )}
            renderButtonSection={() => (
              <Box display='flex' justifyContent='flex-end'>
                <CngPrimaryButton
                  type='submit'
                  disabled={
                    formContext ? formContext.formState.isSubmitting : false
                  }
                >
                  {commodityDetails
                    ? getTranslatedText('AES', 'updateButton')
                    : getTranslatedText('AES', 'saveButton')
                  }
                </CngPrimaryButton>
              </Box>
            )}
          />
        )
      }
      dialogTitle={getTranslatedText('AES', 'commodityDetails')}
      dialogAction={
        isView ? <CngButton onClick={onClose}>{getTranslatedText('AES', 'okButton')}</CngButton> : null
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default CommodityDetailsDialog
