import React, { useRef, useContext } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import PartyDetailsFormProperties from './PartyDetailsFormProperties'
import { useFormContext } from 'react-hook-form'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { AesTranslationContext } from '../contexts/AESTranslationContext'
import CngField from '../../../components/cngcomponents/CngField'
import TinyChip from '../../../components/aciacehighway/TinyChip'

const {
  button: { CngButton, CngPrimaryButton },
  form: { CngForm, CngGridItem },
  CngDialog
} = components

const { FormState } = constants

function PartyDetailsDialog(props) {
  const {
    partyDetails,
    isView,
    onClose,
    open,
    showNotification,
    getLookupValue,
    getCountryStateLabel
  } = props

  const { getTranslatedText } = useContext(AesTranslationContext)

  const { initialValues, makeValidationSchema } = PartyDetailsFormProperties.formikProps

  const lastIndex = useRef(isView ? null : 0)
  const formContext = useFormContext()

  function handleAddPartyDetails(data) {
    const partyDetails = [...formContext.getValues('partyDetails'), data].map(
      (partyDetails) => ({
        ...partyDetails,
        _id: ++lastIndex.current
      })
    )

    formContext.setValue('partyDetails', partyDetails, { shouldValidate: true })
    onClose()
  }

  function handleEditPartyDetails(data) {
    const partyDetails = [...formContext.getValues('partyDetails')]
    const index = partyDetails.findIndex((partyDetails) =>
      data.id ? partyDetails.id === data.id : partyDetails._id === data._id
    )

    formContext.setValue(`partyDetails.${index}`, data, {
      shouldValidate: true
    })
    onClose()
  }

  if (!open) {
    return null
  }

  let viewContent = partyDetails && (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} lg={4}>
            <CngField label={getTranslatedText('AES', 'partyType')}>
              <Typography component='div' variant='inherit'>
                {getLookupValue('aesPartyType', partyDetails.partyType)}
                <TinyChip label={partyDetails.partyType} variant='outlined' />
              </Typography>
            </CngField>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} lg={4} >
                <CngField label={getTranslatedText('AES', 'partyIdentifierType')}>
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('aesPartyIdentifier', partyDetails.partyIdentifierType)}
                    <TinyChip label={partyDetails.partyIdentifierType} variant='outlined' />
                  </Typography>
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} >
                <CngField label={getTranslatedText('AES', 'partyIdentifier')}>
                  {partyDetails.partyIdentifier}
                </CngField>
              </Grid>
              {partyDetails.partyType == "C" && (
                <Grid item xs={12} sm={6} lg={4} >
                  <CngField label={getTranslatedText('AES', 'ultimateConsigneeType')}>
                    <Typography component='div' variant='inherit'>
                      {getLookupValue('aesUltimateConsigneeType', partyDetails.ultimateConsigneeType)}
                      <TinyChip label={partyDetails.ultimateConsigneeType} variant='outlined' />
                    </Typography>
                  </CngField>
                </Grid>
              )}
            </Grid>
          </Grid>
          {partyDetails.partyType == "E" && (
            <Grid item xs={12}>
              <Grid container spacing={1}>

                <Grid item xs={12} sm={6} lg={4} >
                  <CngField label={getTranslatedText('AES', 'usppiIrsNumberType')}>
                    <Typography component='div' variant='inherit'>
                      {getLookupValue('aesUsppiIdentifier', partyDetails.usppiIrsNumberType)}
                      <TinyChip label={partyDetails.usppiIrsNumberType} variant='outlined' />
                    </Typography>
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} >
                  <CngField label={getTranslatedText('AES', 'usppiIrsNumber')}>
                    {partyDetails.usppiIrsNumber}
                  </CngField>
                </Grid>

              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} lg={4}>
                <CngField label={getTranslatedText('AES', 'name')}>
                  {partyDetails.name}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <CngField label={getTranslatedText('AES', 'addressLine1')}>
                  {partyDetails.addressLine1}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <CngField label={getTranslatedText('AES', 'addressLine2')}>
                  {partyDetails.addressLine2}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <CngField label={getTranslatedText('AES', 'postalCode')}>
                  {partyDetails.postalCode}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <CngField label={getTranslatedText('AES', 'cityName')}>
                  {partyDetails.cityName}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <CngField label={getTranslatedText('AES', 'countryCode')}>
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('countries', partyDetails.countryCode)}
                    <TinyChip label={partyDetails.countryCode} variant='outlined' />
                  </Typography>
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={getTranslatedText('AES', 'stateCode')}>
                    <Typography component='div' variant='inherit'>
                      {getCountryStateLabel(partyDetails.countryCode, partyDetails.stateCode)}
                      <TinyChip label={partyDetails.stateCode} variant='outlined' />
                    </Typography>
                  </CngField>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <CngField label={getTranslatedText('AES', 'contactFirstName')}>
                  {partyDetails.contactFirstName}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <CngField label={getTranslatedText('AES', 'contactLastName')}>
                  {partyDetails.contactLastName}
                </CngField>
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <CngField label={getTranslatedText('AES', 'contactPhoneNumber')}>
                  {partyDetails.contactPhoneNumber}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <CngField label={getTranslatedText('AES', 'email')}>
                  {partyDetails.email}
                </CngField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  return (
    <CngDialog
      dialogContent={
        partyDetails && isView ? (
          viewContent
        ) : (
          <CngForm
            fieldLevel='form'
            formikProps={{
              initialValues: partyDetails || initialValues,
              makeValidationSchema: makeValidationSchema,
              onSubmit: (data) => {
                if (partyDetails) {
                  handleEditPartyDetails(data)
                } else {
                  handleAddPartyDetails(data)
                }
              }
            }}
            formState={FormState.COMPLETED}
            innerForm={true}
            renderBodySection={(labelMap, shouldHideMap) => (
              <PartyDetailsFormProperties.Fields
                showNotification={showNotification}
                shouldHideMap={shouldHideMap}
              />
            )}
            renderButtonSection={() => (
              <Box display='flex' justifyContent='flex-end'>
                <CngPrimaryButton
                  type='submit'
                  disabled={
                    formContext ? formContext.formState.isSubmitting : false
                  }
                >
                  {partyDetails
                    ? getTranslatedText('AES', 'updateButton')
                    : getTranslatedText('AES', 'saveButton')
                  }
                </CngPrimaryButton>
              </Box>
            )}
          />
        )
      }
      dialogTitle={getTranslatedText('AES', 'partyDetails')}
      dialogAction={
        isView ? <CngButton onClick={onClose}>{getTranslatedText('AES', 'okButton')}</CngButton> : null
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default PartyDetailsDialog
