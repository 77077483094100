import React, { useEffect, useState } from "react";
import { constants, useServices } from 'cng-web-lib'
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core'
import CngSection from 'src/components/cngcomponents/CngSection'
import AuditApiUrls from 'src/apiUrls/AuditApiUrl';
import clsx from 'clsx'
import moment from 'moment'


const { filter: { EQUAL } } = constants

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

function AuditLog({ showNotification, a6Id }) {
  const { fetchRecords } = useServices()

  const classes = useStyles()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [data, setData] = useState([])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }

  function fetchActivityLog() {
    return fetchRecords.execute(AuditApiUrls.GET,
      {
        filters: [
          { field: 'keyReferenceNo', operator: EQUAL, value: 'ACI_OCN_A6_' + a6Id }
        ]
      }, (res) => res.content)
  }

  function showActvityLog() {
    if (a6Id) {
      Promise.all([fetchActivityLog()])
        .then(([respData]) => setData(
          respData.sort(function (a, b) {
            return new Date(b.createdDate) - new Date(a.createdDate);
          })
        ))
    }
  }

  useEffect(() => { showActvityLog() }, [])

  const columns = [
    {
      field: "userId",
      title: "User ID",
    },
    {
      field: "createdDate",
      title: "Created DateTime",
      render: (rowData) => rowData.createdDate && moment(rowData.createdDate).format('DD/MM/YYYY HH:mm:ss')
    },
    {
      field: "functionCode",
      title: "Action",
    },
    {
      field: "auditDescription",
      title: "Remarks",
    }
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CngSection title="Activity Log">
          <TableContainer>
            <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell key={index}>{column.title}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length > 0 ? (
                  data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((d) => (
                      <TableRow key={d.id}>
                        {columns.map((column, index) => (
                          <TableCell key={index}>
                            {column.render ? column.render(d) : d[column.field]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell align='center' colSpan={columns.length}>
                      <Typography variant='body2' color='textSecondary'>
                        No records to display
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100, 500, 1000]}
            component='div'
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </CngSection>
      </Grid>
    </Grid>
  )
}
export default AuditLog;
