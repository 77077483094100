import makeValidationSchema from './TransportationDetailsMakeValidationSchema'
import React, { useContext } from 'react'
import { Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'
import { AesTranslationContext } from '../contexts/AESTranslationContext'
import { useFormContext } from 'react-hook-form'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  headerId: "",
  equipmentNumber: "",
  sealNumber: "",
  transportationReferenceNumber: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  shouldHideMap,
  index
}) {

  const { getTranslatedText } = useContext(AesTranslationContext)
  const { getValues, setValue, reset, watch, trigger } = useFormContext()

  return (

    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.equipmentNumber}>
        <CngTextField
          name={typeof index === 'number' ? `transportationDetails.${index}.equipmentNumber` : 'equipmentNumber'}
          label={getTranslatedText('AES', 'equipmentNumber')}
          disabled={disabled}
          size='small'
          onChange={(e) => {
            setValue(typeof index === 'number' ? `transportationDetails.${index}.equipmentNumber` : 'equipmentNumber', e.target.value.toUpperCase(), {
              shouldValidate: true
            })
          }}
          onBlur={(e) => {
            if (e.target.value !== undefined) {
              setValue(typeof index === 'number' ? `transportationDetails.${index}.equipmentNumber` : 'equipmentNumber', e.target.value.trim(), {
                shouldValidate: true
              })
            }
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.sealNumber}>
        <CngTextField
          name={typeof index === 'number' ? `transportationDetails.${index}.sealNumber` : 'sealNumber'}
          label={getTranslatedText('AES', 'sealNumber')}
          disabled={disabled}
          size='small'
          onChange={(e) => {
            setValue(typeof index === 'number' ? `transportationDetails.${index}.sealNumber` : 'sealNumber', e.target.value.toUpperCase(), {
              shouldValidate: true
            })
          }}
          onBlur={(e) => {
            if (e.target.value !== undefined) {
              setValue(typeof index === 'number' ? `transportationDetails.${index}.sealNumber` : 'sealNumber', e.target.value.trim(), {
                shouldValidate: true
              })
            }
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.transportationReferenceNumber}>
        <CngTextField
          name={typeof index === 'number' ? `transportationDetails.${index}.transportationReferenceNumber` : 'transportationReferenceNumber'}
          label={getTranslatedText('AES', 'transportationReferenceNumber')}
          disabled={disabled}
          size='small'
          onChange={(e) => {
            setValue(typeof index === 'number' ? `transportationDetails.${index}.transportationReferenceNumber` : 'transportationReferenceNumber', e.target.value.toUpperCase(), {
              shouldValidate: true
            })
          }}
          onBlur={(e) => {
            if (e.target.value !== undefined) {
              setValue(typeof index === 'number' ? `transportationDetails.${index}.transportationReferenceNumber` : 'transportationReferenceNumber', e.target.value.trim(), {
                shouldValidate: true
              })
            }
          }}
        />
      </CngGridItem>
    </Grid>
  )
}

const TransportationDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default TransportationDetailsFormProperties
