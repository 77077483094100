import React, { useContext } from 'react'
import { Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { A6TranslationContext } from '../contexts/A6TranslationContext'

const {
    form: { field: { CngCodeMasterAutocompleteField } },
    CngGridItem
} = components

function A6MessageFunctionSection(props) {
    const { disabled, shouldHideMap, addPage } = props
    const { getTranslatedText } = useContext(A6TranslationContext)

    return (
        <Grid item xs={12}>
            <CngGridItem xs={12} shouldHide={shouldHideMap.messageFunction}>
                <Grid container justify='center'>
                    <Grid item xs={12}>
                        <CngCodeMasterAutocompleteField
                            name='messageFunction'
                            label={getTranslatedText('A6', 'messageFunction')}
                            disabled={disabled}
                            codeType={addPage ? 'MESSAGE_FUNCTION_ADD' : 'MESSAGE_FUNCTION'}
                            required
                        />
                    </Grid>
                </Grid>
            </CngGridItem>
        </Grid>
    )
}

export default A6MessageFunctionSection