import AesProductMasterApiUrls from 'src/apiUrls/AesProductMasterApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useContext, useState, useRef } from 'react'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import { AesTranslationContext } from '../contexts/AESTranslationContext'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import { useLocation } from 'react-router-dom'

const {
  button: { CngButton },
  form: { CngAddForm }
} = components

function AddPage({ history, showNotification }) {
  const { getTranslatedText } = useContext(AesTranslationContext)
  const loc = useLocation()

  const onSuccessCallback = useRef((e) =>
    history.push(`${pathMap.AES_PRODUCT_MASTER_LIST_VIEW}`)
  )

  function handleSave() {
    onSuccessCallback.current = (e) => {
      history.push(`${pathMap.AES_PRODUCT_MASTER}/edit/${e.id}`)
    }
  }

  let formikPropsData;
  if (loc.state !== undefined) {
    formikPropsData = {
      ...FormProperties.formikProps,
      initialValues: FormProperties.toClientDataFormat(loc.state)
    }
  } else {
    formikPropsData = FormProperties.formikProps
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={formikPropsData}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: AesProductMasterApiUrls.POST,
            onSuccess: (e) => {
              showNotification('success', getTranslatedText('AES','addSuccessMsg'))
              onSuccessCallback.current(e)
            }
          }}
          renderButtonSection={(loading) => (
            <ButtonSection
              history={history}
              loading={loading}
              onSave={handleSave}
              getTranslatedText={getTranslatedText}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage

function ButtonSection(props) {
  const { history, loading, onSave, getTranslatedText } = props
  const [confirmDialog, setConfirmDialog] = useState(false)

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton color='secondary' disabled={loading} onClick={() => setConfirmDialog(true)} size='medium'>{getTranslatedText('AES', 'discardButton')}</CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton color='primary' disabled={loading} type='submit' onClick={onSave} size='medium'>{getTranslatedText('AES', 'saveButton')}</CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AlertDialog
        cancelLabel={getTranslatedText('AES', 'continueEditing')}
        confirmLabel={getTranslatedText('AES', 'yesDiscard')}
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={() => history.push(pathMap.AES_PRODUCT_MASTER_LIST_VIEW)}
        title={getTranslatedText('AES', 'discardButton')}
      >
        {getTranslatedText('AES', 'discardMessage')}
      </AlertDialog>
    </>
  )
}