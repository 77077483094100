import React, { useContext, useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import {
    components
} from 'cng-web-lib'
import { AesTranslationContext } from '../../contexts/AESTranslationContext'
import Switch from 'src/components/Switch'
import { useFormContext } from 'react-hook-form'
import CngSection from 'src/components/cngcomponents/CngSection'
import CommodityDetailsTable from '../CommodityDetailsTable'
import CommodityDetailsDialog from '../CommodityDetailsDialog'

const {
    form: {
        field: {
            CngSelectField
        },
    },
    CngGridItem,
} = components

function CommodityDetailsSection({ disabled, showNotification, shouldHideMap, getLookupValue, getCountryStateLabel }) {
    const { getTranslatedText } = useContext(AesTranslationContext)

    const { setValue, watch, getValues } = useFormContext();

    const modeOfTransport = watch('modeOfTransport')

    const commodityDetails = watch('commodityDetails')

    const [commodityDetailsDialog, setCommodityDetailsDialog] = useState({
        open: false,
        commodityDetails: null,
        isView: false
    })

    function calculateTotalWeight(commodityDetails){
        let totalWeight = 0;
        commodityDetails && commodityDetails.forEach(element => {
            if(element.shippingWeight!=null && element.shippingWeight!=undefined && element.shippingWeight!=""){
                totalWeight += parseInt(element.shippingWeight)
            }
        });
        setValue("totalShippingWeight",totalWeight)
    }

    function handleDeleteCommodityDetails(data) {
        const commodityDetails = [...getValues('commodityDetails')].filter((commodities) =>
            commodities._id ? commodities._id !== data._id : commodities.id !== data.id
        )
        setValue('commodityDetails', commodityDetails)
        calculateTotalWeight(commodityDetails)
    }

   

    return (
        <Grid item xs={12}>
            <CngSection
                title={getTranslatedText('AES', 'commodityDetails')}
                subheader={
                    <Typography color='textSecondary' variant='caption'>
                        <Typography variant='inherit' color='error'>*</Typography>
                        {getTranslatedText('AES', 'mandatoryField')}
                    </Typography>
                }
                id="commodity-details"
            >
                <CommodityDetailsTable
                    commodityDetails={commodityDetails}
                    onAddCommodityDetails={() =>
                        setCommodityDetailsDialog({
                            open: true,
                            commodityDetails: null,
                            isView: false,
                            
                        })
                    }
                    onDeleteCommodityDetails={handleDeleteCommodityDetails}
                    onEditCommodityDetails={(commodityDetails) =>
                        setCommodityDetailsDialog({
                            open: true,
                            commodityDetails,
                            isView: false,
                            
                        })
                    }
                    modeOfTransport={modeOfTransport}
                />
                <CommodityDetailsDialog
                    commodityDetails={commodityDetailsDialog.commodityDetails}
                    isView={commodityDetailsDialog.isView}
                    open={commodityDetailsDialog.open}
                    onClose={(commodityDetails)=> {
                        setCommodityDetailsDialog({
                            open: false,
                            commodityDetails: null,
                            isView: false,
                        })
                    }}
                    showNotification={showNotification}
                    getLookupValue={getLookupValue}
                    getCountryStateLabel={getCountryStateLabel}
                    modeOfTransport={modeOfTransport}
                    calculateTotalWeight={calculateTotalWeight}
                />
            </CngSection>
        </Grid>
    )
}

export default CommodityDetailsSection
