import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Card } from '@material-ui/core'
import withFormState from 'src/components/aciacehighway/hocs/withFormState'
import Stepper from 'src/components/aciacehighway/Stepper'
import A6EditPage from './A6EditPage'
import A6Response from './A6Response.js'
import AuditLog from './AuditLog.js'


function EditPage({ history, location, onSetLoading, showNotification }) {
  const { id } = useParams()
  const { state } = location

  const [step, setStep] = useState((state?.step ? state.step : 1) - 1)
  const [ccnValue, setCCNValues] = useState('')

  function setCCNValue(ccnValue) {
    setCCNValues(ccnValue)
  }

  function renderContent(step) {
    switch (step) {
      case 0:
        return (<A6EditPage history={history} onSetLoading={onSetLoading}
          showNotification={showNotification} id={id} setCCNValue={setCCNValue} />)
      case 1:
        return (<A6Response showNotification={showNotification} a6Id={id} ccnValue={ccnValue} />)
      case 2:
        return (<AuditLog showNotification={showNotification} a6Id={id} />)
    }
  }

  return (
    <Card>
      <Stepper steps={['Create A6A', 'Response Message', 'Activity Log']} activeStep={step} onSetActiveStep={(step) => setStep(step)} />
      <Box padding={2}>
        {renderContent(step)}
      </Box>
    </Card>
  );
}

export default withFormState(EditPage)
