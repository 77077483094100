import React, { useState, useContext } from 'react'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngField from '../../../components/cngcomponents/CngField'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import StatusBar from '../../../components/aciacehighway/StatusBar'
import { Box, Card, Grid, Typography, useTheme } from '@material-ui/core'
import { AesTranslationContext } from '../contexts/AESTranslationContext'
import { components } from 'cng-web-lib'
import PartyDetailsTable from './PartyDetailsTable'
import PartyDetailsDialog from './PartyDetailsDialog'
import CommodityDetailsDialog from './CommodityDetailsDialog'
import CommodityDetailsTable from './CommodityDetailsTable'
import ShipmentResponse from './ShipmentResponse'
import ActivityLog from './ActivityLog'

const {
    CngGridItem,
} = components

function ShipmentViewContent(props) {
    const { data, getLookupValue, getCountryStateLabel, statusNotificationData } = props
    const { getTranslatedText } = useContext(AesTranslationContext)
    const theme = useTheme()

    const [partyDetailsDialog, setPartyDetailsDialog] = useState({
        open: false,
        partyDetails: null,
        isView: false
    })

    const [commodityDetailsDialog, setCommodityDetailsDialog] = useState({
        open: false,
        commodityDetails: null,
        isView: false
    })
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <StatusBar status={data.status} />
                </Grid>
                <Grid item xs={12}>
                    <CngSection
                        id="shipment-header-top"
                    >
                        <Grid container spacing={1}>
                            <CngGridItem xs={12} sm={6} lg={4}>
                                <CngField label={getTranslatedText('AES', 'relatedCompanyInd')}>
                                    {data.relatedCompanyInd ? getTranslatedText('AES', 'yes') : getTranslatedText('AES', 'no')}
                                </CngField>
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} lg={4}>
                                <CngField label={getTranslatedText('AES', 'usToPuertoRicoInd')}>
                                    {data.usToPuertoRicoInd != null && data.usToPuertoRicoInd != undefined && data.usToPuertoRicoInd != "" ? data.usToPuertoRicoInd ? getTranslatedText('AES', 'yes') : getTranslatedText('AES', 'no') : ""}
                                </CngField>
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} lg={4}>
                                <CngField label={getTranslatedText('AES', 'puertoRicoToUsInd')}>
                                    {data.puertoRicoToUsInd != null && data.puertoRicoToUsInd != undefined && data.puertoRicoToUsInd != "" ? data.puertoRicoToUsInd ? getTranslatedText('AES', 'yes') : getTranslatedText('AES', 'no') : ""}
                                </CngField>
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} lg={4}>
                                <CngField label={getTranslatedText('AES', 'foreignPrincipalPartyInd')}>
                                    {data.foreignPrincipalPartyInd ? getTranslatedText('AES', 'yes') : getTranslatedText('AES', 'no')}
                                </CngField>
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} lg={4} >
                                <CngField label={getTranslatedText('AES', 'hazmatInd')}>
                                    {data.hazmatInd ? getTranslatedText('AES', 'yes') : getTranslatedText('AES', 'no')}
                                </CngField>
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} lg={4}>
                                <CngField label={getTranslatedText('AES', 'toBeSoldEnRouteInd')}>
                                    {data.toBeSoldEnRouteInd != null && data.toBeSoldEnRouteInd != undefined && data.toBeSoldEnRouteInd != "" ? data.toBeSoldEnRouteInd ? getTranslatedText('AES', 'yes') : getTranslatedText('AES', 'no') : ""}
                                </CngField>
                            </CngGridItem>
                        </Grid>
                    </CngSection>
                </Grid>
                <Grid item xs={12}>
                    <CngSection
                        title={getTranslatedText('AES', 'headerTitle')}
                        id="shipment-header"
                    >
                        <Grid container spacing={1}>
                            <CngGridItem xs={12} sm={6} md={4}>
                                <CngField label={getTranslatedText('AES', 'modeOfTransport')}>
                                    <Typography component='div' variant='inherit'>
                                        {getLookupValue('aesModeOfTransport', data.modeOfTransport)}
                                        <TinyChip label={data.modeOfTransport} variant='outlined' />
                                    </Typography>
                                </CngField>
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} md={4}>
                                <CngField label={getTranslatedText('AES', 'carrierCode')}>
                                    {data.carrierCode}
                                </CngField>
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} md={4}>
                                <CngField label={getTranslatedText('AES', 'aesInternalTransactionNumber')}>
                                    {data.aesInternalTransactionNumber}
                                </CngField>
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} md={4}>
                                <CngField label={getTranslatedText('AES', 'filingTypeInd')}>
                                    <Typography component='div' variant='inherit'>
                                        {getLookupValue('aeeFilingTypeInd', data.filingTypeInd)}
                                        <TinyChip label={data.filingTypeInd} variant='outlined' />
                                    </Typography>
                                </CngField>
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} md={4}>
                                <CngField label={getTranslatedText('AES', 'aeiFilingType')}>
                                    <Typography component='div' variant='inherit'>
                                        {getLookupValue('aeiFilingType', data.aeiFilingType)}
                                        <TinyChip label={data.aeiFilingType} variant='outlined' />
                                    </Typography>
                                </CngField>
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} md={4}>
                                <CngField label={getTranslatedText('AES', 'foreignTradeZone')}>
                                    {data.foreignTradeZone}
                                </CngField>
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} md={4}>
                                <CngField label={getTranslatedText('AES', 'shipmentReferenceNumber')}>
                                    {data.shipmentReferenceNumber}
                                </CngField>
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} md={4}>
                                <CngField label={getTranslatedText('AES', 'inBondType')}>
                                    <Typography component='div' variant='inherit'>
                                        {getLookupValue('aesInBondType', data.inBondType)}
                                        <TinyChip label={data.inBondType} variant='outlined' />
                                    </Typography>
                                </CngField>
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} md={4}>
                                <CngField label={getTranslatedText('AES', 'estimatedExportDate')}>
                                    {data.estimatedExportDate}
                                </CngField>
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} md={4}>

                                <CngField label={getTranslatedText('AES', 'portOfExportation')}>
                                    <Typography component='div' variant='inherit'>
                                        {getLookupValue('aesPortOfExportation', data.portOfExportation)}
                                        <TinyChip label={data.portOfExportation} variant='outlined' />
                                    </Typography>
                                </CngField>
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} md={4}>
                                <CngField label={getTranslatedText('AES', 'portOfUnlading')}>
                                    <Typography component='div' variant='inherit'>
                                        {getLookupValue('aesPortOfUnlading', data.portOfUnlading)}
                                        <TinyChip label={data.portOfUnlading} variant='outlined' />
                                    </Typography>
                                </CngField>
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} md={4}>
                                <CngField label={getTranslatedText('AES', 'entryNumber')}>
                                    {data.entryNumber}
                                </CngField>
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} md={4}>
                                <CngField label={getTranslatedText('AES', 'usStateOfOrigin')}>
                                    <Typography component='div' variant='inherit'>
                                        {getCountryStateLabel("US", data.usStateOfOrigin)}
                                        <TinyChip label={data.usStateOfOrigin} variant='outlined' />
                                    </Typography>
                                </CngField>
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} md={4}>
                                <CngField label={getTranslatedText('AES', 'ultimateDestinationCountry')}>
                                    <Typography component='div' variant='inherit'>
                                        {getLookupValue('countries', data.ultimateDestinationCountry)}
                                        <TinyChip label={data.ultimateDestinationCountry} variant='outlined' />
                                    </Typography>
                                </CngField>
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} md={4}>
                                <CngField label={getTranslatedText('AES', 'originalItn')}>
                                    {data.originalItn}
                                </CngField>
                            </CngGridItem>
                        </Grid>
                    </CngSection>
                </Grid>
                <Grid item xs={12}>
                    <CngSection
                        title={getTranslatedText('AES', 'transportationDetails')}
                        id="transportation-details"
                    >
                        <Grid container spacing={1}>
                            <CngGridItem xs={12} sm={6} md={6}>
                                <CngField label={getTranslatedText('AES', 'totalShippingWeight')}>
                                    {data.totalShippingWeight}
                                </CngField>
                            </CngGridItem>

                            <CngGridItem xs={12} sm={6} md={6}>
                                <CngField label={getTranslatedText('AES', 'carrierVesselName')}>
                                    {data.carrierVesselName}
                                </CngField>
                            </CngGridItem>
                            <Grid item xs={12}>
                                <CngSection
                                    title={getTranslatedText('AES', 'transportationDetailsRelatedDataTitle')}
                                >
                                    {data.transportationDetails.length > 0 ? (
                                        <Grid container spacing={2}>

                                            {data.transportationDetails.map((transportation) => {
                                                return (
                                                    <Grid key={transportation.id || transportation._id} item xs={12}>
                                                        <Card variant='outlined' style={{ backgroundColor: theme.palette.grey[50] }}>
                                                            <Box padding={2}>
                                                                <Grid container spacing={2}>
                                                                    <CngGridItem xs={12} sm={6} lg={4} >
                                                                        <CngField label={getTranslatedText('AES', 'equipmentNumber')}>
                                                                            {transportation.equipmentNumber}
                                                                        </CngField>
                                                                    </CngGridItem>
                                                                    <CngGridItem xs={12} sm={6} lg={4}>
                                                                        <CngField label={getTranslatedText('AES', 'sealNumber')}>
                                                                            {transportation.sealNumber}
                                                                        </CngField>
                                                                    </CngGridItem>
                                                                    <CngGridItem xs={12} sm={6} lg={4}>
                                                                        <CngField label={getTranslatedText('AES', 'transportationReferenceNumber')}>
                                                                            {transportation.sealNumber}
                                                                        </CngField>
                                                                    </CngGridItem>
                                                                </Grid>
                                                            </Box>
                                                        </Card>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    ) : (
                                        <Typography variant='body2' color='textSecondary'>
                                            {getTranslatedText('AES', 'noRecordToDisplay')}
                                        </Typography>
                                    )}

                                </CngSection>
                            </Grid>
                        </Grid>
                    </CngSection>
                </Grid>
                <Grid item xs={12}>
                    <CngSection
                        title={getTranslatedText('AES', 'partyDetails')}
                        id="party-details"
                    >
                        <PartyDetailsTable
                            partyDetails={data.partyDetails}
                            onViewPartyDetails={(partyDetails) =>
                                setPartyDetailsDialog({
                                    open: true,
                                    partyDetails,
                                    isView: true
                                })
                            }
                        />
                        <PartyDetailsDialog
                            partyDetails={partyDetailsDialog.partyDetails}
                            isView={partyDetailsDialog.isView}
                            open={partyDetailsDialog.open}
                            getCountryStateLabel={getCountryStateLabel}
                            getLookupValue={getLookupValue}
                            onClose={() =>
                                setPartyDetailsDialog({
                                    open: false,
                                    partyDetails: null,
                                    isView: false
                                })
                            }
                        />
                    </CngSection>
                </Grid>

                <Grid item xs={12}>
                    <CngSection
                        title={getTranslatedText('AES', 'commodityDetails')}
                        id="commodity-details"
                    >
                        <CommodityDetailsTable
                            commodityDetails={data.commodityDetails}
                            onViewCommodityDetails={(commodityDetails) =>
                                setCommodityDetailsDialog({
                                    open: true,
                                    commodityDetails,
                                    isView: true
                                })
                            }
                        />
                        <CommodityDetailsDialog
                            commodityDetails={commodityDetailsDialog.commodityDetails}
                            isView={commodityDetailsDialog.isView}
                            open={commodityDetailsDialog.open}
                            getCountryStateLabel={getCountryStateLabel}
                            getLookupValue={getLookupValue}
                            onClose={() =>
                                setCommodityDetailsDialog({
                                    open: false,
                                    commodityDetails: null,
                                    isView: false
                                })
                            }
                        />
                    </CngSection>
                </Grid>

                <Grid item xs={12}>
                    <CngSection
                        title={getTranslatedText('AES', 'snDetails')}
                    >
                        {statusNotificationData && statusNotificationData.length > 0 ? (
                            <Grid container spacing={2}>

                                {statusNotificationData.map((statusNotification) => {
                                    return (
                                        <Grid key={statusNotification.id || statusNotification._id} item xs={12}>
                                            <Card variant='outlined' style={{ backgroundColor: theme.palette.grey[50] }}>
                                                <Box padding={2}>
                                                    <Grid container spacing={2}>
                                                        <CngGridItem xs={12} sm={6} lg={4} >
                                                            <CngField label={getTranslatedText('AES', 'snName')}>
                                                                {statusNotification.name}
                                                            </CngField>
                                                        </CngGridItem>
                                                        <CngGridItem xs={12} sm={6} lg={4}>
                                                            <CngField label={getTranslatedText('AES', 'snType')}>
                                                                <Typography component='div' variant='inherit'>
                                                                    {getLookupValue('aesStatusNotificationType', statusNotification.statusNotificationType)}
                                                                    <TinyChip label={statusNotification.statusNotificationType} variant='outlined' />
                                                                </Typography>
                                                            </CngField>
                                                        </CngGridItem>
                                                        <CngGridItem xs={12} sm={6} lg={4}>
                                                            <CngField label={getTranslatedText('AES', 'snPhoneNumber')}>
                                                                {statusNotification.phoneNumber}
                                                            </CngField>
                                                        </CngGridItem>
                                                        <CngGridItem xs={12} sm={6} lg={4}>
                                                            <CngField label={getTranslatedText('AES', 'snEmail')}>
                                                                {statusNotification.email}
                                                            </CngField>
                                                        </CngGridItem>
                                                    </Grid>
                                                </Box>
                                            </Card>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        ) : (
                            <Typography variant='body2' color='textSecondary'>
                                {getTranslatedText('AES', 'noRecordToDisplay')}
                            </Typography>
                        )}

                    </CngSection>
                </Grid>
                <Grid item xs={12}>
                <CngSection title={getTranslatedText('AES', 'shipmentResponse')}>
                    <ShipmentResponse
                        id={data.id}
                        isViewOnly={true}
                    />
                </CngSection>
            </Grid>
            <Grid item xs={12}>
                <CngSection title={getTranslatedText('AES', 'activityLog')}>
                    <ActivityLog
                        id={data.id}
                        isViewOnly={true}
                    />
                </CngSection>
            </Grid>
            </Grid>
        </>
    )
}

export default ShipmentViewContent