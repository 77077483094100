import React, { useContext } from 'react'
import { useFormContext } from "react-hook-form";
import { components } from 'cng-web-lib'
import { Grid } from '@material-ui/core'
import makeValidationSchema from './TaciOcnA6RouteMakeValidationSchema'
import { A6TranslationContext } from './contexts/A6TranslationContext'
import _ from 'lodash'

const {
  form: {
    field: { CngTextField, CngPortAutocompleteField }
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  createdBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
  portOfCall: "",
  terminal: "",
  pierNo: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { setValue, watch } = useFormContext();
  const { getTranslatedText } = useContext(A6TranslationContext)

  const portOfCall = watch('portOfCall')

	return (
    <Grid container spacing={2}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfCall}>
        <CngPortAutocompleteField
          name="portOfCall"
          label={getTranslatedText('A6', 'portOfCall')}
          inputProps={{ maxLength: 5 }}
          disabled={disabled}
          lookupProps={{
            label: (record) => (`${record.code};${record.descriptionEn}`)
          }}
          required
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.terminal}>
        <CngTextField
          name="terminal"
          label={getTranslatedText('A6', 'terminal')}
          inputProps={{ maxLength: 30 }}
          onBlur={(e) => { setValue("terminal", e.target.value.toUpperCase()) }}
          disabled={disabled}
          required={portOfCall?.slice(0, 2) == "CA"}
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.pierNo}>
        <CngTextField
          name="pierNo"
          label={getTranslatedText('A6', 'pierNo')}
          inputProps={{ maxLength: 4 }}
          onBlur={(e) => { setValue("pierNo", e.target.value.toUpperCase()) }}
          disabled={disabled}
          size="small"
        />
      </CngGridItem>
    </Grid>
	)
}

const TaciOcnA6RouteFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default TaciOcnA6RouteFormProperties
