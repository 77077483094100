import DbAdminStatusConfigApiUrls from 'src/apiUrls/DbAdminStatusConfigApiUrls'
import FormProperties from './FormProperties'
import { Card, Grid, CardContent } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'

const {
  form: { CngViewForm }
} = components

function ViewPage({ showNotification }) {
  const { id } = useParams()

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngViewForm
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              showNotification={showNotification}
              renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
                <FormProperties.Fields
                  disabled={false}
                  showNotification={showNotification}
                  shouldHideMap={shouldHideMap}
                />
              )}
              formikProps={FormProperties.formikProps}
              toClientDataFormat={FormProperties.toClientDataFormat}
              toServerDataFormat={FormProperties.toServerDataFormat}
            /*fetch={{
              id: id,
              url: DbAdminStatusConfigApiUrls.GET
            }}*/
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ViewPage
