import React, { useCallback } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AesShipmentKeys from 'src/constants/locale/key/AesShipment'
import AesPartyScreeningKeys from 'src/constants/locale/key/AesPartyScreening'

const AesTranslationContext = React.createContext()

const AesTranslationConsumer = AesTranslationContext.Consumer

const AesTranslationProvider = ({ children }) => {
    const { translate } = useTranslation([
        Namespace.AES_SHIPMENT,
        Namespace.AES_PARTY_SCREENING
    ])

    const translation = {
        AES: {
            aesShipment: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.TITLE),
            id: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.ID),
            corpid: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CORPID),
            relatedCompanyInd: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.RELATED_COMPANY_IND),
            usToPuertoRicoInd: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.US_TO_PUERTO_RICO_IND),
            puertoRicoToUsInd: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PUERTO_RICO_TO_US_IND),
            foreignPrincipalPartyInd: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.FOREIGN_PRINCIPAL_PARTY_IND),
            hazmatInd: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.HAZMAT_IND),
            toBeSoldEnRouteInd: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.TO_BE_SOLD_EN_ROUTE_IND),
            filingTypeInd: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.FILING_TYPE_IND),
            aeiFilingType: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.AEI_FILING_TYPE),
            shipmentReferenceNumber: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.SHIPMENT_REFERENCE_NUMBER),
            estimatedExportDate: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.ESTIMATED_EXPORT_DATE),
            portOfExportation: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PORT_OF_EXPORTATION),
            portOfUnlading: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PORT_OF_UNLADING),
            aesInternalTransactionNumber: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.AES_INTERNAL_TRANSACTION_NUMBER),
            foreignTradeZone: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.FOREIGN_TRADE_ZONE),
            entryNumber: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.ENTRY_NUMBER),
            usStateOfOrigin: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.US_STATE_OF_ORIGIN),
            ultimateDestinationCountry: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.ULTIMATE_DESTINATION_COUNTRY),
            modeOfTransport: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.MODE_OF_TRANSPORT),
            totalShippingWeight: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.TOTAL_SHIPPING_WEIGHT),
            carrierCode: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CARRIER_CODE),
            carrierVesselName: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CARRIER_VESSEL_NAME),
            responseDatetime: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.RESPONSE_DATETIME),
            actionCode: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.ACTION_CODE),
            status: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.STATUS),
            inBondType: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.IN_BOND_TYPE),
            shipperName: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.SHIPPER_NAME),
            consigneeName: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CONSIGNEE_NAME),
            ftpUserId: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.FTP_USER_ID),
            originalItn: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.ORIGINAL_ITN),
            modeOfSubmission: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.MODE_OF_SUBMISSION),
            oriCreatedBy: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.ORI_CREATED_BY),
            oriUpdatedBy: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.ORI_UPDATED_BY),
            oriSubmittedBy: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.ORI_SUBMITTED_BY),
            createdBy: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CREATED_BY),
            createdDate: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CREATED_DATE),
            updatedBy: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.UPDATED_BY),
            updatedDate: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.UPDATED_DATE),
            submittedBy: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.SUBMITTED_BY),
            submittedDate: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.SUBMITTED_DATE),
            version: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.VERSION),
            partyId: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PARTY_ID),
            headerTitle: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.HEADER_TITLE),
            yes: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.YES),
            no: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.NO),
            select: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.SELECT),
            mandatoryField: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.MANDATORY_FIELD),
            okButton: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.OK_BUTTON),
            saveButton: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.SAVE_BUTTON),
            updateButton: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.UPDATE_BUTTON),
            editShipment: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.EDIT_SHIPMENT),
            licenseDetails: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.LICENSE_DETAILS),
            ddtcDetails: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.DDTC_DETAILS),
            addNewButton: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.ADD_NEW_BUTTON),
            cloneButton: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CLONE_BUTTON),
            deleteButton: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.DELETE_BUTTON),
            noRecordToDisplay: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.NO_RECORD_TO_DISPLAY),
            action: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.ACTION),
            add: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.ADD),
            change: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CHANGE),
            delete: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.DELETE),
            discardMessage: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.DISCARD_MESSAGE),
            discardButton: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.DISCARD_BUTTON),
            yesDiscard: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.YES_DISCARD),
            continueEditing: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CONTINUE_EDITING),
            saveSuccessMsg: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.SAVE_SUCCESS_MSG),
            updateSuccessMsg: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.UPDATE_SUCCESS_MSG),
            deleteSuccessMsg: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.DELETE_SUCCESS_MSG),
            deleteConfirmationMsg: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.DELETE_CONFIRMATION_MSG),
            deleteConfirmButton: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.DELETE_CONFIRM_BUTTON),
            cancelActionButton: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CANCEL_ACTION_BUTTON),
            clonePreviousOne: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CLONE_PREVIOUS_ONE),
            submitButton: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.SUBMIT_BUTTON),
            submitSuccessMessage: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.SUBMIT_SUCCESS_MESSAGE),
            submitErrorMessage: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.SUBMIT_ERROR_MESSAGE),
            editButton: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.EDIT_BUTTON),
            editSentRecord: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.EDIT_SENT_RECORD),
            deleteNotDraftRecord: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.DELETE_NOT_DRAFT_RECORD),
            createButton: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CREATE_BUTTON),
            printButton: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PRINT_BUTTON),
            uploadCSVButton: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.UPLOAD_CSV_BUTTON),
            fileEmptyMsg: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.FILE_EMPTY_MSG),
            uploadDialogTitle: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.UPLOAD_DIALOG_TITLE),
            uploadSuccessMsg: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.UPLOAD_SUCCESS_MSG),
            uploadErrorMsg: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.UPLOAD_ERROR_MSG),
            resetButton: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.RESET_BUTTON),
            searchButton: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.SEARCH_BUTTON),
            addSuccessMsg: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.ADD_SUCCESS_MSG),
            productMaster: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PRODUCT_MASTER),
            commodityLineHeader: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.COMMODITY_LINE_HEADER),

            transportationDetails: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.TransportationDetails.TITLE),
            transportationDetailsRelatedDataTitle: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.TransportationDetails.RELATED_DATA_TITLE),
            headerId: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.TransportationDetails.HEADER_ID),
            equipmentNumber: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.TransportationDetails.EQUIPMENT_NUMBER),
            sealNumber: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.TransportationDetails.SEAL_NUMBER),
            transportationReferenceNumber: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.TransportationDetails.TRANSPORTATION_REFERENCE_NUMBER),
            partyDetails: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.TITLE),
            partyType: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.PARTY_TYPE),
            partyIdentifierType: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.PARTY_IDENTIFIER_TYPE),
            partyIdentifier: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.PARTY_IDENTIFIER),
            name: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.NAME),
            contactFirstName: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.CONTACT_FIRST_NAME),
            contactLastName: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.CONTACT_LAST_NAME),
            addressLine1: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.ADDRESS_LINE1),
            addressLine2: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.ADDRESS_LINE2),
            contactPhoneNumber: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.CONTACT_PHONE_NUMBER),
            cityName: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.CITY_NAME),
            stateCode: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.STATE_CODE),
            postalCode: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.POSTAL_CODE),
            countryCode: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.COUNTRY_CODE),
            usppiIrsNumber: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.USPPI_IRS_NUMBER),
            usppiIrsNumberType: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.USPPI_IRS_NUMBER_TYPE),
            ultimateConsigneeType: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.ULTIMATE_CONSIGNEE_TYPE),
            email: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.EMAIL),
            savePartyFlag: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.SAVE_PARTY_FLAG),
            partyTemplate: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.PARTY_TEMPLATE),
            partyDropdown: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.PARTY_DROPDOWN),
            addPartyButton: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PartyDetails.ADD_PARTY_BUTTON),

            commodityDetails: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.TITLE),
            productId: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.PRODUCT_ID),
            commodityDescription: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.COMMODITY_DESCRIPTION),
            htsNumber: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.HTS_NUMBER),
            originOfGoods: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.ORIGIN_OF_GOODS),
            quantity1: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.QUANTITY1),
            uom1: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.UOM1),
            quantity2: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.QUANTITY2),
            uom2: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.UOM2),
            valueOfGoods: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.VALUE_OF_GOODS),
            exportInformationCode: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.EXPORT_INFORMATION_CODE),
            shippingWeight: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.SHIPPING_WEIGHT),
            licenseExemptionCode: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.LICENSE_EXEMPTION_CODE),
            eccn: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.ECCN),
            exportLicenseNumber: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.EXPORT_LICENSE_NUMBER),
            licenseValue: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.LICENSE_VALUE),
            itarExemptionCode: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.ITAR_EXEMPTION_CODE),
            registrationNumber: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.REGISTRATION_NUMBER),
            eligiblePartyCertificationInd: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.ELIGIBLE_PARTY_CERTIFICATION_IND),
            usmlCategoryCode: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.USML_CATEGORY_CODE),
            ddtcQuantity: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.DDTC_QUANTITY),
            ddtcUom: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.DDTC_UOM),
            significantMilitaryEquipmentInd: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.SIGNIFICANT_MILITARY_EQUIPMENT_IND),
            additionalPgaDataRequiredInd: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.ADDITIONAL_PGA_DATA_REQUIRED_IND),
            deliveryDetails: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.DELIVERY_DETAILS),
            saveProductFlag: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.SAVE_PRODUCT_FLAG),
            productTemplate: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.PRODUCT_TEMPLATE),
            productDropdown: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.PRODUCT_DROPDOWN),
            addCommodityButton: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.CommodityDetails.ADD_COMMODITY_BUTTON),    

            usedVehicleDetails: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.UsedVehicleDetails.TITLE),
            _commodityDetailsId: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.UsedVehicleDetails.COMMODITY_DETAILS),
            commodityId: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.UsedVehicleDetails.COMMODITY_ID),
            vehicleIdType: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.UsedVehicleDetails.VEHICLE_ID_TYPE),
            vehicleIdNumber: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.UsedVehicleDetails.VEHICLE_ID_NUMBER),
            vehicleTitleNumber: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.UsedVehicleDetails.VEHICLE_TITLE_NUMBER),
            vehicleTitleState: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.UsedVehicleDetails.VEHICLE_TITLE_STATE),

            pgaDetails: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PgaDetails.TITLE),
            pgaId: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PgaDetails.PGA_ID),
            amsCertificateNumber: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PgaDetails.AMS_CERTIFICATE_NUMBER),
            epaLicenseRequired: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PgaDetails.EPA_LICENSE_REQUIRED),
            epaConsentNumber: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PgaDetails.EPA_CONSENT_NUMBER),
            rcraHazardousWasteTrackingNumber: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PgaDetails.RCRA_HAZARDOUS_WASTE_TRACKING_NUMBER),
            epaNetQuantity: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PgaDetails.EPA_NET_QUANTITY),
            epaUom: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.PgaDetails.EPA_UOM),
            
            snDetails: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.StatusNotificationDetails.TITLE),
            snName: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.StatusNotificationDetails.SN_NAME),
            snEmail: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.StatusNotificationDetails.SN_EMAIL),
            snPhoneNumber: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.StatusNotificationDetails.SN_PHONE_NUMBER),
            snType: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.StatusNotificationDetails.SN_TYPE),
            addStatusNotification: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.StatusNotificationDetails.ADD_STATUS_NOTIFICATION),
            setSnDetails: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.StatusNotificationDetails.SET_SN_DETAILS),    

            viewResponse: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.Responses.VIEW_RESPONSE),
            responsedId: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.Responses.RESPONSE_ID),
            processingDate: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.Responses.PROCESSING_DATE),
            aesInternalTransactionNumber: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.Responses.AES_INTERNAL_TRANSACTION_NUMBER),
            responseCode: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.Responses.RESPONSE_CODE),
            responseText: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.Responses.RESPONSE_TEXT),
            responseDate: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.Responses.RESPONSE_DATE),
            reasonCode: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.Responses.REASON_CODE),
            finalDestinationInd: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.Responses.FINAL_DESTINATION_IND),
            severityIndicator: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.Responses.SEVERITY_INDICATOR),
            shipmentResponse:  translate(Namespace.AES_SHIPMENT, AesShipmentKeys.Responses.SHIPMENT_RESPONSE),
            action:  translate(Namespace.AES_SHIPMENT, AesShipmentKeys.Responses.ACTION),
            remarks:  translate(Namespace.AES_SHIPMENT, AesShipmentKeys.Responses.REMARKS),
            account:  translate(Namespace.AES_SHIPMENT, AesShipmentKeys.Responses.ACCOUNT),
            submissionMode:  translate(Namespace.AES_SHIPMENT, AesShipmentKeys.Responses.SUBMISSION_MODE),
            activityLog: translate(Namespace.AES_SHIPMENT, AesShipmentKeys.Responses.ACTIVITY_LOG),

            partyScreening: translate(Namespace.AES_PARTY_SCREENING, AesPartyScreeningKeys.TITLE),
            partyScreeningName: translate(Namespace.AES_PARTY_SCREENING, AesPartyScreeningKeys.PARTY_NAME),
            partyScreeningAddress: translate(Namespace.AES_PARTY_SCREENING, AesPartyScreeningKeys.ADDRESS),
            partyScreeningCountryCode: translate(Namespace.AES_PARTY_SCREENING, AesPartyScreeningKeys.COUNTRY_CODE),
            screeningButton: translate(Namespace.AES_PARTY_SCREENING, AesPartyScreeningKeys.SCREENING_BUTTON),
            source:  translate(Namespace.AES_PARTY_SCREENING, AesPartyScreeningKeys.SOURCE),
            startDate:  translate(Namespace.AES_PARTY_SCREENING, AesPartyScreeningKeys.START_DATE),
            endDate:  translate(Namespace.AES_PARTY_SCREENING, AesPartyScreeningKeys.END_DATE),
            partyScreeningDetails: translate(Namespace.AES_PARTY_SCREENING, AesPartyScreeningKeys.PARTY_SCREENING_DETAILS),
            addressDetails: translate(Namespace.AES_PARTY_SCREENING, AesPartyScreeningKeys.ADDRESS_DETAILS),
            sourceInformationUrl: translate(Namespace.AES_PARTY_SCREENING, AesPartyScreeningKeys.SOURCE_INFORMATION_URL),
            sourceListUrl: translate(Namespace.AES_PARTY_SCREENING, AesPartyScreeningKeys.SOURCE_LIST_URL),
            standardOrder: translate(Namespace.AES_PARTY_SCREENING, AesPartyScreeningKeys.STANDARD_ORDER),
            federalRegisterNotice: translate(Namespace.AES_PARTY_SCREENING, AesPartyScreeningKeys.FEDERAL_REGISTER_NOTICE),
            city: translate(Namespace.AES_PARTY_SCREENING, AesPartyScreeningKeys.CITY),
            state: translate(Namespace.AES_PARTY_SCREENING, AesPartyScreeningKeys.STATE),
            country: translate(Namespace.AES_PARTY_SCREENING, AesPartyScreeningKeys.COUNTRY),
            provideAtLeastOneSearchCriteria: translate(Namespace.AES_PARTY_SCREENING, AesPartyScreeningKeys.PROVIDE_AT_LEAST_ONE_SEARCH_CRITERIA),
            errorSearchPartyScreeningMsg: translate(Namespace.AES_PARTY_SCREENING, AesPartyScreeningKeys.ERROR_SEARCH_PARTY_SCREENING_MSG),
        }
    }

    const getTranslatedText = useCallback((namespace, key) => {
        if (!namespace || !key) return undefined

        return translation?.[namespace]?.[key] || undefined
    }, [])

    return (
        <AesTranslationContext.Provider value={{ translation, getTranslatedText }}>
            {children}
        </AesTranslationContext.Provider>
    )
}

export default AesTranslationProvider

export { AesTranslationContext, AesTranslationConsumer }
