import React, { useEffect, useState, useContext } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import AesShipmentStatusNotificationApiUrls from 'src/apiUrls/AesShipmentStatusNotificationApiUrls'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngField from '../../../components/cngcomponents/CngField'
import IconDescButton from '../../../components/button/IconDescButton'
import StatusNotificationDialog from './StatusNotificationDialog'
import ConfirmDialog from '../../common/ConfirmDialog'
import { Box, Divider, Grid } from '@material-ui/core'
import { AesTranslationContext } from '../contexts/AESTranslationContext'

const {
  button: { CngButton, CngIconButton },
  table: { useFetchCodeMaintenanceLookup },
} = components

const {
  filter: { EQUAL }
} = constants

function StatusNotificationListPage(props) {
  const {
    history,
    headerId,
    onSetLoading,
    showNotification,
  } = props

  const { getTranslatedText } = useContext(AesTranslationContext)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const [lookups, setLookups] = useState(null)
  const [data, setData] = useState([])
  const [statusNotificationDialog, setStatusNotificationDialog] = useState({
    open: false,
    statusNotification: null,
    type: 'ADD'
  })
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    statusNotification: null
  })
  const { createRecord, deleteRecord, fetchRecords, updateRecord } =
    useServices()

  useEffect(() => {
    
    fetchRecords.execute(
      AesShipmentStatusNotificationApiUrls.GET,
      {
        filters: [{ field: 'headerId', operator: EQUAL, value: headerId }]
      },
      (res) => {
        setData(res.content)
      }
    )

    Promise.all([
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'AES_STATUS_NOTIFICATION_TYPE' }],
        undefined,
        'code'
      )
    ]).then(([statusNotificationType]) => { setLookups({statusNotificationType}) })

  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value
    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function handleAddStatusNotification(datum) {

    onSetLoading(true)

    createRecord.execute(
      AesShipmentStatusNotificationApiUrls.POST,
      datum,
      (serverData) => {
        setData((prev) => [...prev, serverData])
        showNotification('success', getTranslatedText('AES', 'saveSuccessMsg'))
        setStatusNotificationDialog({
          open: false,
          statusNotification: null,
          type: 'ADD'
        })
      },
      (error) => {
        const { errorMessages } = error.response.data

        errorMessages.forEach((message) => {
          showNotification('error', message)
        })
      },
      () => onSetLoading(false)
    )
  }

  function handleEditStatusNotification(datum) {
    onSetLoading(true)

    updateRecord.execute(
      AesShipmentStatusNotificationApiUrls.PUT,
      datum,
      (serverData) => {
        const clonedData = [...data]
        const index = data.findIndex((sn) => sn.id === datum.id)

        clonedData[index] = serverData

        setData(clonedData)
        showNotification('success', getTranslatedText('AES', 'updateSuccessMsg'))
        setStatusNotificationDialog({
          open: false,
          statusNotification: null,
          type: 'ADD'
        })
      },
      (error) => {
        const { errorMessages } = error.response.data

        errorMessages.forEach((message) => {
          showNotification('error', message)
        })
      },
      () => onSetLoading(false)
    )
  }

  function handleDeleteStatusNotification() {
    const { statusNotification } = confirmDialog

    if (statusNotification) {
      onSetLoading(true)

      deleteRecord.execute(
        AesShipmentStatusNotificationApiUrls.DELETE,
        statusNotification,
        () => {
          setData((prev) =>
            prev.filter((sn) => sn.id !== statusNotification.id)
          )
          setConfirmDialog({ open: false, statusNotification: null })
          showNotification('success', getTranslatedText('AES', 'deleteSuccessMsg'))
        },
        (error) => {
          const { errorMessages } = error.response.data

          errorMessages.forEach((message) => {
            showNotification('error', message)
          })
        },
        () => onSetLoading(false)
      )
    }
  }

  return (
    <>
      <CngSection title={getTranslatedText('AES', 'snDetails')} subheader='Optional'>
        <Grid container spacing={2}>
          {data.map((datum, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={getTranslatedText('AES', 'snName')}>
                          {datum.name}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={getTranslatedText('AES', 'snType')}>
                          {getLookupValue("statusNotificationType",datum.statusNotificationType)}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={getTranslatedText('AES', 'snPhoneNumber')}>
                          {datum.phoneNumber}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={getTranslatedText('AES', 'snEmail')}>
                          {datum.email}
                        </CngField>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'pen']}
                              onClick={() =>
                                setStatusNotificationDialog({
                                  open: true,
                                  statusNotification: datum,
                                  type: 'EDIT'
                                })
                              }
                              size='small'
                            />
                          </Grid>
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'copy']}
                              onClick={() =>
                                setStatusNotificationDialog({
                                  open: true,
                                  statusNotification: datum,
                                  type: 'ADD'
                                })
                              }
                              size='small'
                            />
                          </Grid>
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'trash']}
                              onClick={() =>
                                setConfirmDialog({
                                  open: true,
                                  statusNotification: datum
                                })
                              }
                              size='small'
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {data.length !== index + 1 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
        <Box marginTop={2}>
          <IconDescButton
            description={getTranslatedText('AES', 'clonePreviousOne')}
            label={getTranslatedText('AES', 'addStatusNotification')}
            iconButtonProps={{
              icon: ['fal', 'plus'],
              onClick: () =>
                setStatusNotificationDialog({
                  open: true,
                  statusNotification: null,
                  type: 'ADD'
                })
            }}
          />
        </Box>
      </CngSection>
      <StatusNotificationDialog
        open={statusNotificationDialog.open}
        onAddStatusNotification={handleAddStatusNotification}
        onClose={() =>
          setStatusNotificationDialog({
            open: false,
            statusNotification: null,
            type: 'ADD'
          })
        }
        onEditStatusNotification={handleEditStatusNotification}
        headerId={headerId}
        showNotification={showNotification}
        statusNotification={statusNotificationDialog.statusNotification}
        type={statusNotificationDialog.type}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() =>
          setConfirmDialog({ open: false, statusNotification: null })
        }
        confirmDialog={handleDeleteStatusNotification}
        content={getTranslatedText('AES', 'deleteConfirmationMsg')}
        okMsg={getTranslatedText('AES', 'deleteConfirmButton')}
        cancelMsg={getTranslatedText('AES', 'cancelActionButton')}
        title={getTranslatedText('AES', 'deleteButton')}
      />
    </>
  )
}

export default StatusNotificationListPage
