import DbAdminStatusConfigApiUrls from 'src/apiUrls/DbAdminStatusConfigApiUrls'
import FormProperties from './FormProperties'
import { Grid, Card, CardContent } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'

const {
  form: { CngAddForm }
} = components

function AddPage({ history, showNotification }) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngAddForm
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              history={history}
              showNotification={showNotification}
              renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
                <FormProperties.Fields
                  disabled={false}
                  showNotification={showNotification}
                  shouldHideMap={shouldHideMap}
                />
              )}
              formikProps={FormProperties.formikProps}
              toClientDataFormat={FormProperties.toClientDataFormat}
              toServerDataFormat={FormProperties.toServerDataFormat}
              create={{
                url: DbAdminStatusConfigApiUrls.POST,
                successRedirect: pathMap.AES_PARTY_SCREENING_LIST_VIEW
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default AddPage
