import React, { useEffect, useState, useContext } from 'react'
import { components, constants } from 'cng-web-lib'
import {
    Box,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { A6TranslationContext } from './contexts/A6TranslationContext'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
    table: {
        '& .MuiTableHead-root': {
            backgroundColor: theme.palette.background.sectionOddBg,
            '& .MuiTableCell-head': {
                borderBottom: 0,
                fontSize: 12,
                fontWeight: 700,
                lineHeight: 1.2,
                textTransform: 'uppercase'
            }
        },
        '& .MuiTableBody-root': {
            '& .MuiTableRow-root': {
                '&:last-child': {
                    '& .MuiTableCell-root': {
                        borderBottom: 0
                    }
                }
            }
        },
        '& .MuiTableCell-root': {
            padding: '8px 16px'
        }
    }
}))

const {
    table: { useFetchCodeMaintenanceLookup },
    button: { CngButton, CngIconButton }
} = components

const { filter: { EQUAL } } = constants

function TaciOcnA6SNTable(props) {
    const { data, onAddSN, onEditSN, onDeleteSN } = props
    const { getTranslatedText } = useContext(A6TranslationContext)

    const classes = useStyles()
    const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

    const [lookups, setLookups] = useState(null)


    function getLookupValue(name, value) {
        if (!lookups) return value

        return lookups[name] && lookups[name][value] ? lookups[name][value] : value
    }

    useEffect(() => {
        Promise.all([
            // SN Type
            fetchCodeMaintenanceLookup(constants.CodeMaintenanceType.CODE_MASTER, undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'ACIOCN_STATUS_NOTIFI_TYPE' }],
                undefined, 'code'
            )
        ]).then(([snTyp]) => { setLookups({ snTyp }) })
    }, [])

    return (
        <>
            <Box mb={2}>
                <Grid container spacing={2} justify='flex-end'>
                    {onAddSN && (
                        <CngButton color='secondary' onClick={onAddSN} size='medium'
                            startIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}>
                            Add Status Notification
                        </CngButton>
                    )}
                </Grid>
            </Box>

            <TableContainer>
                <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{getTranslatedText('A6', 'name')}</TableCell>
                            <TableCell>{getTranslatedText('A6', 'email')}</TableCell>
                            <TableCell>{getTranslatedText('A6', 'phno')}</TableCell>
                            <TableCell>{getTranslatedText('A6', 'snTyp')}</TableCell>
                            {(onEditSN || onDeleteSN) && (<TableCell align='right'>Action</TableCell>)}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data.length > 0 ? (
                            data.map((sn, index) => (
                                <TableRow key={sn._id || sn.id || index}>
                                    <TableCell>{sn.name}</TableCell>
                                    <TableCell>{sn.email}</TableCell>
                                    <TableCell>{sn.phno}</TableCell>
                                    <TableCell>{getLookupValue('snTyp', sn.snTyp)}</TableCell>
                                    {(onEditSN || onDeleteSN) && (
                                        <TableCell>
                                            <Box display='flex' flexDirection='row' justifyContent='flex-end' style={{ gap: 8 }}>
                                                {onEditSN && (
                                                    <CngIconButton type='outlined' icon={['fal', 'pen']} onClick={() => onEditSN(sn)} size='small' />
                                                )}
                                                {onDeleteSN && (
                                                    <CngIconButton type='outlined' icon={['fal', 'trash']} onClick={() => onDeleteSN(sn)} size='small' />
                                                )}
                                            </Box>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell align='center' colSpan={4 + (onEditSN || onDeleteSN ? 1 : 0)}>
                                    <Typography variant='body2' color='textSecondary'>
                                        No records to display
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default TaciOcnA6SNTable
