import React, { useEffect, useState, useContext } from 'react'
import { Grid, Divider, Typography } from '@material-ui/core'
import {
  components
} from 'cng-web-lib'
import { useFormContext } from 'react-hook-form'
import { AesTranslationContext } from '../../contexts/AESTranslationContext'
import TransportationDetailsEntry from '../TransportationDetailsEntry'
import TransportationDetailsFormProperties from '../TransportationDetailsFormProperties'
import CollapsibleSection from 'src/components/ui/CollapsibleSection'
import CngSection from 'src/components/cngcomponents/CngSection'

const {
  form: {
    field: {
      CngTextField,
    },
  },
  CngGridItem,
} = components

function ShipmentHeaderSection({ disabled, showNotification, shouldHideMap }) {
  const { getTranslatedText } = useContext(AesTranslationContext)

  const { setValue, watch, getValues } = useFormContext();

  const transportationDetails = watch('transportationDetails')

  function handleAddTransportationDetails(data) {
    const transportationDetails = [...getValues('transportationDetails'), data].map(
      (material, index) => ({ ...material, _id: index + 1 })
    )

    setValue('transportationDetails', transportationDetails)
  }

  function handleDeleteTransportationDetails(data) {
    const clonedTransportationDetails = [...getValues('transportationDetails')].filter((material) =>
      material.id ? material.id !== data.id : material._id !== data._id
    )
    const transportationDetails = clonedTransportationDetails.map(
      (material, index) => ({ ...material, _id: index + 1 })
    )
    setValue('transportationDetails', transportationDetails)
  }

  return (
    <Grid item xs={12}>
      <CngSection
        title={getTranslatedText('AES', 'transportationDetails')}
        subheader={
          <Typography color='textSecondary' variant='caption'>
            <Typography variant='inherit' color='error'>*</Typography>
            {getTranslatedText('AES', 'mandatoryField')}
          </Typography>
        }
        id="transportation-details"
      >
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} md={6} shouldHide={shouldHideMap.totalShippingWeight}>
            <CngTextField
              name="totalShippingWeight"
              label={getTranslatedText('AES', 'totalShippingWeight')}
              disabled={true}
              size='small'
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={6} md={6} shouldHide={shouldHideMap.carrierVesselName}>
            <CngTextField
              name="carrierVesselName"
              label={getTranslatedText('AES', 'carrierVesselName')}
              disabled={disabled}
              size='small'
              onChange={(e) => {
                setValue('carrierVesselName', e.target.value.toUpperCase(), {
                  shouldValidate: true
                })
              }}
              onBlur={(e) => {
                if (e.target.value !== undefined) {
                  setValue('carrierVesselName', e.target.value.trim(), {
                    shouldValidate: true
                  })
                }
              }}
              required
            />
          </CngGridItem>
          <Grid item xs={12}>
            <CollapsibleSection
              defaultExpanded={transportationDetails.length > 0}
              onExpandedChange={(expanded) => {
                const { initialValues } = TransportationDetailsFormProperties.formikProps
                setValue('transportationDetails', expanded ? [{ ...initialValues, _id: 1 }] : [])
              }}
              title={getTranslatedText('AES', 'transportationDetailsRelatedDataTitle')}
            >
              <Grid container spacing={1}>
                {transportationDetails.map((goods, index) => {
                  const isLast = transportationDetails.length === 1
                  return (
                    <React.Fragment key={goods._id || goods.id || index}>
                      <Grid item xs={12}>
                        <TransportationDetailsEntry
                          index={index}
                          isLast={isLast}
                          onAddTransportationDetails={handleAddTransportationDetails}
                          onDeleteTransportationDetails={handleDeleteTransportationDetails}
                          transportationDetails={goods}
                          showNotification={showNotification}
                        />
                      </Grid>
                      {transportationDetails.length !== index + 1 && (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      )}
                    </React.Fragment>
                  )
                })}
              </Grid>
            </CollapsibleSection>
          </Grid>
        </Grid>
      </CngSection>
    </Grid>
  )
}

export default ShipmentHeaderSection
