import { Card, CardContent, Grid } from '@material-ui/core'
import React, { useContext, useState, useEffect, useRef } from 'react'
import pathMap from 'src/paths/pathMap'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import { useParams } from 'react-router-dom'
import { components } from 'cng-web-lib'
import { AesTranslationContext } from '../contexts/AESTranslationContext'
import ProductMasterEditForm from './ProductMasterEditPage'

const {
  CngTabs,
} = components

function EditPage({ history, showNotification, onSetLoading }) {

  const { id } = useParams()
  const { getTranslatedText } = useContext(AesTranslationContext)

  return (
    <Card>
      <CardContent>
        <ProductMasterEditForm
          history={history}
          showNotification={showNotification}
          onSetLoading={onSetLoading}
          id={id}
        />
      </CardContent>
    </Card>
  )
}

export default withFormState(EditPage)