import React, { useRef, useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { components, constants } from 'cng-web-lib'
import { A6TranslationContext } from './contexts/A6TranslationContext'
import Box from '@material-ui/core/Box'
import TaciOcnA6RouteFormProperties from './TaciOcnA6RouteFormProperties'

const {
    button: { CngPrimaryButton },
    form: { CngForm },
    CngDialog
} = components

const { FormState } = constants

function TaciOcnA6RouteDialog(props) {
    const { open, onClose, route, showNotification } = props
    const { getValues, setValue, formState: { isSubmitting } } = useFormContext()
    const { getTranslatedText } = useContext(A6TranslationContext)

    const lastIndex = useRef(0)

    const { initialValues, makeValidationSchema } = TaciOcnA6RouteFormProperties.formikProps

    function handleAddRoute(data) {
        const routes = [...getValues('taciOcnA6Route'), data].map(
            (route) => ({ ...route, _id: ++lastIndex.current })
        )

        setValue('taciOcnA6Route', routes)
        onClose()
    }

    function handleEditRoute(data) {
        const routes = [...getValues('taciOcnA6Route')]
        const index = routes.findIndex((route) =>
            route.id ? route.id === data.id : route._id === data._id
        )

        routes[index] = data

        setValue('taciOcnA6Route', routes)
        onClose()
    }

    return (
        <CngDialog
            dialogContent={
                <CngForm
                    fieldLevel='form'
                    formikProps={{
                        initialValues: route || initialValues,
                        makeValidationSchema: makeValidationSchema,
                        onSubmit: (data) => {
                            if (route) {
                                handleEditRoute(data)
                            } else {
                                handleAddRoute(data)
                            }
                        }
                    }}
                    formState={FormState.COMPLETED}
                    innerForm={true}
                    renderBodySection={(labelMap, shouldHideMap) => (
                        <TaciOcnA6RouteFormProperties.Fields showNotification={showNotification} shouldHideMap={shouldHideMap} />
                    )}
                    renderButtonSection={() => (
                        <Box display='flex' justifyContent='flex-end'>
                            <CngPrimaryButton type='submit' disabled={isSubmitting}>
                                {route ? "Update" : "Save"}
                            </CngPrimaryButton>
                        </Box>
                    )}
                />
            }
            dialogTitle={getTranslatedText('A6', 'taciOcnA6Route')}
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            fullWidth
            maxWidth='lg'
            onClose={onClose}
            open={open}
            shouldShowCloseButton
        />
    )
}

export default TaciOcnA6RouteDialog
