import React, { useEffect, useContext } from 'react'
import { Grid, Card, Box } from '@material-ui/core'
import { useFormContext, useWatch } from 'react-hook-form'
import { A6TranslationContext } from '../contexts/A6TranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import TaciOcnA6PartyFormProperties from '../TaciOcnA6PartyFormProperties'

function A6PartiesSection() {
    const { setValue } = useFormContext()
    const { getTranslatedText } = useContext(A6TranslationContext)

    const taciOcnA6Party = useWatch({ name: 'taciOcnA6Party' })

    //Initialize parties
    useEffect(() => {
        const { initialValues } = TaciOcnA6PartyFormProperties.formikProps

        if (taciOcnA6Party.length === 0) {
            setValue('taciOcnA6Party', [
                { ...initialValues, partyType: 'HR', _id: 1 },
                { ...initialValues, partyType: 'OV', _id: 2 },
                { ...initialValues, partyType: 'AG', _id: 3 }
            ])
        }
    }, [])

    return (
        <Grid item xs={12}>
            <CngSection title={getTranslatedText('A6', 'taciOcnA6Party')}>
                <Grid container spacing={2}>
                    {taciOcnA6Party.map((party, index) => (
                        <Grid key={index} item xs={12}>
                            <Card variant='outlined'>
                                <Box padding={2}>
                                    <Grid container justify='space-between' spacing={1}>
                                        <Grid item xs={12}>
                                            <TaciOcnA6PartyFormProperties.Fields index={index} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </CngSection>
        </Grid>
    )

}

export default A6PartiesSection