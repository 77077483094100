import { Card, CardContent, Grid } from '@material-ui/core'
import React, { useContext, useState, useEffect, useRef } from 'react'
import ShipmentEditForm from './ShipmentEditPage'
import pathMap from 'src/paths/pathMap'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import { useParams } from 'react-router-dom'
import { components } from 'cng-web-lib'
import { AesTranslationContext } from '../contexts/AESTranslationContext'
import StatusNotificationListPage from '../shipment/StatusNotificationListPage'
import ResponseMessage from './ResponseMessage'

const {
  CngTabs,
} = components

function EditPage({ history, showNotification, onSetLoading }) {

  const { id } = useParams()
  const { getTranslatedText } = useContext(AesTranslationContext)
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: getTranslatedText('AES', 'editShipment'),
              tabContent: (
                <ShipmentEditForm
                  history={history}
                  showNotification={showNotification}
                  onSetLoading={onSetLoading}
                  id={id}
                />
              )
            },
            {
              tabName: getTranslatedText('AES', 'setSnDetails'),
              tabContent: (
                <StatusNotificationListPage
                  history={history}
                  showNotification={showNotification}
                  headerId={id}
                  onSetLoading={onSetLoading}
                />
              )
            },
            {
              tabName: getTranslatedText('AES', 'viewResponse'),
              tabContent: (
                <ResponseMessage
                  showNotification={showNotification}
                  id={id}
                  isViewOnly={true}
                />
              )
            }
          ]}
        />
      </Grid>
    </Grid>

  )
}

export default withFormState(EditPage)