import React, { useEffect, useState, useContext, useMemo } from 'react'
import { Grid, Typography } from '@material-ui/core'
import {
  components,
  DateTimeFormatter,
  useServices
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { AesTranslationContext } from '../contexts/AESTranslationContext'
import ShipmentHeaderSection from './sections/ShipmentHeaderSection'
import ShipmentHeaderTopSection from './sections/ShipmentHeaderTopSection'
import TransportationDetailsSection from './sections/TransportationDetailsSection'
import PartyDetailsSection from './sections/PartyDetailsSection'
import CommodityDetailsSection from './sections/CommodityDetailsSection'
import ShipmentActionCodeSection from './sections/ShipmentActionCodeSection'
import { useFormContext } from 'react-hook-form'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { fetchUser } from "src/views/userprofile/UserProfileService.js"
import moment from 'moment-timezone'

const {
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngCodeMasterAutocompleteField,
      CngSelectField,
      CngCountryAutocompleteField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  corpid: "",
  relatedCompanyInd: "",
  usToPuertoRicoInd: "",
  puertoRicoToUsInd: "",
  foreignPrincipalPartyInd: "",
  hazmatInd: "",
  toBeSoldEnRouteInd: "",
  filingTypeInd: "",
  aeiFilingType: "",
  shipmentReferenceNumber: "",
  estimatedExportDate: "",
  portOfExportation: "",
  portOfUnlading: "",
  aesInternalTransactionNumber: "",
  foreignTradeZone: "",
  entryNumber: "",
  usStateOfOrigin: "",
  ultimateDestinationCountry: "",
  modeOfTransport: "",
  totalShippingWeight: "",
  carrierCode: "",
  carrierVesselName: "",
  responseDatetime: "",
  actionCode: "A",
  status: "",
  inBondType: "",
  shipperName: "",
  consigneeName: "",
  ftpUserId: "",
  originalItn: "",
  modeOfSubmission: "",
  oriCreatedBy: "",
  oriUpdatedBy: "",
  oriSubmittedBy: "",
  createdBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
  submittedBy: "",
  submittedDate: "",
  version: "",
  partyId: "",
  transportationDetails: [],
  partyDetails: [],
  commodityDetails: [],
  usedVehicleDetails: [],
  pgaDetails: [],
  modeOfSubmission: "UI"
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap, getLookupValue, getCountryStateLabel }) {
  const { getTranslatedText } = useContext(AesTranslationContext)
  const { setValue, watch, getValues } = useFormContext();

  const [user, setUser] = useState([]);
  const { securedSendRequest } = useServices()

  watch("fileForUserId");
  watch("fileForUserLoginId")
  watch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest, fileForUserDetails);

    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  useEffect(() => {
    var carrierCode = "";
    var module = "";
    if (user && user.userClientCodes) {

      Object.entries(user.userClientCodes).forEach((item) => {
        for (const key in item[1]) {
          if (key == "carrierCode") {
            carrierCode = item[1][key];
          }
          if (key == "module") {
            module = item[1][key];
          }
        }

        if (module == "AES" && carrierCode!=null && carrierCode!=undefined && carrierCode!="") {
          if(getValues("id")==null || getValues("id") == undefined || getValues("id") == ""){
            setValue("carrierCode",carrierCode)
          }
        }
      })
    }
  }, [user]);

  const memoedShipmentHeaderTopSection = useMemo(() => (
    <ShipmentHeaderTopSection disabled={disabled} shouldHideMap={shouldHideMap} showNotification={showNotification} />
  ), [])

  const memoedShipmentHeaderSection = useMemo(() => (
    <ShipmentHeaderSection disabled={disabled} shouldHideMap={shouldHideMap} showNotification={showNotification} />
  ), [])

  const memoedTransportationDetailsSection = useMemo(() => (
    <TransportationDetailsSection disabled={disabled} shouldHideMap={shouldHideMap} showNotification={showNotification} />
  ), [])

  const memoedPartyDetailsSection = useMemo(() => (
    <PartyDetailsSection disabled={disabled} shouldHideMap={shouldHideMap} showNotification={showNotification} getLookupValue={getLookupValue} getCountryStateLabel={getCountryStateLabel} />
  ), [])

  const memoedCommodityDetailsSection = useMemo(() => (
    <CommodityDetailsSection disabled={disabled} shouldHideMap={shouldHideMap} showNotification={showNotification} getLookupValue={getLookupValue} getCountryStateLabel={getCountryStateLabel} />
  ), [])

  const memoedActionCodeSection = useMemo(() => (
    <ShipmentActionCodeSection disabled={disabled} shouldHideMap={shouldHideMap} showNotification={showNotification} getLookupValue={getLookupValue} getCountryStateLabel={getCountryStateLabel} />
  ), [])

  return (
    <Grid container spacing={2}>
      {memoedShipmentHeaderTopSection}
      {memoedShipmentHeaderSection}
      {memoedTransportationDetailsSection}
      {memoedPartyDetailsSection}
      {memoedCommodityDetailsSection}
      {memoedActionCodeSection}
    </Grid>
  )
}

function toClientDataFormat(serverData) {
  let localData = serverData;

  /*if (localData.estimatedExportDate !== null){
    localData.estimatedExportDate = moment(localData.estimatedExportDate).format('YYYY-MM-DD');
  }*/

  localData.relatedCompanyInd = localData.relatedCompanyInd == "Y" ? true : false;
  localData.foreignPrincipalPartyInd = localData.foreignPrincipalPartyInd == "Y" ? true : false;
  localData.hazmatInd = localData.hazmatInd == "Y" ? true : false;
  localData.inBondType = String(localData.inBondType)

  let commodityDetails = localData.commodityDetails;

  if (commodityDetails && commodityDetails.length > 0) {
    for (let i = 0; i < commodityDetails.length; i++) {
      let pgaDetails = commodityDetails[i].pgaDetails;
      if (pgaDetails && pgaDetails.length > 0) {
        for (let i = 0; i < pgaDetails.length; i++) {
          pgaDetails[i].epaLicenseRequired = pgaDetails[i].epaLicenseRequired == "Y" ? true : false;
        }
      }
    }
  }

  localData.commodityDetails = commodityDetails;

  return localData;
}

function toServerDataFormat(localData) {

  if (localData.estimatedExportDate !== null){
    localData.estimatedExportDateString = moment(localData.estimatedExportDate).format('YYYY-MM-DD');
  }
  
  localData.responseDatetime = DateTimeFormatter.toServerDate(
    localData.responseDatetime
  );
  localData.createdDate = DateTimeFormatter.toServerDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toServerDate(
    localData.updatedDate
  );
  localData.submittedDate = DateTimeFormatter.toServerDate(
    localData.submittedDate
  );

  localData.relatedCompanyInd = localData.relatedCompanyInd == true ? "Y" : "N";
  localData.foreignPrincipalPartyInd = localData.foreignPrincipalPartyInd == true ? "Y" : "N";
  localData.hazmatInd = localData.hazmatInd == true ? "Y" : "N";

  let commodityDetails = localData.commodityDetails;

  if (commodityDetails && commodityDetails.length > 0) {
    for (let i = 0; i < commodityDetails.length; i++) {
      let pgaDetails = commodityDetails[i].pgaDetails;
      if (pgaDetails && pgaDetails.length > 0) {
        for (let i = 0; i < pgaDetails.length; i++) {
          pgaDetails[i].epaLicenseRequired = pgaDetails[i].epaLicenseRequired == true ? "Y" : "N";
        }
      }
    }
  }

  localData.commodityDetails = commodityDetails;

  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
