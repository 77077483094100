import { Box, Chip, Tooltip, Typography } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation,
  useServices
} from 'cng-web-lib'

import AesShipmentApiUrls from 'src/apiUrls/AesShipmentApiUrls'
import React,{useRef, useContext, useState} from 'react'
import AesShipmentKeys from 'src/constants/locale/key/AesShipment'
import Namespace from 'src/constants/locale/Namespace'
import Table from 'src/components/aciacehighway/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import pathMap from 'src/paths/pathMap'
import { FileForUserGetPartyId,FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { getStatusMetadataMini } from 'src/common/NACommon'
import moment from 'moment-timezone'
import { AesTranslationContext } from '../contexts/AESTranslationContext'
import ConfirmDialog from '../../common/ConfirmDialog'
import { format } from 'date-fns'
import ShipmentCSVUploadDialog from './upload/ShipmentCSVUploadDialog'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateRangeFilter: CngDateRangeFilter,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: { BETWEEN, EQUAL, IN, LIKE }
} = constants

function TablePage(props) {
  const {
    location,
    showNotification,
    history
  } = props

  const notification = useDefaultNotification(showNotification)
  const { getTranslatedText } = useContext(AesTranslationContext)
  const [confirmDialog, setConfirmDialog] = useState({ open: false, shipment: null })
  const { createRecord, deleteRecord, fetchRecords, securedSendRequest } = useServices()
  const [uploadCSVShipmentDialog, setUploadCSVShipmentDialog] = useState({ open: false, tableRef: null });

  const tableRef = useRef(null)

  const columns = [
    {
      field: "shipmentReferenceNumber",
      sortKey: 'shipmentReferenceNumber',
      title: getTranslatedText('AES', 'shipmentReferenceNumber'),
    },
    {
      field: "status",
      sortKey: 'status',
      title: getTranslatedText('AES', 'status'),
      render: (data) => {
        const status = getStatusMetadataMini(data.status)

        return (
          <>
            <Tooltip key='more' title='View response(s)' placement='top'>
              <Chip
                label={status.label}
                size='small'
                style={{
                  backgroundColor: status.color,
                  color: status.contrastColor
                }}
              />
            </Tooltip>
            <Box mt={0.5}>
              <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}              >
                {data.responseDatetime && moment(data.responseDatetime).tz('Canada/Eastern').format('D MMM YYYY, HH:mm')}
              </Typography>
            </Box>
          </>
        )
      }
    },
    {
      field: "shipperName",
      sortKey: 'shipperName',
      title: getTranslatedText('AES', 'shipperName'),
    },
    {
      field: "consigneeName",
      sortKey: 'consigneeName',
      title: getTranslatedText('AES', 'consigneeName'),
    },
    {
      field: "entryNumber",
      sortKey: 'entryNumber',
      title: getTranslatedText('AES', 'entryNumber'),
    },
    {
      field: "aesInternalTransactionNumber",
      sortKey: 'aesInternalTransactionNumber',
      title: getTranslatedText('AES', 'aesInternalTransactionNumber'),
    },
    {
      field: "createdDate",
      sortKey: 'createdDate',
      title: getTranslatedText('AES', 'createdDate'),
      render: (data) => data.createdDate && moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: "responseDatetime",
      sortKey: 'responseDatetime',
      title: getTranslatedText('AES', 'responseDatetime'),
      render: (data) => data.responseDatetime && moment(data.responseDatetime).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    }
  ]

  const filters = [
    {
      name: "shipmentReferenceNumber",
      type: 'textfield',
      label: getTranslatedText('AES', 'shipmentReferenceNumber'),
      operatorType: LIKE
    },
    {
      name: "status",
      type: 'checkbox',
      operatorType: IN,
      title: getTranslatedText('AES', 'status'),
      options: [
        {
          fullWidth: true,
          label: 'Draft',
          value: 'draft',
          filterValue: {
            value: '1005'
          }
        },
        {
          fullWidth: true,
          label: 'Accepted',
          value: 'accepted',
          filterValue: {
            value: '1000'
          }
        },
        {
          fullWidth: true,
          label: 'Sent',
          value: 'sent',
          filterValue: {
            value: '1008'
          }
        },
        {
          fullWidth: true,
          label: 'Rejected',
          value: 'rejected',
          filterValue: {
            value: '1001'
          }
        },
        {
          fullWidth: true,
          label: 'Status Notification',
          value: 'status_notification',
          filterValue: {
            value: '1006'
          }
        },
        {
          fullWidth: true,
          label: 'Cancellation Accepted',
          value: 'cancellation_accepted',
          filterValue: {
            value: '1003'
          }
        },
        {
          fullWidth: true,
          label: 'Cancellation Rejected',
          value: 'cancellation_rejected',
          filterValue: {
            value: '1004'
          }
        },
        {
          fullWidth: true,
          label: 'Replace Rejected',
          value: 'replace_rejected',
          filterValue: {
            value: '1007'
          }
        },
        {
          fullWidth: true,
          label: 'Modification Rejected',
          value: 'modification_rejected',
          filterValue: {
            value: '1009'
          }
        }
      ]
    },
    {
      name: "shipperName",
      type: 'textfield',
      title: getTranslatedText('AES', 'shipperName'),
      operatorType: LIKE
    },
    {
      name: "consigneeName",
      type: 'textfield',
      title: getTranslatedText('AES', 'consigneeName'),
      operatorType: LIKE
    },
    {
      name: "entryNumber",
      type: 'textfield',
      title: getTranslatedText('AES', 'entryNumber'),
      operatorType: LIKE
    },
    {
      name: "aesInternalTransactionNumber",
      type: 'textfield',
      title: getTranslatedText('AES', 'aesInternalTransactionNumber'),
      operatorType: LIKE
    },
    {
      name: "createdDate",
      title: getTranslatedText('AES', 'createdDate'),
      type: "daterange",
      operatorType: BETWEEN
    },
    {
      name: "responseDatetime",
      title: getTranslatedText('AES', 'responseDatetime'),
      type: "daterange",
      operatorType: BETWEEN
    }
  ]

  function handleDeleteShipment() {
    if (confirmDialog.shipment) {
      deleteRecord.execute(
        AesShipmentApiUrls.DELETE,
        confirmDialog.shipment,
        (res) => {
          showNotification('success', getTranslatedText('AES', 'deleteSuccessMsg'))
          setConfirmDialog({ open: false, shipment: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          console.log(error)
          setConfirmDialog({ open: false, shipment: null })

          if( error.response ){
            const errorMessages = error.response.data.errorMessages
            errorMessages.forEach((message) =>
              showNotification('error', message)
            )
          }
        }
      )
    }
  }

  function handleCloneShipment(data) {
    createRecord.execute(
      AesShipmentApiUrls.CLONE,
      data,
      (res) => {
        const transportationData = res.transportationDetails.map(
          (transportationDetails, index) => ({ ...transportationDetails, _id: index + 1 })
        )
        res.transportationDetails = transportationData;

        const partyData = res.partyDetails.map(
          (partyDetails, index) => ({ ...partyDetails, _id: index + 1 })
        )
        res.partyDetails = partyData;

        const commodityData = res.commodityDetails.map(
          (commodityDetails, index) => ({ ...commodityDetails, _id: index + 1 })
        )
        res.commodityDetails = commodityData;

        if(res.commodityDetails && res.commodityDetails.length>0){
          for(let commodity of res.commodityDetails){
            const usedVehicleData = commodity.usedVehicleDetails && commodity.usedVehicleDetails.map(
              (usedVehicleDetails, index) => ({ ...usedVehicleDetails, _id: index + 1 }))
              commodity.usedVehicleDetails =   usedVehicleData

            const pgaData = commodity.pgaDetails && commodity.pgaDetails.map(
              (pgaDetails, index) => ({ ...pgaDetails, _id: index + 1 }))
              commodity.pgaDetails =   pgaData
          }
        }

        res.commodityDetails = commodityData;

        const statusNotificationData = res.statusNotificationDetails && res.statusNotificationDetails.map(
          (statusNotificationDetails, index) => ({ ...statusNotificationDetails, _id: index + 1 })
        )
        res.statusNotificationDetails = statusNotificationData;

        history.push({
          pathname: pathMap.AES_SHIPMENT_ADD_VIEW,
          state: res
        })
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function handlePrintShipment(data) {
    const config = { responseType: 'blob' }

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }
  
    securedSendRequest.execute(
      'POST',
      AesShipmentApiUrls.PRINT_SHIPMENT,
      data,
      (response) => {
        let blob = new Blob([response.data])

        let first = 'aes_'+data.shipmentReferenceNumber+"_"
        let curDate = format(new Date(), 'yyyyMMddHHmmss')
        let filename = first + curDate + ".pdf"

        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      },
      (error) => console.error(error),
      undefined,
      config,
      null
    )
  }

  return (
    <>
    <Table
      actions={[
        /*
        //new feature not yet implement on legacy
        {
          buttonProps: {
            color: 'primary',
            size: 'medium',
            startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
            onClick: () => setUploadCSVShipmentDialog({ open: true, tableRef: tableRef })
          },
          label: getTranslatedText('AES', 'uploadCSVButton')
        },*/
        {
          buttonProps: {
            color: 'primary',
            size: 'medium',
            startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
            onClick: () => history.push(pathMap.AES_SHIPMENT_ADD_VIEW)
          },
          label: getTranslatedText('AES', 'createButton')
        },
      ]}
      columns={columns}
      compact
      exportData={{ url: AesShipmentApiUrls.EXPORT }}
      fetch={{ url: AesShipmentApiUrls.SEARCH }}
      fetchFilters={[
        { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
      ]}
      filters={filters}
      onRowClick={(rowData) => {
      if(rowData.status === '1008'){
          history.push(`${location.pathname}/view/${rowData.id}`)
      }else{
          history.push(`${location.pathname}/edit/${rowData.id}`)
        }
       }
      }
      rowActions={[
        {
          icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
          label: getTranslatedText('AES', 'editButton'),
          onClick: (rowData) =>
            history.push(pathMap.AES_SHIPMENT+`/edit/${rowData.id}`),
          tooltip: (rowData) => rowData.status === '1008' ? getTranslatedText('AES', 'editSentRecord') : getTranslatedText('AES', 'editButton'),
          disabled: (rowData) => rowData.status === '1008',
        },
        {
          icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
          label: getTranslatedText('AES', 'cloneButton'),
          onClick: handleCloneShipment
        },
        {
          icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
          label: getTranslatedText('AES', 'deleteButton'),
          onClick: (rowData) =>
          setConfirmDialog({ open: true, shipment: rowData }),
          tooltip: (rowData) => rowData.status !== '1005' ? getTranslatedText('AES', 'deleteNotDraftRecord') : getTranslatedText('AES', 'deleteButton'),
          disabled: (rowData) => rowData.status !== '1005',
        },
        {
          icon: <FontAwesomeIcon icon={['fal', 'print']} />,
          label: getTranslatedText('AES', 'printButton'),
          onClick: handlePrintShipment
        },
      ]}
      showNotification={showNotification}
      sortConfig={{
        type: 'column',
        defaultField: 'createdDate',
        defaultDirection: 'DESC'
      }}
      persistSettings
      tableRef={tableRef}
      tablePreference={{ module: 'AES', key: 'shipment_table_visible_column' }}
      selectActions={[
      ]}
      exportButtonProps={{
        options: [{ label: 'CSV', value: 'CSV' }]
      }}
    />
     <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, houseBill: null })}
        confirmDialog={handleDeleteShipment}
        content={getTranslatedText('AES', 'deleteConfirmationMsg')}
        okMsg={getTranslatedText('AES', 'deleteConfirmButton')}
        cancelMsg={getTranslatedText('AES', 'cancelActionButton')}
        title={getTranslatedText('AES', 'deleteButton')}
      />
      <ShipmentCSVUploadDialog
        open={uploadCSVShipmentDialog}
        onClose={() => setUploadCSVShipmentDialog({ open: false, tableRef: null })}
        showNotification={showNotification}
      />
    </>
  )
}

export default TablePage
