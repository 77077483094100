import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { components, constants, useServices } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import AesShipmentApiUrls from 'src/apiUrls/AesShipmentApiUrls'
import FormProperties from './FormProperties'
import {
    Box,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Divider,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AesTranslationContext } from '../contexts/AESTranslationContext'
import ShipmentViewContent from './ShipmentViewContent'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import AesShipmentStatusNotificationApiUrls from 'src/apiUrls/AesShipmentStatusNotificationApiUrls'


const {
    button: { CngButton },
    table: { useFetchCodeMaintenanceLookup }
} = components

const {
    filter: { EQUAL, IN }
} = constants

function ViewPage(props) {
    const { history, location, showNotification } = props
    const [data, setData] = useState(null)
    const [lookups, setLookups] = useState(null)
    const { id } = useParams()
    const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
    const { fetchRecord, fetchRecords } = useServices()
    const { getTranslatedText } = useContext(AesTranslationContext)
    const [statusNotificationData, setStatusNotificationData] = useState(null)

    useEffect(() => {
        Promise.all([
            // Data
            fetchRecord.execute(
                AesShipmentApiUrls.GET,
                id,
                (res) => res.content[0]
            ),
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'AES_MODE_OF_TRANSPORT' }],
                undefined,
                'code'
            ),
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'AES_FILING_TYPE_INDICATOR' }],
                undefined,
                'code'
            ),
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'AES_AEI_FILING_TYPE' }],
                undefined,
                'code'
            ),
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'AES_INBOND_TYPE' }],
                undefined,
                'code'
            ),
            fetchRecords.execute(
                NaCodeMaintenanceApiURLs.GET,
                {
                    filters: [
                        { field: 'set_indicator', operator: EQUAL, value: 'AES_POE' }
                    ],
                    customData: { codeMType: 'DocPortCode' }
                },
                (res) => {
                    const result = {}

                    res.content.forEach((item) => {
                        result[item.code] = item.descriptionEn
                    })

                    return result
                }
            ),
            fetchRecords.execute(
                NaCodeMaintenanceApiURLs.GET,
                {
                    filters: [
                        { field: 'set_indicator', operator: EQUAL, value: 'AES_POU' }
                    ],
                    customData: { codeMType: 'DocPortCode' }
                },
                (res) => {
                    const result = {}

                    res.content.forEach((item) => {
                        result[item.code] = item.descriptionEn
                    })

                    return result
                }
            ),
            // Countries
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.COUNTRY,
                undefined,
                [],
                undefined,
                'code'
            ),
            // Country states
            fetchRecords.execute(
                NaCodeMaintenanceApiURLs.GET,
                {
                    filters: [{ field: 'indicator', operator: EQUAL, value: 'AES' }],
                    customData: { codeMType: 'CountryStateCode' }
                },
                (res) => res.content
            ),
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'AES_PARTY_TYPE' }],
                undefined,
                'code'
            ),
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'AES_PARTY_IDENTIFIER' }],
                undefined,
                'code'
            ),
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'AES_USPPI_IDENTIFIER' }],
                undefined,
                'code'
            ),
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'AES_ULTIMATE_CONSIGNEE_TYPE' }],
                undefined,
                'code'
            ),
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'AES_UOM' }],
                undefined,
                'code'
            ),
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'AES_ORIGIN_OF_GOODS' }],
                undefined,
                'code'
            ),
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'AES_LICENSE_EXEMPTION' }],
                undefined,
                'code'
            ),
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'AES_EXPORT_INFORMATION' }],
                undefined,
                'code'
            ),
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'AES_USML_CATEGORY' }],
                undefined,
                'code'
            ),
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'AES_ITAR_EXEMPTION' }],
                undefined,
                'code'
            ),
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'AES_VEHICLE_ID_TYPE' }],
                undefined,
                'code'
            ),
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'AES_PGA' }],
                undefined,
                'code'
            ),
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'AES_STATUS_NOTIFICATION_TYPE' }],
                undefined,
                'code'
            ),
            fetchRecords.execute(
                AesShipmentStatusNotificationApiUrls.GET,
                {
                    filters: [{ field: 'headerId', operator: EQUAL, value: id }]
                },
                (res) => res.content
            ),
        ]).then(
            ([data, aesModeOfTransport, aeeFilingTypeInd, aeiFilingType, aesInBondType, aesPortOfExportation, aesPortOfUnlading, countries, 
                countryStates, aesPartyType, aesPartyIdentifier, aesUsppiIdentifier, aesUltimateConsigneeType, aesUOM, aesOriginOfGoods,
                aesLicenseExemption, aesExportInformation, aesUsmlCategory, aesItarExemption, aesVehicleIdType, aesPga, aesStatusNotificationType,
                snData
            ]) => {
                if (data === undefined) {
                    showNotification('error', 'Data not found.')
                    history.push(pathMap.AES_SHIPMENT_LIST_VIEW)
                }

                const states = Object.keys(countries).reduce((acc, countryCode) => {
                    acc[countryCode] = countryStates.filter(
                      (state) => state.code === countryCode
                    )
          
                    return acc
                  }, {})

                var clientData = FormProperties.toClientDataFormat(data)
                setData(clientData)

                setStatusNotificationData(snData)

                setLookups({
                    aesModeOfTransport,
                    aeeFilingTypeInd,
                    aeiFilingType,
                    aesInBondType,
                    aesPortOfExportation,
                    aesPortOfUnlading,
                    countryStates: states,
                    countries,
                    aesPartyType,
                    aesPartyIdentifier,
                    aesUsppiIdentifier,
                    aesUltimateConsigneeType,   
                    aesUOM,
                    aesOriginOfGoods,
                    aesLicenseExemption, 
                    aesExportInformation, 
                    aesUsmlCategory, 
                    aesItarExemption, 
                    aesVehicleIdType, 
                    aesPga, 
                    aesStatusNotificationType
                })
            });
    }, []);

    function getLookupValue(name, value) {
        if (!lookups) return value

        return lookups[name] && lookups[name][value] ? lookups[name][value] : value
    }

    function getCountryStateLabel(country, state) {
        if (!lookups || !country || !state) return state
    
        // Check if country code exists in the lookup
        if (Object.keys(lookups.countries).includes(country)) {
          const result = lookups.countryStates[country].find(
            (countryState) => countryState.intlcode === state
          )
    
          return result ? result.descriptionEn : state
        } else {
          return state
        }
      }
      
    if (data === null) {
        return <CircularProgress />
    }
    return (
        <>
            <Card>
                <CardContent>
                    <ShipmentViewContent
                        data={data}
                        getLookupValue={getLookupValue}
                        getCountryStateLabel={getCountryStateLabel}
                        statusNotificationData={statusNotificationData}
                    />
                </CardContent>
            </Card>
        </>
    )
}

export default ViewPage