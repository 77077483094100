import React, { useState, useContext, useEffect } from 'react'
import { components, useTranslation, constants } from 'cng-web-lib'
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { AesTranslationContext } from '../contexts/AESTranslationContext'
import moment from 'moment-timezone'

const {
  button: { CngButton, CngIconButton },
  table: { useFetchCodeMaintenanceLookup },
} = components

const {
  filter: { LIKE, EQUAL, IN }
} = constants

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      padding: '12px 16px',
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        cursor: 'pointer',
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

function PartyScreeningTable(props) {
  const {
    partyScreening,
    onViewPartyScreening,
  } = props

  const { getTranslatedText } = useContext(AesTranslationContext)
  const classes = useStyles()

  return (
    <>
      <Box marginTop={3}>
      </Box>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              <TableCell>{getTranslatedText('AES', 'name')}</TableCell>
              <TableCell>{getTranslatedText('AES', 'remarks')}</TableCell>
              <TableCell>{getTranslatedText('AES', 'source')}</TableCell>
              <TableCell>{getTranslatedText('AES', 'startDate')}</TableCell>
              <TableCell>{getTranslatedText('AES', 'endDate')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partyScreening && partyScreening.length > 0 ? (
              partyScreening.map((partyScreening) => (
                <TableRow
                  hover
                  onClick={() => {
                      onViewPartyScreening(partyScreening)
                  }}
                >
                  <TableCell>{partyScreening.name}</TableCell>
                  <TableCell>{partyScreening.remarks}</TableCell>
                  <TableCell>{partyScreening.source}</TableCell>
                  <TableCell>{partyScreening.startDate && moment(partyScreening.startDate).tz('Canada/Eastern').format('D MMM YYYY, HH:mm')}</TableCell>
                  <TableCell>{partyScreening.endDate && moment(partyScreening.endDate).tz('Canada/Eastern').format('D MMM YYYY, HH:mm')}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={
                    5
                  }
                >
                  <Typography variant='body2' color='textSecondary'>
                    {getTranslatedText('AES', 'noRecordToDisplay')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default PartyScreeningTable
