import React, { useState, useEffect, useContext } from 'react'
import { useFormContext } from 'react-hook-form';
import { components, DataFlattener, constants, useTranslation, useServices } from 'cng-web-lib'
import { Card, CardContent, Grid, Chip, Box, Typography } from '@material-ui/core'
import makeValidationSchema from './MakeValidationSchema'
import CngBackdrop from 'src/components/cngcomponents/CngBackDrop';
import PartyScreeningButtonComponent from './component/PartyScreeningButtonComponent'
import { v4 as uuid } from 'uuid'
import { AesTranslationContext } from '../contexts/AESTranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import AesPartyScreeningApiUrls from 'src/apiUrls/AesPartyScreeningApiUrls'
import PartyScreeningTable from './PartyScreeningTable'
import PartyScreeningDialog from './PartyScreeningDialog'

const {
  card: { CngSimpleCardHeader },
  form: {
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField
    }
  },
  table: {
    CngCrudTable,
    useFetchCodeMaintenanceLookup
  },
  CngGridItem,
} = components

const { filter: { EQUAL } } = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyName: '',
  address: '',
  countryCode: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {

  const { setValue, getValues, reset, watch, trigger } = useFormContext();
  const { securedSendRequest } = useServices()
  const { getTranslatedText } = useContext(AesTranslationContext)

  const [partyScreening, setPartyScreening] = useState([]);
  const [partyScreeningDialog, setPartyScreeningDialog] = useState(
    ({
      open: false,
      partyScreening: null,
      isView: false
    })
  )

  const [loading, setLoading] = useState(false);

  function handleSearch() {

    if(getValues("partyName") == "" && getValues("address") == "" && getValues("countryCode") == ""){
      showNotification("error",getTranslatedText('AES','provideAtLeastOneSearchCriteria'))
    }else{
      setLoading(true)
      securedSendRequest.execute('POST', AesPartyScreeningApiUrls.SEARCH_PARTY_SCREENING, getValues(),
        (response) => {
          if (response.status === 200) {
            if (response.data) {
              setPartyScreening(response.data)
            }
          }
        },
        (error) => {
          console.error(error)
          setPartyScreening([])
          showNotification("error",getTranslatedText('AES', 'errorSearchPartyScreeningMsg'))
        },
        () => setLoading(false)
      )
    }
  }

  function handleReset() {
    setValue("partyName", "")
    setValue("address", "")
    setValue("countryCode", "")
    setPartyScreening([])
  }


  return (
    <Grid container spacing={2}>
      <CngGridItem xs={12} sm={9}>
        <CngBackdrop loading={loading} />
      </CngGridItem>
      <Grid item xs={12}>
        <CngSection
          title={getTranslatedText('AES', 'partyScreening')}
          id="shipment-header-top"
        >
          <Grid container spacing={1}>
            <CngGridItem xs={12} sm={6} lg={4}>
              <CngTextField
                name='partyName'
                label={getTranslatedText('AES', 'partyScreeningName')+" **"}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} lg={4}>
              <CngTextField
                name='address'
                label={getTranslatedText('AES', 'partyScreeningAddress')+" **"}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} lg={4}>
              <CngTextField
                name='countryCode'
                label={getTranslatedText('AES', 'partyScreeningCountryCode')+" **"}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>

            <Grid xs={12}>
              <PartyScreeningButtonComponent
                onSearch={handleSearch}
                onReset={handleReset}
              />
            </Grid>
          </Grid>
          <Grid>
          </Grid>

          {partyScreening &&
            <Grid xs={12}>
              <PartyScreeningTable
                partyScreening={partyScreening}
                onViewPartyScreening={(partyScreening) =>
                  setPartyScreeningDialog({
                    open: true,
                    partyScreening,
                    isView: false
                  })
                }
              />
              <PartyScreeningDialog
                partyScreening={partyScreeningDialog.partyScreening}
                isView={partyScreeningDialog.isView}
                open={partyScreeningDialog.open}
                onClose={() =>
                  setPartyScreeningDialog({
                    open: false,
                    partyScreening: null,
                    isView: false
                  })
                }
                showNotification={showNotification}
              />
            </Grid>
          }
        </CngSection>
      </Grid>
    </Grid>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
