import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useFormContext, useWatch } from "react-hook-form"
import { Grid } from '@material-ui/core'
import { DataFlattener, DateTimeFormatter } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

import A6HeaderSection from './Sections/A6HeaderSection'
import A6VesselSection from './Sections/A6VesselSection'
import A6CertificatesSection from './Sections/A6CertificatesSection'
import A6ScheduleSection from './Sections/A6ScheduleSection'
import A6PartiesSection from './Sections/A6PartiesSection'
import A6RouteSection from './Sections/A6RouteSection'
import A6ContainerSection from './Sections/A6ContainerSection'
import A6SNSection from './Sections/A6SNSection'
import A6MessageFunctionSection from './Sections/A6MessageFunctionSection'
import moment from 'moment-timezone'

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: null,
  version: 0,
  createdDate: "",
  updatedDate: "",
  sno: "",
  doctype: "",
  carrierCode: "",
  vesselName: "",
  vesselCode: "",
  voyageNo: "",
  nationality: "",
  masterOperator: "",
  convRefno: "",
  crew: 0,
  passengers: 0,
  conveyanceType: "",
  containerShipment: false,
  customsProc: "",
  charterInfo: "",
  specialops: "",
  cargoDescription: "",
  regNo: "",
  regDate: "",
  placeOfRegistry: "",
  nettWt: "",
  netWeightUom: "TNE",
  grossWt: "",
  grossWeightUom: "TNE",
  cntrcargoWt: 0,
  containerizedCargoWeightUom: "TNE",
  cargoWt: 0,
  nonContainerizedCargoWeightUom: "TNE",
  deadWt: "",
  summerDeadWeightUom: "TNE",
  length: "",
  overallLengthUom: "",
  safetycertNo: "",
  safetycertExpiryDate: "",
  radiocertNo: "",
  radiocertExpiryDate: "",
  equipcertNo: "",
  equipcertExpiryDate: "",
  loadlinecertNo: "",
  loadlinecertExpiryDate: "",
  deratcertNo: "",
  deratcertExpiryDate: "",
  healthcertNo: "",
  healthcertExpiryDate: "",
  civilcertNo: "",
  civilcertExpiryDate: "",
  departurePort: "",
  departureTerminal: "",
  departurePier: "",
  etd: "",
  templateFlag: false,
  templateName: "",
  arrivalPort: "",
  arrivalTerminal: "",
  arrivalPier: "",
  eta: "",
  a6aStatus: "",
  customsOfficeOfExit: "",
  portOfDischarge: "",
  messageFunction: "9",
  submittedBy: "",
  submittedDate: "",
  taciOcnA6Party: [],
  taciOcnA6Route: [],
  taciOcnA6Container: [],
  taciOcnA6SN: [],
  oriCreatedBy: "",
  oriUpdatedBy: "",
  oriSubmittedBy: "",
  createdByLoginId:""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const location = useLocation();

  const { setValue } = useFormContext();

  const containerShipment = useWatch({ name: "containerShipment" })

  const editPage = location.pathname.toString().indexOf("edit") != -1 ? true : false
  const addPage = location.pathname.toString().indexOf("add") != -1 ? true : false

  useWatch({ name: "fileForUserId" })
  useWatch({ name: "fileForUserLoginId" })
  useWatch({ name: "fileForUserPartyId" })
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  const memoedA6HeaderSection = useMemo(() => (
    <A6HeaderSection disabled={disabled} shouldHideMap={shouldHideMap} />
  ), [])

  const memoedA6VesselSection = useMemo(() => (
    <A6VesselSection disabled={disabled} shouldHideMap={shouldHideMap} />
  ), [])

  const memoedA6CertificatesSection = useMemo(() => (
    <A6CertificatesSection disabled={disabled} shouldHideMap={shouldHideMap} />
  ), [])

  const memoedA6ScheduleSection = useMemo(() => (
    <A6ScheduleSection disabled={disabled} shouldHideMap={shouldHideMap} editPage={editPage} />
  ), [])

  const memoedA6PartiesSection = useMemo(() => (
    <A6PartiesSection />
  ), [])

  const memoedA6RouteSection = useMemo(() => (
    <A6RouteSection showNotification={showNotification} />
  ), [])

  const memoedA6ContainerSection = useMemo(() => (
    <A6ContainerSection showNotification={showNotification} />
  ), [])

  const memoedA6SNSection = useMemo(() => (
    <A6SNSection />
  ), [])

  const memoedA6MesageFunctionSection = useMemo(() => (
    <A6MessageFunctionSection disabled={disabled} shouldHideMap={shouldHideMap} addPage={addPage} />
  ), [])

  return (
    <Grid container spacing={3}>
      {memoedA6HeaderSection}
      {memoedA6VesselSection}
      {memoedA6CertificatesSection}
      {memoedA6ScheduleSection}
      {memoedA6PartiesSection}
      {memoedA6RouteSection}

      {containerShipment && memoedA6ContainerSection}

      {memoedA6SNSection}
      {memoedA6MesageFunctionSection}
    </Grid>
  )
}

function toClientDataFormat(serverData) {
  if (serverData.taciOcnA6Container) {
    const updatedContainers = serverData.taciOcnA6Container.map(cont =>
      cont.othContainerSize ? cont.containerSize !== 'OTHR' ? { ...cont, containerSizeOth: cont.containerSize, containerSize: 'OTHR' } : cont : cont
    )
    serverData.taciOcnA6Container = updatedContainers;
  }

  let localData = DataFlattener.parse(serverData);
  localData.regDate = DateTimeFormatter.toClientDate(localData.regDate);
  localData.safetycertExpiryDate = DateTimeFormatter.toClientDate(localData.safetycertExpiryDate);
  localData.radiocertExpiryDate = DateTimeFormatter.toClientDate(localData.radiocertExpiryDate);
  localData.equipcertExpiryDate = DateTimeFormatter.toClientDate(localData.equipcertExpiryDate);
  localData.loadlinecertExpiryDate = DateTimeFormatter.toClientDate(localData.loadlinecertExpiryDate);
  localData.deratcertExpiryDate = DateTimeFormatter.toClientDate(localData.deratcertExpiryDate);
  localData.healthcertExpiryDate = DateTimeFormatter.toClientDate(localData.healthcertExpiryDate);
  localData.civilcertExpiryDate = DateTimeFormatter.toClientDate(localData.civilcertExpiryDate);
  localData.submittedDate = DateTimeFormatter.toClientDate(localData.submittedDate);

  if (serverData.etd !== null) {
    localData.etd = moment(serverData.etd).tz("Canada/Eastern").format('YYYY-MM-DD HH:mm');
  }

  if (serverData.eta) {
    localData.eta = moment(serverData.eta).tz("Canada/Eastern").format('YYYY-MM-DD HH:mm');
  }

  return localData;
}

function toServerDataFormat(localData) {
  if (localData && localData.taciOcnA6Container) {
    const updatedContainers = localData.taciOcnA6Container.map(cont =>
      cont.containerSize === 'OTHR' ? { ...cont, containerSize: cont.containerSizeOth, othContainerSize: true } : { ...cont, othContainerSize: false }
    )
    localData.taciOcnA6Container = updatedContainers;
  }

  localData.regDate = DateTimeFormatter.toServerDate(localData.regDate);
  localData.safetycertExpiryDate = DateTimeFormatter.toServerDate(localData.safetycertExpiryDate);
  localData.radiocertExpiryDate = DateTimeFormatter.toServerDate(localData.radiocertExpiryDate);
  localData.equipcertExpiryDate = DateTimeFormatter.toServerDate(localData.equipcertExpiryDate);
  localData.loadlinecertExpiryDate = DateTimeFormatter.toServerDate(localData.loadlinecertExpiryDate);
  localData.deratcertExpiryDate = DateTimeFormatter.toServerDate(localData.deratcertExpiryDate);

  if (localData.healthcertExpiryDate) {
    localData.healthcertExpiryDate = DateTimeFormatter.toServerDate(localData.healthcertExpiryDate);
  }

  if (localData.civilcertExpiryDate) {
    localData.civilcertExpiryDate = DateTimeFormatter.toServerDate(localData.civilcertExpiryDate);
  }

  if (localData.submittedDate) {
    localData.submittedDate = DateTimeFormatter.toServerDate(localData.submittedDate);
  }

  localData.etdString = moment(localData.etd).format('YYYY-MM-DD HH:mm'); //pass string to backend convert to server timezone
  localData.etd = moment(localData.etd).format('YYYY-MM-DDTHH:mmZ');

  if (localData.eta) {
    localData.etaString = moment(localData.eta).format('YYYY-MM-DD HH:mm'); //pass string to backend convert to server timezone
    localData.eta = moment(localData.eta).format('YYYY-MM-DDTHH:mmZ');
  }

  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
