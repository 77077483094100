import React, { useRef, useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { components, constants } from 'cng-web-lib'
import { A6TranslationContext } from './contexts/A6TranslationContext'
import Box from '@material-ui/core/Box'
import TaciOcnA6SNFormProperties from './TaciOcnA6SNFormProperties'

const {
    button: { CngPrimaryButton },
    form: { CngForm },
    CngDialog
} = components

const { FormState } = constants

function TaciOcnA6SNDialog(props) {
    const { open, onClose, sn } = props
    const { getValues, setValue, formState: { isSubmitting } } = useFormContext()
    const { getTranslatedText } = useContext(A6TranslationContext)

    const lastIndex = useRef(0)

    const { initialValues, makeValidationSchema } = TaciOcnA6SNFormProperties.formikProps

    function handleAddSN(data) {
        const taciOcnA6SN = [...getValues('taciOcnA6SN'), data].map(
            (sn) => ({ ...sn, _id: ++lastIndex.current })
        )

        setValue('taciOcnA6SN', taciOcnA6SN)
        onClose()
    }

    function handleEditSN(data) {
        const taciOcnA6SN = [...getValues('taciOcnA6SN')]
        const index = taciOcnA6SN.findIndex((taciOcnA6SN) => taciOcnA6SN.id ? taciOcnA6SN.id === data.id : taciOcnA6SN._id === data._id)

        taciOcnA6SN[index] = data

        setValue('taciOcnA6SN', taciOcnA6SN)
        onClose()
    }

    return (
        <CngDialog
            dialogContent={
                <CngForm
                    fieldLevel='form'
                    formikProps={{
                        initialValues: sn || initialValues,
                        makeValidationSchema: makeValidationSchema,
                        onSubmit: (data) => {
                            if (sn) {
                                handleEditSN(data)
                            } else {
                                handleAddSN(data)
                            }
                        }
                    }}
                    formState={FormState.COMPLETED}
                    innerForm={true}
                    renderBodySection={(labelMap, shouldHideMap) => (
                        <TaciOcnA6SNFormProperties.Fields shouldHideMap={shouldHideMap} />
                    )}
                    renderButtonSection={() => (
                        <Box display='flex' justifyContent='flex-end'>
                            <CngPrimaryButton type='submit' disabled={isSubmitting}>
                                {sn ? "Update" : "Save"}
                            </CngPrimaryButton>
                        </Box>
                    )}
                />
            }
            dialogTitle={getTranslatedText('A6', 'taciOcnA6SN')}
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            fullWidth
            maxWidth='lg'
            onClose={onClose}
            open={open}
            shouldShowCloseButton
        />
    )
}

export default TaciOcnA6SNDialog
