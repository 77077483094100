import ShipmentResponse from './ShipmentResponse';
import ActivityLog from './ActivityLog';
import React, {useContext} from "react";
import { AesTranslationContext } from '../contexts/AESTranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import { Grid } from '@material-ui/core'

function ResponseMessage({
    showNotification,
    id,
    isViewOnly = false
}) {

    const { getTranslatedText } = useContext(AesTranslationContext)

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <CngSection title={getTranslatedText('AES', 'shipmentResponse')}>
                    <ShipmentResponse
                        showNotification={showNotification}
                        id={id}
                        isViewOnly={true}
                    />
                </CngSection>
            </Grid>
            <Grid item xs={12}>
                <CngSection title={getTranslatedText('AES', 'activityLog')}>
                    <ActivityLog
                        showNotification={showNotification}
                        id={id}
                        isViewOnly={true}
                    />
                </CngSection>
            </Grid>
        </Grid>
    )
}

export default ResponseMessage;