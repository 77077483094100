import React, { useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Grid, Typography, } from '@material-ui/core'
import { A6TranslationContext } from '../contexts/A6TranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import TaciOcnA6RouteDialog from '../TaciOcnA6RouteDialog'
import TaciOcnA6RouteTable from '../TaciOcnA6RouteTable'

function A6RouteSection(props) {
    const { showNotification } = props
    const { setValue, getValues } = useFormContext();
    const { getTranslatedText } = useContext(A6TranslationContext)

    const [dialog, setDialog] = useState({ open: false, route: null })

    const taciOcnA6Route = useWatch({ name: 'taciOcnA6Route' })

    function handleRemoveRoute(data) {
        const taciOcnA6Route = [...getValues('taciOcnA6Route')].filter((route) =>
            route.id ? route.id !== data.id : route._id !== data._id
        )

        setValue('taciOcnA6Route', taciOcnA6Route, { shouldValidate: true })
    }

    return (
        <Grid item xs={12}>
            <CngSection
                title={
                    <Typography variant='h5' style={{ fontWeight: 700 }}>
                        {getTranslatedText('A6', 'taciOcnA6Route') + ' '}
                        <Typography color='textSecondary' component='span' variant='caption'>
                            {`/ ${taciOcnA6Route.length} Entries`}
                        </Typography>
                    </Typography>
                }
                alignItems='flex-end'
            >

                <TaciOcnA6RouteTable
                    data={taciOcnA6Route}
                    onAddRoute={() => setDialog({ open: true, route: null })}
                    onEditRoute={(route) => setDialog({ open: true, route })}
                    onDeleteRoute={handleRemoveRoute}
                />

                <TaciOcnA6RouteDialog
                    onClose={() => setDialog({ open: false, route: null })}
                    open={dialog.open}
                    route={dialog.route}
                    showNotification={showNotification}
                />
            </CngSection>
        </Grid>
    )
}

export default A6RouteSection