import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import { constants } from 'cng-web-lib'

const {
  locale: {
    key: { CommonValidationMessageKeys },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  
  return Yup.object({
    portOfCall: Yup.string().required(requiredMessage).nullable(),
    terminal: Yup.string().when('portOfCall', {
      is: (value) => (value?.splice == 'CA'),
      then: Yup.string().required(requiredMessage)
    }).matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").nullable(),
    pierNo: Yup.string().matches(/^[0-9]*$/,"Must be numeric values in between 0 to 9999").nullable(),
  })
}

export default makeValidationSchema
