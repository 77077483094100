import React, { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { components } from 'cng-web-lib'
import { Grid, Typography } from '@material-ui/core'
import { A6TranslationContext } from '../contexts/A6TranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'

const {
    form: {
        field: {
            CngTextField,
            CngDateField,
            CngCodeMasterAutocompleteField
        }
    },
    CngGridItem,
} = components

function A6VesselSection(props) {
    const { disabled, shouldHideMap } = props
    const { setValue } = useFormContext();
    const { getTranslatedText } = useContext(A6TranslationContext)

    return (
        <Grid item xs={12}>
            <CngSection title={getTranslatedText('A6', 'vessel')}
                subheader={
                    <Typography color='textSecondary' variant='caption'>
                        <Typography variant='inherit' color='error'>*</Typography>
                        Mandatory fields
                    </Typography>
                }>
                <Grid container spacing={1}>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.regNo}>
                        <CngTextField
                            name='regNo'
                            label={getTranslatedText('A6', 'regNo')}
                            inputProps={{ maxLength: 25 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('regNo', e.target.value.toUpperCase()) }}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.regDate}>
                        <CngDateField
                            name='regDate'
                            label={getTranslatedText('A6', 'regDate')}
                            disabled={disabled}
                            format='YYYY-MM-DD'
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.placeOfRegistry}>
                        <CngTextField
                            name='placeOfRegistry'
                            label={getTranslatedText('A6', 'placeOfRegistry')}
                            inputProps={{ maxLength: 30 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('placeOfRegistry', e.target.value.toUpperCase()) }}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.nettWt}>
                        <CngTextField
                            name='nettWt'
                            label={getTranslatedText('A6', 'nettWt')}
                            inputProps={{ maxLength: 12 }}
                            disabled={disabled}
                            helperText={getTranslatedText('A6', 'tonUOM')}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.grossWt}>
                        <CngTextField
                            name='grossWt'
                            label={getTranslatedText('A6', 'grossWt')}
                            inputProps={{ maxLength: 12 }}
                            disabled={disabled}
                            helperText={getTranslatedText('A6', 'tonUOM')}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cntrcargoWt}>
                        <CngTextField
                            name='cntrcargoWt'
                            label={getTranslatedText('A6', 'cntrcargoWt')}
                            inputProps={{ maxLength: 12 }}
                            disabled={disabled}
                            helperText={getTranslatedText('A6', 'tonUOM')}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoWt}>
                        <CngTextField
                            name='cargoWt'
                            label={getTranslatedText('A6', 'cargoWt')}
                            inputProps={{ maxLength: 12 }}
                            disabled={disabled}
                            helperText={getTranslatedText('A6', 'tonUOM')}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.deadWt}>
                        <CngTextField
                            name='deadWt'
                            label={getTranslatedText('A6', 'deadWt')}
                            inputProps={{ maxLength: 12 }}
                            disabled={disabled}
                            helperText={getTranslatedText('A6', 'tonUOM')}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.length}>
                        <CngTextField
                            name='length'
                            label={getTranslatedText('A6', 'length')}
                            inputProps={{ maxLength: 12 }}
                            disabled={disabled}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.overallLengthUom}>
                        <CngCodeMasterAutocompleteField
                            name='overallLengthUom'
                            label={getTranslatedText('A6', 'overallLengthUom')}
                            disabled={disabled}
                            codeType='A6_OVERALL_LENGTH_UOM'
                            required
                            size='small'
                        />
                    </CngGridItem>
                </Grid>
            </CngSection>
        </Grid>
    )
}

export default A6VesselSection