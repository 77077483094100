import React, { useContext } from 'react'
import StatusBar from 'src/components/aciacehighway/StatusBar'
import CngSection from 'src/components/cngcomponents/CngSection'
import CngSubSection from 'src/components/cngcomponents/CngSubSection'
import CngField from 'src/components/cngcomponents/CngField'
import TinyChip from 'src/components/aciacehighway/TinyChip'
import TaciOcnA6ContainerTable from './TaciOcnA6ContainerTable'
import TaciOcnA6RouteTable from './TaciOcnA6RouteTable'
import TaciOcnA6SNTable from './TaciOcnA6SNTable'
import A6Response from './A6Response'
import AuditLog from './AuditLog'
import moment from 'moment-timezone'
import { Box, Card, Divider, Grid, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { A6TranslationContext } from './contexts/A6TranslationContext'

function A6ViewPage(props) {
  const { id, data, getCountryStateLabel, getLookupValue } = props
  const { getTranslatedText } = useContext(A6TranslationContext)

  const theme = useTheme()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StatusBar status={data.a6aStatus} />
      </Grid>

      <Grid item xs={12}>
        <CngSection title={getTranslatedText('A6', 'header')}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'carrierCode')}>
                {data.carrierCode}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'vesselName')}>
                {data.vesselName}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'vesselCode')}>
                {data.vesselCode}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'voyageNo')}>
                {data.voyageNo}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'nationality')}>
                {getLookupValue('countries', data.nationality)}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'masterOperator')}>
                {data.masterOperator}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'convRefno')}>
                {data.convRefno}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'crew')}>
                {data.crew}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'passengers')}>
                {data.passengers}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'conveyanceType')}>
                {getLookupValue('conveyanceType', data.conveyanceType)}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'customsProc')}>
                {getLookupValue('customsProc', data.customsProc)}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'charterInfo')}>
                {getLookupValue('charterInfo', data.charterInfo)}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'specialops')}>
                {data.specialops}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'cargoDescription')}>
                {data.cargoDescription}
              </CngField>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>

      <Grid item xs={12}>
        <CngSection title={getTranslatedText('A6', 'vessel')}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'regNo')}>
                {data.regNo}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'regDate')}>
                {data.regDate ? moment(data.regDate).format('YYYY-MM-DD') : data.regDate}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'placeOfRegistry')}>
                {data.placeOfRegistry}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'nettWt')}>
                {data.nettWt + ' Tons'}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'grossWt')}>
                {data.grossWt + ' Tons'}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'cntrcargoWt')}>
                {data.cntrcargoWt + ' Tons'}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'cargoWt')}>
                {data.cargoWt + ' Tons'}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'deadWt')}>
                {data.deadWt + ' Tons'}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'length')}>
                {data.length}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'overallLengthUom')}>
                {getLookupValue('lengthUom', data.overallLengthUom)}
              </CngField>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>

      <Grid item xs={12}>
        <CngSection title={getTranslatedText('A6', 'certificates')}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'safetycertNo')}>
                {data.safetycertNo}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'safetycertExpiryDate')}>
                {data.safetycertExpiryDate ? moment(data.safetycertExpiryDate).format('YYYY-MM-DD') : data.safetycertExpiryDate}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'radiocertNo')}>
                {data.radiocertNo}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'radiocertExpiryDate')}>
                {data.radiocertExpiryDate ? moment(data.radiocertExpiryDate).format('YYYY-MM-DD') : data.radiocertExpiryDate}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'equipcertNo')}>
                {data.equipcertNo}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'equipcertExpiryDate')}>
                {data.equipcertExpiryDate ? moment(data.equipcertExpiryDate).format('YYYY-MM-DD') : data.equipcertExpiryDate}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'loadlinecertNo')}>
                {data.loadlinecertNo}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'loadlinecertExpiryDate')}>
                {data.loadlinecertExpiryDate ? moment(data.loadlinecertExpiryDate).format('YYYY-MM-DD') : data.loadlinecertExpiryDate}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'deratcertNo')}>
                {data.deratcertNo}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'deratcertExpiryDate')}>
                {data.deratcertExpiryDate ? moment(data.deratcertExpiryDate).format('YYYY-MM-DD') : data.deratcertExpiryDate}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'healthcertNo')}>
                {data.healthcertNo}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'healthcertExpiryDate')}>
                {data.healthcertExpiryDate ? moment(data.healthcertExpiryDate).format('YYYY-MM-DD') : data.healthcertExpiryDate}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'civilcertNo')}>
                {data.civilcertNo}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={getTranslatedText('A6', 'civilcertExpiryDate')}>
                {data.civilcertExpiryDate ? moment(data.civilcertExpiryDate).format('YYYY-MM-DD') : data.civilcertExpiryDate}
              </CngField>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>

      <Grid item xs={12}>
        <CngSection title={getTranslatedText('A6', 'schedule')}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
              <CngField label={getTranslatedText('A6', 'departurePort')}>
                {data.departurePort}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} >
              <CngField label={getTranslatedText('A6', 'departureTerminal')}>
                {data.departureTerminal}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} >
              <CngField label={getTranslatedText('A6', 'departurePier')}>
                {data.departurePier}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} >
              <CngField label={getTranslatedText('A6', 'etd')}>
                {data.etd ? moment(data.etd).tz("Canada/Eastern").format('YYYY-MM-DD HH:mm') : data.etd}
              </CngField>
            </Grid>

            <Grid item xs={12} sm={6} shouldHide={data.customsProc !== '22'}>
              <CngField label={getTranslatedText('A6', 'customsOfficeOfExit')}>
                {getLookupValue('customsOffice', data.customsOfficeOfExit)}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} shouldHide={data.customsProc !== '22'}>
              <CngField label={getTranslatedText('A6', 'portOfDischarge')}>
                {data.portOfDischarge}
              </CngField>
            </Grid>

            <Grid item xs={12} sm={6} shouldHide={data.customsProc === '22'}>
              <CngField label={getTranslatedText('A6', 'arrivalPort')}>
                {data.arrivalPort}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} shouldHide={data.customsProc === '22'}>
              <CngField label={getTranslatedText('A6', 'arrivalTerminal')}>
                {data.arrivalTerminal}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} shouldHide={data.customsProc === '22'}>
              <CngField label={getTranslatedText('A6', 'arrivalPier')}>
                {data.arrivalPier}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} shouldHide={data.customsProc === '22'}>
              <CngField label={getTranslatedText('A6', 'eta')}>
                {data.eta ? moment(data.eta).tz("Canada/Eastern").format('YYYY-MM-DD HH:mm') : data.eta}
              </CngField>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>

      <Grid item xs={12}>
        <CngSection title={getTranslatedText('A6', 'taciOcnA6Party')}>
          {data.taciOcnA6Party.length > 0 ? (
            <Grid container spacing={1}>
              {data.taciOcnA6Party.map(party => (
                <Grid key={party.id || party._id} item xs={12}>
                  <Card variant='outlined' style={{ backgroundColor: theme.palette.grey[50] }}>
                    <Box padding={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6', 'partyType')}>
                            {getLookupValue('partyType', party.partyType)}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6', 'partyName1')}>
                            {party.partyName1}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6', 'partyName2')}>
                            {party.partyName2}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6', 'address1')}>
                            {party.address1}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6', 'address2')}>
                            {party.address2}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6', 'city')}>
                            {party.city}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6', 'postalCode')}>
                            {party.postalCode}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6', 'countryCode')}>
                            {getLookupValue('countries', party.countryCode)}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6', 'stateCode')}>
                            {getCountryStateLabel(party.countryCode, party.stateCode)}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6', 'contactPerson')}>
                            {party.contactPerson}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6', 'telNo')}>
                            {party.telNo}
                          </CngField>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant='body2' color='textSecondary'>
              No records to display
            </Typography>
          )}
        </CngSection>
      </Grid>

      <Grid item xs={12}>
        <CngSection title={
          <Typography variant='h5' style={{ fontWeight: 700 }}>
            {getTranslatedText('A6', 'taciOcnA6Route') + ' '}
            <Typography color='textSecondary' component='span' variant='caption'>
              {`/ ${data.taciOcnA6Route.length} Entries`}
            </Typography>
          </Typography>
        }>
          <TaciOcnA6RouteTable data={data.taciOcnA6Route} />
        </CngSection>
      </Grid>

      {data.containerShipment &&
        <Grid item xs={12}>
          <CngSection title={
            <Typography variant='h5' style={{ fontWeight: 700 }}>
              {getTranslatedText('A6', 'taciOcnA6Container') + ' '}
              <Typography color='textSecondary' component='span' variant='caption'>
                {`/ ${data.taciOcnA6Container.length} Entries`}
              </Typography>
            </Typography>
          }>
            <TaciOcnA6ContainerTable data={data.taciOcnA6Container} />
          </CngSection>
        </Grid>
      }

      <Grid item xs={12}>
        <CngSection title={
          <Typography variant='h5' style={{ fontWeight: 700 }}>
            {getTranslatedText('A6', 'taciOcnA6SN') + ' '}
            <Typography color='textSecondary' component='span' variant='caption'>
              {`/ ${data.taciOcnA6SN.length} Entries`}
            </Typography>
          </Typography>
        }>
          <TaciOcnA6SNTable data={data.taciOcnA6SN} />
        </CngSection>
      </Grid>

    </Grid>
  )
}

export default A6ViewPage
