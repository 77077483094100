import makeValidationSchema from './PartyDetailsMakeValidationSchema'
import { useTranslation, constants } from 'cng-web-lib'
import React, { useState, useContext, useCallback, useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AesShipmentKeys from 'src/constants/locale/key/AesShipment'
import { Card, CardContent, Grid, Typography, InputAdornment, IconButton, Box } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  components,
} from 'cng-web-lib'
import { useFormContext } from 'react-hook-form'
import { AesTranslationContext } from '../contexts/AESTranslationContext'
import { NaAcePartyMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import TemplateSelectDialog from 'src/components/aciacehighway/TemplateSelectDialog'
import _ from 'lodash'
import PartyMasterApiUrls from '../../../apiUrls/PartyMasterApiUrls'
import { NaAesStateCodeAutoCompleteField } from 'src/components/na'

const {
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField,
    }
  },
  CngGridItem,
  table: { useFetchCodeMaintenanceLookup },
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  headerId: "",
  partyType: "",
  partyIdentifierType: "",
  partyIdentifier: "",
  name: "",
  contactFirstName: "",
  contactLastName: "",
  addressLine1: "",
  addressLine2: "",
  contactPhoneNumber: "",
  cityName: "",
  stateCode: "",
  postalCode: "",
  countryCode: "",
  usppiIrsNumber: "",
  usppiIrsNumberType: "",
  ultimateConsigneeType: "",
  email: "",
  partyId: "",
  saveFlag: false,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

const {
  filter: { LIKE, EQUAL, IN }
} = constants

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  index
}) {

  const { getTranslatedText } = useContext(AesTranslationContext)
  const { getValues, setValue, reset, watch, trigger } = useFormContext()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const saveFlag = watch('saveFlag')
  const partyType = watch('partyType')
  const countryCode = watch('countryCode')
  const [templateDialog, setTemplateDialog] = useState(false)
  const [lookups, setLookups] = useState(null)
  const partyIdentifierType = watch('partyIdentifierType')

  useEffect(() => {
    Promise.all([
      // Crew Type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'AES_PARTY_TYPE' }],
        undefined,
        'code'
      )
    ]).then(([partyType]) => {
      setLookups({ partyType })
    })
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value
    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const columns = [
    {
      field: 'partytype',
      title: getTranslatedText('AES', 'partyType'),
      render: (data) =>
        <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
          {getLookupValue('partyType', data.partytype)}
        </Typography>
    },
    {
      field: 'partyidn',
      title: getTranslatedText('AES', 'partyIdentifier')
    },
    {
      field: 'partyname1',
      title: getTranslatedText('AES', 'name')
    },
    {
      field: 'countrycode',
      title: getTranslatedText('AES', 'countryCode')
    },
    {
      field: 'statecode',
      title: getTranslatedText('AES', 'stateCode')
    },
  ]

  function handleApplyTemplate(template) {
    if (!template) {
      reset()
      return
    }

    const {
      partyidn,
      partytype,
      partyname1,
      partyname2,
      address1,
      address2,
      address3,
      cityname,
      postalcode,
      statecode,
      countrycode,
      ctpatInd,
      email,
      faxNo,
      telephonenumber,
      partyIdentifier,
      contactFirstName,
      contactLastName
    } = template

    const partyType = _.isEmpty(partytype) ? '' : partytype.slice(-2)

    const convertPartyType = [{ id: 'C', value: 'UC' }] //CZ, SH is Shipper in other module

    var matchPartyObj = (convertPartyType.find(obj => {
        return obj.id === partyType || obj.value === partyType;
      }))
      var convPartyType = partyType ? undefined !== matchPartyObj ? matchPartyObj.id : partyType : partyType;

    setValue('partyId', partyidn, { shouldDirty: true })
    setValue('partyType', convPartyType, { shouldDirty: true })
    setValue('name', partyname1 || '', { shouldDirty: true })
    setValue('addressLine1', address1 || '', { shouldDirty: true })
    setValue('addressLine2', address2 || '', { shouldDirty: true })
    setValue('cityName', cityname || '', { shouldDirty: true })
    setValue('postalCode', postalcode || '', { shouldDirty: true })
    setValue('stateCode', statecode || '', { shouldDirty: true })
    setValue('countryCode', countrycode || '', { shouldDirty: true })
    setValue('email', email || '', { shouldDirty: true })
    setValue('contactFirstName', contactFirstName || '', { shouldDirty: true })
    setValue('contactLastName', contactLastName || '', { shouldDirty: true })
    setValue('contactPhoneNumber', telephonenumber || '', { shouldDirty: true })

    if (partyIdentifier !== null && partyIdentifier !== undefined && partyIdentifier.length > 0) {
      const { partyIdentifierCode, partyIdentifierNumber } = partyIdentifier[partyIdentifier.length - 1]
      setValue('partyIdentifierType', partyIdentifierCode || '', { shouldDirty: true })
      setValue('partyIdentifier', partyIdentifierNumber || '', { shouldDirty: true })
    }

    trigger()
  }

  return (
    <>
      <Grid container spacing={1}>
        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.partyType}>
          <CngCodeMasterAutocompleteField
            name="partyType"
            label={getTranslatedText('AES', 'partyType')}
            disabled={disabled}
            size='small'
            codeType='AES_PARTY_TYPE'
            required
            key={partyType}
          />
        </CngGridItem>
        <CngGridItem xs={12} lg={8}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} md={3} shouldHide={shouldHideMap?.saveFlag}>
              <CngCheckboxField
                label={
                  <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                    {getTranslatedText('AES', 'savePartyFlag')}
                  </Typography>
                }
                name='saveFlag'
                onChange={(e) => setValue('saveFlag', e.target.checked)}
                size='small'
                style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md shouldHide={shouldHideMap?.partyId}>
              <CngTextField
                required
                name='partyId'
                inputProps={{ maxLength: 35 }}
                label={getTranslatedText('AES', 'partyId')}
                disabled={disabled || !saveFlag}
                onChange={(e) => {
                  setValue('partyId', e.target.value.toUpperCase(), {
                    shouldValidate: true
                  })
                }}
                onBlur={(e) => {
                  if (e.target.value !== undefined) {
                    setValue('partyId', e.target.value.trim(), {
                      shouldValidate: true
                    })
                  }
                }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4}>
              <NaAcePartyMasterAutoCompleteField
                name={'partyDropDown'}
                label={getTranslatedText('AES', 'partyDropdown')}
                disabled={disabled}
                onChange={(_, options) => handleApplyTemplate(options.data)}
                lookupProps={{
                  filters: [
                    {
                      field: 'partyId',
                      operator: EQUAL,
                      value: FileForUserGetPartyId()
                    },
                    { field: 'partytype', operator: IN, value: ["F", "I", "E", "UC"] }
                  ]
                }}
                size='small'
                fullWidth
                disableClearable
                textFieldProps={{
                  InputProps: {
                    customEndAdornment: () => (
                      <InputAdornment position='end' style={{ marginTop: -16 }}>
                        <IconButton onClick={(event) => {
                          event.stopPropagation()
                          setTemplateDialog(true)
                        }}>
                          <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                            <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                          </Box>
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                }}
                forcePopupIcon={false}
              />

            </CngGridItem>
          </Grid>
        </CngGridItem>
        <CngGridItem xs={12}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.partyIdentifierType}>
              <CngCodeMasterAutocompleteField
                name="partyIdentifierType"
                label={getTranslatedText('AES', 'partyIdentifierType')}
                disabled={disabled}
                size='small'
                codeType='AES_PARTY_IDENTIFIER'
                required={partyType=='F' || partyType=='E'}
                key={partyIdentifierType}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.partyIdentifier}>
              <CngTextField
                name="partyIdentifier"
                label={getTranslatedText('AES', 'partyIdentifier')}
                disabled={disabled}
                size='small'
                onChange={(e) => {
                  setValue('partyIdentifier', e.target.value.toUpperCase(), {
                    shouldValidate: true
                  })
                }}
                onBlur={(e) => {
                  if (e.target.value !== undefined) {
                    setValue('partyIdentifier', e.target.value.trim(), {
                      shouldValidate: true
                    })
                  }
                }}
                required={partyType=='F' || partyType=='E'}
              />
            </CngGridItem>
            {partyType == "C" && (
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.ultimateConsigneeType}>
                <CngCodeMasterAutocompleteField
                  name="ultimateConsigneeType"
                  label={getTranslatedText('AES', 'ultimateConsigneeType')}
                  disabled={disabled}
                  size='small'
                  codeType='AES_ULTIMATE_CONSIGNEE_TYPE'
                  lookupProps={{
                    label: (record) => `${record.code};${record.descriptionEn}`
                  }}
                  required={partyType=='C'}
                />
              </CngGridItem>
            )}
          </Grid>
        </CngGridItem>
        {partyType == "E" && (
          <CngGridItem xs={12}>
            <Grid container spacing={1}>

              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.usppiIrsNumberType}>
                <CngCodeMasterAutocompleteField
                  name="usppiIrsNumberType"
                  label={getTranslatedText('AES', 'usppiIrsNumberType')}
                  disabled={disabled}
                  size='small'
                  codeType='AES_USPPI_IDENTIFIER'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.usppiIrsNumber}>
                <CngTextField
                  name="usppiIrsNumber"
                  label={getTranslatedText('AES', 'usppiIrsNumber')}
                  disabled={disabled}
                  size='small'
                  onChange={(e) => {
                    setValue('usppiIrsNumber', e.target.value.toUpperCase(), {
                      shouldValidate: true
                    })
                  }}
                  onBlur={(e) => {
                    if (e.target.value !== undefined) {
                      setValue('usppiIrsNumber', e.target.value.trim(), {
                        shouldValidate: true
                      })
                    }
                  }}
                />
              </CngGridItem>

            </Grid>
          </CngGridItem>
        )}
        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.name}>
          <CngTextField
            name="name"
            label={getTranslatedText('AES', 'name')}
            disabled={disabled}
            size='small'
            onChange={(e) => {
              setValue('name', e.target.value.toUpperCase(), {
                shouldValidate: true
              })
            }}
            onBlur={(e) => {
              if (e.target.value !== undefined) {
                setValue('name', e.target.value.trim(), {
                  shouldValidate: true
                })
              }
            }}
            required
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.addressLine1}>
          <CngTextField
            name="addressLine1"
            label={getTranslatedText('AES', 'addressLine1')}
            disabled={disabled}
            size='small'
            onChange={(e) => {
              setValue('addressLine1', e.target.value.toUpperCase(), {
                shouldValidate: true
              })
            }}
            onBlur={(e) => {
              if (e.target.value !== undefined) {
                setValue('addressLine1', e.target.value.trim(), {
                  shouldValidate: true
                })
              }
            }}
            required={partyType=='F' || partyType=='E' || partyType=='I'}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.addressLine2}>
          <CngTextField
            name="addressLine2"
            label={getTranslatedText('AES', 'addressLine2')}
            disabled={disabled}
            size='small'
            onChange={(e) => {
              setValue('addressLine2', e.target.value.toUpperCase(), {
                shouldValidate: true
              })
            }}
            onBlur={(e) => {
              if (e.target.value !== undefined) {
                setValue('addressLine2', e.target.value.trim(), {
                  shouldValidate: true
                })
              }
            }}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.postalCode}>
          <CngTextField
            name="postalCode"
            label={getTranslatedText('AES', 'postalCode')}
            disabled={disabled}
            size='small'
            onChange={(e) => {
              setValue('postalCode', e.target.value.toUpperCase(), {
                shouldValidate: true
              })
            }}
            onBlur={(e) => {
              if (e.target.value !== undefined) {
                setValue('postalCode', e.target.value.trim(), {
                  shouldValidate: true
                })
              }
            }}
            required={countryCode=='US'}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.cityName}>
          <CngTextField
            name="cityName"
            label={getTranslatedText('AES', 'cityName')}
            disabled={disabled}
            size='small'
            onChange={(e) => {
              setValue('cityName', e.target.value.toUpperCase(), {
                shouldValidate: true
              })
            }}
            onBlur={(e) => {
              if (e.target.value !== undefined) {
                setValue('cityName', e.target.value.trim(), {
                  shouldValidate: true
                })
              }
            }}
            required
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.countryCode}>
          <CngCountryAutocompleteField
            name="countryCode"
            label={getTranslatedText('AES', 'countryCode')}
            disabled={disabled}
            size='small'
            lookupProps={{
              label: (record) => `${record.code};${record.descriptionEn}`
            }}
            required
            key={countryCode}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.stateCode}>
          <NaAesStateCodeAutoCompleteField
            name="stateCode"
            label={getTranslatedText('AES', 'stateCode')}
            disabled={disabled}
            size='small'
            countryCode={countryCode}
            key={countryCode}
            required={countryCode=='US' && (partyType=='F' || partyType=='E')}
          />
        </CngGridItem>

        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.contactFirstName}>
          <CngTextField
            name="contactFirstName"
            label={getTranslatedText('AES', 'contactFirstName')}
            disabled={disabled}
            size='small'
            onChange={(e) => {
              setValue('contactFirstName', e.target.value.toUpperCase(), {
                shouldValidate: true
              })
            }}
            onBlur={(e) => {
              if (e.target.value !== undefined) {
                setValue('contactFirstName', e.target.value.trim(), {
                  shouldValidate: true
                })
              }
            }}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.contactLastName}>
          <CngTextField
            name="contactLastName"
            label={getTranslatedText('AES', 'contactLastName')}
            disabled={disabled}
            size='small'
            onChange={(e) => {
              setValue('contactLastName', e.target.value.toUpperCase(), {
                shouldValidate: true
              })
            }}
            onBlur={(e) => {
              if (e.target.value !== undefined) {
                setValue('contactLastName', e.target.value.trim(), {
                  shouldValidate: true
                })
              }
            }}
            required={partyType=='E'}
          />
        </CngGridItem>

        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.contactPhoneNumber}>
          <CngTextField
            name="contactPhoneNumber"
            label={getTranslatedText('AES', 'contactPhoneNumber')}
            disabled={disabled}
            size='small'
            onChange={(e) => {
              setValue('contactPhoneNumber', e.target.value.toUpperCase(), {
                shouldValidate: true
              })
            }}
            onBlur={(e) => {
              if (e.target.value !== undefined) {
                setValue('contactPhoneNumber', e.target.value.trim(), {
                  shouldValidate: true
                })
              }
            }}
            required={partyType=='F' || partyType=='E'}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.email}>
          <CngTextField
            name="email"
            label={getTranslatedText('AES', 'email')}
            disabled={disabled}
            size='small'
            onChange={(e) => {
              setValue('email', e.target.value.toUpperCase(), {
                shouldValidate: true
              })
            }}
            onBlur={(e) => {
              if (e.target.value !== undefined) {
                setValue('email', e.target.value.trim(), {
                  shouldValidate: true
                })
              }
            }}
          />
        </CngGridItem>



      </Grid>
      <TemplateSelectDialog
        columns={columns}
        defaultSorts={[{ field: 'partyidn', direction: 'ASC' }]}
        fetch={{ url: PartyMasterApiUrls.SEARCH }}
        fetchFilters={[
          { field: 'partytype', operator: IN, value: ["F", "I", "E", "UC"] },
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        onApplyTemplate={handleApplyTemplate}
        onClose={() => setTemplateDialog(false)}
        open={templateDialog}
        search={{ field: 'partyidn', operator: LIKE }}
        title={getTranslatedText('AES', 'partyTemplate')}
      />
    </>
  )
}

const PartyDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default PartyDetailsFormProperties
