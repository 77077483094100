import React, { useCallback } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AcioceanKeys from 'src/constants/locale/key/Aciocean'

const A6TranslationContext = React.createContext()

const A6TranslationConsumer = A6TranslationContext.Consumer

const A6TranslationProvider = ({ children }) => {
    const { translate } = useTranslation([Namespace.A6])

    const translation = {
        A6: {
            aciocean: translate(Namespace.A6, AcioceanKeys.TITLE),
            createdDate: translate(Namespace.A6, AcioceanKeys.CREATED_DATE),
            updatedDate: translate(Namespace.A6, AcioceanKeys.UPDATED_DATE),
            sno: translate(Namespace.A6, AcioceanKeys.SNO),
            doctype: translate(Namespace.A6, AcioceanKeys.DOCTYPE),
            carrierCode: translate(Namespace.A6, AcioceanKeys.CARRIER_CODE),
            vesselName: translate(Namespace.A6, AcioceanKeys.VESSEL_NAME),
            vesselCode: translate(Namespace.A6, AcioceanKeys.VESSEL_CODE),
            voyageNo: translate(Namespace.A6, AcioceanKeys.VOYAGE_NO),
            nationality: translate(Namespace.A6, AcioceanKeys.NATIONALITY),
            masterOperator: translate(Namespace.A6, AcioceanKeys.MASTER_OPERATOR),
            convRefno: translate(Namespace.A6, AcioceanKeys.CONV_REFNO),
            crew: translate(Namespace.A6, AcioceanKeys.CREW),
            passengers: translate(Namespace.A6, AcioceanKeys.PASSENGERS),
            conveyanceType: translate(Namespace.A6, AcioceanKeys.CONVEYANCE_TYPE),
            containerShipment: translate(Namespace.A6, AcioceanKeys.CONTAINER_SHIPMENT),
            customsProc: translate(Namespace.A6, AcioceanKeys.CUSTOMS_PROC),
            charterInfo: translate(Namespace.A6, AcioceanKeys.CHARTER_INFO),
            specialops: translate(Namespace.A6, AcioceanKeys.SPECIALOPS),
            cargoDescription: translate(Namespace.A6, AcioceanKeys.CARGO_DESCRIPTION),
            regNo: translate(Namespace.A6, AcioceanKeys.REG_NO),
            regDate: translate(Namespace.A6, AcioceanKeys.REG_DATE),
            placeOfRegistry: translate(Namespace.A6, AcioceanKeys.PLACE_OF_REGISTRY),
            nettWt: translate(Namespace.A6, AcioceanKeys.NETT_WT),
            grossWt: translate(Namespace.A6, AcioceanKeys.GROSS_WT),
            cntrcargoWt: translate(Namespace.A6, AcioceanKeys.CNTRCARGO_WT),
            cargoWt: translate(Namespace.A6, AcioceanKeys.CARGO_WT),
            deadWt: translate(Namespace.A6, AcioceanKeys.DEAD_WT),
            length: translate(Namespace.A6, AcioceanKeys.LENGTH),
            safetycertNo: translate(Namespace.A6, AcioceanKeys.SAFETYCERT_NO),
            safetycertExpiryDate: translate(Namespace.A6, AcioceanKeys.SAFETYCERT_EXPIRY_DATE),
            radiocertNo: translate(Namespace.A6, AcioceanKeys.RADIOCERT_NO),
            radiocertExpiryDate: translate(Namespace.A6, AcioceanKeys.RADIOCERT_EXPIRY_DATE),
            equipcertNo: translate(Namespace.A6, AcioceanKeys.EQUIPCERT_NO),
            equipcertExpiryDate: translate(Namespace.A6, AcioceanKeys.EQUIPCERT_EXPIRY_DATE),
            loadlinecertNo: translate(Namespace.A6, AcioceanKeys.LOADLINECERT_NO),
            loadlinecertExpiryDate: translate(Namespace.A6, AcioceanKeys.LOADLINECERT_EXPIRY_DATE),
            deratcertNo: translate(Namespace.A6, AcioceanKeys.DERATCERT_NO),
            deratcertExpiryDate: translate(Namespace.A6, AcioceanKeys.DERATCERT_EXPIRY_DATE),
            healthcertNo: translate(Namespace.A6, AcioceanKeys.HEALTHCERT_NO),
            healthcertExpiryDate: translate(Namespace.A6, AcioceanKeys.HEALTHCERT_EXPIRY_DATE),
            civilcertNo: translate(Namespace.A6, AcioceanKeys.CIVILCERT_NO),
            civilcertExpiryDate: translate(Namespace.A6, AcioceanKeys.CIVILCERT_EXPIRY_DATE),
            departurePort: translate(Namespace.A6, AcioceanKeys.DEPARTURE_PORT),
            departureTerminal: translate(Namespace.A6, AcioceanKeys.DEPARTURE_TERMINAL),
            departurePier: translate(Namespace.A6, AcioceanKeys.DEPARTURE_PIER),
            etd: translate(Namespace.A6, AcioceanKeys.ETD),
            arrivalPort: translate(Namespace.A6, AcioceanKeys.ARRIVAL_PORT),
            arrivalTerminal: translate(Namespace.A6, AcioceanKeys.ARRIVAL_TERMINAL),
            arrivalPier: translate(Namespace.A6, AcioceanKeys.ARRIVAL_PIER),
            eta: translate(Namespace.A6, AcioceanKeys.ETA),
            a6aStatus: translate(Namespace.A6, AcioceanKeys.A6A_STATUS),
            submittedBy: translate(Namespace.A6, AcioceanKeys.SUBMITTED_BY),
            submittedDate: translate(Namespace.A6, AcioceanKeys.SUBMITTED_DATE),
            viewMultipleRecords: translate(Namespace.A6, AcioceanKeys.VIEW_MULTIPLE_RECORDS),
            deleteMultipleRecords: translate(Namespace.A6, AcioceanKeys.DELETE_MULTIPLE_RECORDS),
            cloneMultipleRecords: translate(Namespace.A6, AcioceanKeys.CLONE_MULTIPLE_RECORDS),
            editMultipleRecords: translate(Namespace.A6, AcioceanKeys.EDIT_MULTIPLE_RECORDS),
            editSentRecords: translate(Namespace.A6, AcioceanKeys.EDIT_SENT_RECORDS),
            editButton: translate(Namespace.A6, AcioceanKeys.EDIT_BUTTON),
            viewButton: translate(Namespace.A6, AcioceanKeys.VIEW_BUTTON),
            cloneButton: translate(Namespace.A6, AcioceanKeys.CLONE_BUTTON),
            submitSentRecord: translate(Namespace.A6, AcioceanKeys.SUBMIT_SENT_RECORD),
            submitSuccessMsg: translate(Namespace.A6, AcioceanKeys.SUBMIT_SUCCESS_MSG),
            submitErrorMsg: translate(Namespace.A6, AcioceanKeys.SUBMIT_ERROR_MSG),
            submitRecordDialogContent: translate(Namespace.A6, AcioceanKeys.SUBMIT_RECORD_DIALOG_CONTENT),
            submitRecord: translate(Namespace.A6, AcioceanKeys.SUBMIT_RECORD),
            cancelButton: translate(Namespace.A6, AcioceanKeys.CANCEL_BUTTON),
            deleteButton: translate(Namespace.A6, AcioceanKeys.DELETE_BUTTON),
            submitButton: translate(Namespace.A6, AcioceanKeys.SUBMIT_BUTTON),
            creationdatetime: translate(Namespace.A6, AcioceanKeys.CREATIONDATETIME),
            printA6: translate(Namespace.A6, AcioceanKeys.PRINT_A6),
            printMultiplrRecords: translate(Namespace.A6, AcioceanKeys.PRINT_MULTIPLE_RECORDS),
            messageFunction: translate(Namespace.A6, AcioceanKeys.MESSAGE_FUNCTION),
            createdByLoginId: translate(Namespace.A6, AcioceanKeys.CREATED_BY_LOGIN_ID),
            deleteContainer: translate(Namespace.A6, AcioceanKeys.DELETE_CONTAINER),
            deleteContainerConfirmMsg: translate(Namespace.A6, AcioceanKeys.DELETE_CONTAINER_CONFIRM_MSG),
            yesButton: translate(Namespace.A6, AcioceanKeys.YES_BUTTON),
            backButton: translate(Namespace.A6, AcioceanKeys.BACK_BUTTON),

            certificates: translate(Namespace.A6, AcioceanKeys.CERTIFICATES),
            schedule: translate(Namespace.A6, AcioceanKeys.SCHEDULE),
            header: translate(Namespace.A6, AcioceanKeys.HEADER),
            vessel: translate(Namespace.A6, AcioceanKeys.VESSEL),
            templateFlag: translate(Namespace.A6, AcioceanKeys.TEMPLATE_FLAG),
            templateName: translate(Namespace.A6, AcioceanKeys.TEMPLATE_NAME),
            netWeightUom: translate(Namespace.A6, AcioceanKeys.NETT_WT_UOM),
            grossWeightUom: translate(Namespace.A6, AcioceanKeys.GROSS_WT_UOM),
            containerizedCargoWeightUom: translate(Namespace.A6, AcioceanKeys.CNTRCARGO_WT_UOM),
            nonContainerizedCargoWeightUom: translate(Namespace.A6, AcioceanKeys.CARGO_WT_UOM),
            summerDeadWeightUom: translate(Namespace.A6, AcioceanKeys.DEAD_WT_UOM),
            overallLengthUom: translate(Namespace.A6, AcioceanKeys.LENGTH_UOM),
            portOfDischarge: translate(Namespace.A6, AcioceanKeys.PORT_OF_DISCHARGE),
            customsOfficeOfExit: translate(Namespace.A6, AcioceanKeys.CUSTOMS_OFFICE_OF_DECLARATION),
            taciOcnA6Party: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Party.TITLE),
            partyType: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Party.PARTY_TYPE),
            partyName1: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Party.PARTY_NAME1),
            partyName2: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Party.PARTY_NAME2),
            address1: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Party.ADDRESS1),
            address2: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Party.ADDRESS2),
            city: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Party.CITY),
            stateCode: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Party.STATE_CODE),
            postalCode: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Party.POSTAL_CODE),
            countryCode: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Party.COUNTRY_CODE),
            contactPerson: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Party.CONTACT_PERSON),
            telNo: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Party.TEL_NO),
            taciOcnA6Route: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Route.TITLE),
            createdBy: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Route.CREATED_BY),
            updatedBy: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Route.UPDATED_BY),
            portOfCall: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Route.PORT_OF_CALL),
            terminal: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Route.TERMINAL),
            pierNo: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Route.PIER_NO),
            taciOcnA6Container: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Container.TITLE),
            containerSize: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Container.CONTAINER_SIZE),
            containerStatus: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Container.CONTAINER_STATUS),
            noOfContainers: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Container.NO_OF_CONTAINERS),
            taciOcnA6SN: translate(Namespace.A6, AcioceanKeys.TaciOcnA6SN.TITLE),
            name: translate(Namespace.A6, AcioceanKeys.TaciOcnA6SN.NAME),
            email: translate(Namespace.A6, AcioceanKeys.TaciOcnA6SN.EMAIL),
            phno: translate(Namespace.A6, AcioceanKeys.TaciOcnA6SN.PHNO),
            snTyp: translate(Namespace.A6, AcioceanKeys.TaciOcnA6SN.SN_TYP),
            tonUOM: translate(Namespace.A6, AcioceanKeys.TON_UOM),

            partyDropDown: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Party.PARTY_DROP_DOWN),
            saveFlag: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Party.SAVE_FLAG),
            partyIdentification: translate(Namespace.A6, AcioceanKeys.TaciOcnA6Party.PARTY_IDENTIFICATION),

            errorCode: translate(Namespace.A6, AcioceanKeys.ERROR_CODE),
            fieldDesc: translate(Namespace.A6, AcioceanKeys.FIELD_DESC),
            rejectType: translate(Namespace.A6, AcioceanKeys.REJECT_TYPE),
            errorDesc: translate(Namespace.A6, AcioceanKeys.ERROR_DESC),
            responseText: translate(Namespace.A6, AcioceanKeys.RESPONSE_TEXT),

            d4title: translate(Namespace.A6, AcioceanKeys.D4_TITLE),
            systemDateTime: translate(Namespace.A6, AcioceanKeys.SYSTEM_PROCESSED_DATE_TIME),
            cbsaRefNo: translate(Namespace.A6, AcioceanKeys.CBSA_REF_NO),
            cbsaProcessingDate: translate(Namespace.A6, AcioceanKeys.CBSA_PROCESSED_DATE_TIME),
            remarks: translate(Namespace.A6, AcioceanKeys.REMARKS),
            documentType: translate(Namespace.A6, AcioceanKeys.DOCUMENT_TYPE)

        }
    }

    const getTranslatedText = useCallback((namespace, key) => {
        if (!namespace || !key) return undefined

        return translation?.[namespace]?.[key] || undefined
    }, [])

    return (
        <A6TranslationContext.Provider value={{ translation, getTranslatedText }}>
            {children}
        </A6TranslationContext.Provider>
    )
}

export default A6TranslationProvider

export { A6TranslationContext, A6TranslationConsumer }
