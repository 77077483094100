import React, { useContext, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import {
    components
} from 'cng-web-lib'
import { AesTranslationContext } from '../../contexts/AESTranslationContext'
import Switch from 'src/components/Switch'
import { useFormContext } from 'react-hook-form'
import CngSection from 'src/components/cngcomponents/CngSection'
import PartyDetailsTable from '../PartyDetailsTable'
import PartyDetailsDialog from '../PartyDetailsDialog'

const {
    form: {
        field: {
            CngSelectField
        },
    },
    CngGridItem,
} = components

function PartyDetailsSection({ disabled, showNotification, shouldHideMap, getLookupValue, getCountryStateLabel }) {
    const { getTranslatedText } = useContext(AesTranslationContext)

    const { setValue, watch, getValues } = useFormContext();

    const partyDetails = watch('partyDetails')

    const [partyDetailsDialog, setPartyDetailsDialog] = useState({
        open: false,
        partyDetails: null,
        isView: false
    })
    function handleDeletePartyDetails(data) {
        const partyDetails = [...getValues('partyDetails')].filter((parties) =>
            parties._id ? parties._id !== data._id : parties.id !== data.id
        )
        setValue('partyDetails', partyDetails)
    }

    return (
        <Grid item xs={12}>
            <CngSection
                title={getTranslatedText('AES', 'partyDetails')}
                subheader={
                    <Typography color='textSecondary' variant='caption'>
                        <Typography variant='inherit' color='error'>*</Typography>
                        {getTranslatedText('AES', 'mandatoryField')}
                    </Typography>
                }
                id="party-details"
            >
                <PartyDetailsTable
                    partyDetails={partyDetails}
                    onAddPartyDetails={() =>
                        setPartyDetailsDialog({
                            open: true,
                            partyDetails: null,
                            isView: false
                        })
                    }
                    onDeletePartyDetails={handleDeletePartyDetails}
                    onEditPartyDetails={(partyDetails) =>
                        setPartyDetailsDialog({
                            open: true,
                            partyDetails,
                            isView: false
                        })
                    }
                />
                <PartyDetailsDialog
                    partyDetails={partyDetailsDialog.partyDetails}
                    isView={partyDetailsDialog.isView}
                    open={partyDetailsDialog.open}
                    onClose={() =>
                        setPartyDetailsDialog({
                            open: false,
                            partyDetails: null,
                            isView: false
                        })
                    }
                    showNotification={showNotification}
                    getLookupValue={getLookupValue}
                    getCountryStateLabel={getCountryStateLabel}
                />
            </CngSection>
        </Grid>
    )
}

export default PartyDetailsSection
