import React, { useState, useContext, useEffect } from 'react'
import { components, useTranslation, constants } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayShipmentKeys from 'src/constants/locale/key/AceHighwayShipment'
import TinyChip from 'src/components/aciacehighway/TinyChip'
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Select
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { AesTranslationContext } from '../contexts/AESTranslationContext'
import { getStatusMetadataMini } from 'src/common/NACommon'
import { useFormContext } from 'react-hook-form'


const {
  button: { CngButton, CngIconButton },
  table: { useFetchCodeMaintenanceLookup },
} = components

const {
  filter: { LIKE, EQUAL, IN }
} = constants

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      padding: '12px 16px',
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        cursor: 'pointer',
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

function CommodityDetailsTable(props) {
  const {
    commodityDetails,
    onViewCommodityDetails,
    onAddCommodityDetails,
    onDeleteCommodityDetails,
    onEditCommodityDetails
  } = props

  const { getTranslatedText } = useContext(AesTranslationContext)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const [searchTerm, setSearchTerm] = useState('')
  const classes = useStyles()
  let counter = 1;
  const [lookups, setLookups] = useState(null)
  const filtered =
    searchTerm !== ''
      ? commodityDetails.filter((party) =>
        party.commodityDescription.includes(searchTerm)
      )
      : commodityDetails

  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'AES_ORIGIN_OF_GOODS' }],
        undefined,
        'code'
      )
    ]).then(([originOfGoods]) => { setLookups({ originOfGoods }) })
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value
    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  //const tableRef = useRef(null)

  const formContext = useFormContext()
  function handleEditCommodityDetails(data) {
    const commodityDetails = [...formContext.getValues('commodityDetails')]
    const index = commodityDetails.findIndex((commodityDetails) =>
      data.id ? commodityDetails.id === data.id : commodityDetails._id === data._id
    )

    formContext.setValue(`commodityDetails.${index}`, data, {
      shouldValidate: true
    })
  }



  return (
    <>
      <Box marginBottom={1}>
        <Grid container spacing={2}>
          <Grid item xs>
            {commodityDetails && commodityDetails.length > 0 && (
              <TextField
                fullWidth
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder='Search in your entries'
                size='small'
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <FontAwesomeIcon icon={['fal', 'search']} />
                    </InputAdornment>
                  )
                }}
              />
            )}
          </Grid>
          {onAddCommodityDetails && (
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                onClick={onAddCommodityDetails}
                size='medium'
                startIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
              >
                {getTranslatedText('AES', 'addCommodityButton')}
              </CngButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              <TableCell>{getTranslatedText('AES', 'line')}</TableCell>
              <TableCell>{getTranslatedText('AES', 'commodityDescription')}</TableCell>
              <TableCell>{getTranslatedText('AES', 'htsNumber')}</TableCell>
              <TableCell>{getTranslatedText('AES', 'originOfGoods')}</TableCell>
              <TableCell>{getTranslatedText('AES', 'quantity1')}</TableCell>
              <TableCell>{getTranslatedText('AES', 'quantity2')}</TableCell>
              <TableCell>{getTranslatedText('AES', 'valueOfGoods')}</TableCell>
              <TableCell>{getTranslatedText('AES', 'actionCode')}</TableCell>
              <TableCell>{getTranslatedText('AES', 'status')}</TableCell>
              {(onEditCommodityDetails || onDeleteCommodityDetails) && (
                <TableCell align='right'>Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered && filtered.length > 0 ? (

              filtered.map((commodityDetails) => (
                <TableRow
                  key={commodityDetails._id || commodityDetails.id}
                  hover
                  onClick={() => {
                    if (onViewCommodityDetails) {
                      onViewCommodityDetails(commodityDetails)
                    }
                    if (onEditCommodityDetails) {
                      onEditCommodityDetails(commodityDetails)
                    }
                  }}
                >
                  <TableCell>{counter++}</TableCell>
                  <TableCell>{commodityDetails.commodityDescription}</TableCell>
                  <TableCell>{commodityDetails.htsNumber}</TableCell>
                  <TableCell>{getLookupValue("originOfGoods", commodityDetails.originOfGoods)}</TableCell>
                  <TableCell>{commodityDetails.quantity1}</TableCell>
                  <TableCell>{commodityDetails.quantity2}</TableCell>
                  <TableCell>{commodityDetails.valueOfGoods}</TableCell>
                  <TableCell>
                    {onViewCommodityDetails && (
                      <>
                        {commodityDetails.actionCode == 'A' && getTranslatedText('AES', 'add')}
                        {commodityDetails.actionCode == 'C' && getTranslatedText('AES', 'change')}
                        {commodityDetails.actionCode == 'D' && getTranslatedText('AES', 'delete')}
                      </>
                    )}
                    {onEditCommodityDetails && (
                      <Select
                        className={classes.select}
                        native
                        onChange={(event) =>
                          handleEditCommodityDetails({ ...commodityDetails, actionCode: event.target.value })
                        }
                        onClick={(event) => event.stopPropagation()}
                        size='small'
                        value={commodityDetails.actionCode || ''}
                        variant='outlined'
                      >
                        <option value=''>--{getTranslatedText('AES', 'select')}--</option>
                        <option value='A'>{getTranslatedText('AES', 'add')}</option>
                        <option value='C'>{getTranslatedText('AES', 'change')}</option>
                        <option value='D'>{getTranslatedText('AES', 'delete')}</option>
                      </Select>)}
                  </TableCell>
                  <TableCell>{getStatusMetadataMini(commodityDetails.status).label}</TableCell>
                  {(onEditCommodityDetails || onDeleteCommodityDetails) && (
                    <TableCell>
                      <Grid container spacing={1} justify='flex-end'>
                        {onEditCommodityDetails && (
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'pen']}
                              onClick={(event) => {
                                event.stopPropagation()

                                if(commodityDetails!=null){
                                    Object.keys(commodityDetails).forEach(function(k){
                                      if(k!="baseFilterDTO" && k!= "errorMessages" && k!= "customData" && commodityDetails[k]==null){
                                        commodityDetails[k] = ''
                                      }
                                  });
                                }
                                onEditCommodityDetails(commodityDetails)
                              }}
                              size='small'
                            />
                          </Grid>
                        )}
                        {onDeleteCommodityDetails && (
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'trash']}
                              onClick={(event) => {
                                event.stopPropagation()
                                onDeleteCommodityDetails(commodityDetails)
                              }}
                              size='small'
                            />
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={
                    3 + (onEditCommodityDetails || onDeleteCommodityDetails ? 1 : 0)
                  }
                >
                  <Typography variant='body2' color='textSecondary'>
                    {getTranslatedText('AES', '')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default CommodityDetailsTable
