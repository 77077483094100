import { Box, Button, Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { AesTranslationContext } from '../../contexts/AESTranslationContext'
import { components } from 'cng-web-lib'

function PartyScreeningButtonComponent({
  onSearch,
  onReset
}) {

  const {
    button: { CngButton },
  } = components

  const { getTranslatedText } = useContext(AesTranslationContext)

  return (
    <Box pt={4}>
      <Grid container xs={12} >
        <Grid container xs={12} justify="center" >
          <Grid>
            <Box pr={1} pl={1}>
              <CngButton color='primary' onClick={onSearch} size='medium'>{getTranslatedText('AES', 'screeningButton')}</CngButton>
            </Box>
          </Grid>
          <Grid>
            <Box pr={1} pl={1}>
              <CngButton color='secondary' onClick={onReset} size='medium'>{getTranslatedText('AES', 'resetButton')}</CngButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PartyScreeningButtonComponent;
