import React, { useContext } from 'react'
import { Grid, Typography } from '@material-ui/core'
import {
  components
} from 'cng-web-lib'
import { AesTranslationContext } from '../../contexts/AESTranslationContext'
import Switch from 'src/components/Switch'
import CngSection from 'src/components/cngcomponents/CngSection'

const {
  form: {
    field: {
      CngSelectField
    },
  },
  CngGridItem,
} = components

function ShipmentHeaderTopSection({ disabled, showNotification, shouldHideMap }) {
  const { getTranslatedText } = useContext(AesTranslationContext)

  return (
    <Grid item xs={12}>
      <CngSection
        subheader={
          <Typography color='textSecondary' variant='caption'>
            <Typography variant='inherit' color='error'>*</Typography>
            {getTranslatedText('AES', 'mandatoryField')}
          </Typography>
        }
        id="shipment-header-top"
      >
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.relatedCompanyInd}>
            <Switch
              name="relatedCompanyInd"
              label={getTranslatedText('AES', 'relatedCompanyInd')}
              disabled={disabled}
              size='small'
              fullWidth
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.usToPuertoRicoInd}>
            <CngSelectField
              name="usToPuertoRicoInd"
              label={getTranslatedText('AES', 'usToPuertoRicoInd')}
              disabled={disabled}
              size='small'
              items={[
                { text: "--" + getTranslatedText('AES', 'select') + "--", value: '' },
                { text: "Y;" + getTranslatedText('AES', 'yes'), value: 'Y' },
                { text: "N;" + getTranslatedText('AES', 'no'), value: 'N' },
              ]}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.puertoRicoToUsInd}>
            <CngSelectField
              name="puertoRicoToUsInd"
              label={getTranslatedText('AES', 'puertoRicoToUsInd')}
              disabled={disabled}
              size='small'
              items={[
                { text: "--" + getTranslatedText('AES', 'select') + "--", value: '' },
                { text: "Y;" + getTranslatedText('AES', 'yes'), value: 'Y' },
                { text: "N;" + getTranslatedText('AES', 'no'), value: 'N' },
              ]}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.foreignPrincipalPartyInd}>
            <Switch
              name="foreignPrincipalPartyInd"
              label={getTranslatedText('AES', 'foreignPrincipalPartyInd')}
              disabled={disabled}
              size='small'
              fullWidth
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.hazmatInd}>
            <Switch
              name="hazmatInd"
              label={getTranslatedText('AES', 'hazmatInd')}
              disabled={disabled}
              size='small'
              fullWidth
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.toBeSoldEnRouteInd}>
            <CngSelectField
              name="toBeSoldEnRouteInd"
              label={getTranslatedText('AES', 'toBeSoldEnRouteInd')}
              disabled={disabled}
              size='small'
              items={[
                { text: "--" + getTranslatedText('AES', 'select') + "--", value: '' },
                { text: "Y;" + getTranslatedText('AES', 'yes'), value: 'Y' },
                { text: "N;" + getTranslatedText('AES', 'no'), value: 'N' },
              ]}
            />
          </CngGridItem>
        </Grid>
      </CngSection>
    </Grid>
  )
}

export default ShipmentHeaderTopSection
