import { Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'

const {
  form: { CngViewForm }
} = components

function ViewPage({ history, location, showNotification }) {
  const { id } = useParams()

  return (
    {/*<ShipmentViewForm
      history={history}
      location={location}
      showNotification={showNotification}
  />*/}
    
  )
}

export default ViewPage
