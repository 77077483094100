import makeValidationSchema from './CommodityDetailsMakeValidationSchema'
import React, { useContext, useCallback, useState, useEffect } from 'react'
import {Grid, Typography, Divider, InputAdornment, Box, IconButton } from '@material-ui/core'
import {
  components,
  constants
} from 'cng-web-lib'
import { useFormContext } from 'react-hook-form'
import { AesTranslationContext } from '../contexts/AESTranslationContext'
import CollapsibleSection from 'src/components/ui/CollapsibleSection'
import UsedVehicleDetailsFormProperties from '../shipment/UsedVehicleDetailsFormProperties'
import UsedVehicleDetailsEntry from '../shipment/UsedVehicleDetailsEntry'
import PgaDetailsFormProperties from '../shipment/PgaDetailsFormProperties'
import PgaDetailsEntry from '../shipment/PgaDetailsEntry'
import AesProductMasterApiUrls from 'src/apiUrls/AesProductMasterApiUrls'
import TemplateSelectDialog from 'src/components/aciacehighway/TemplateSelectDialog'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import { NaAesProductMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngCheckboxField,
      CngCodeMasterAutocompleteField,
      CngSelectField
    },
  },
  CngGridItem,
  table: { useFetchCodeMaintenanceLookup },
} = components

const {
  filter: { LIKE, EQUAL, IN }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  headerId: "",
  productId: "",
  commodityDescription: "",
  htsNumber: "",
  originOfGoods: "D",
  quantity1: "",
  uom1: "",
  quantity2: "",
  uom2: "",
  valueOfGoods: "",
  exportInformationCode: "",
  shippingWeight: "",
  licenseExemptionCode: "",
  eccn: "",
  exportLicenseNumber: "",
  licenseValue: "",
  itarExemptionCode: "",
  registrationNumber: "",
  eligiblePartyCertificationInd: "",
  usmlCategoryCode: "",
  ddtcQuantity: "",
  ddtcUom: "",
  significantMilitaryEquipmentInd: "",
  actionCode: "A",
  status: "1005",
  additionalPgaDataRequiredInd: "",
  deliveryDetails: "",
  saveFlag: false,
  usedVehicleDetails: [],
  pgaDetails: []
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  index,
  modeOfTransport
}) {

  const { getTranslatedText } = useContext(AesTranslationContext)
  const { getValues, setValue, reset, watch, trigger } = useFormContext()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const saveFlag = watch('saveFlag')

  const [licenseDetailsExpandFlag, setLicenseDetailsExpandFlag] = useState(true);
  const [ddtcDetailsExpandFlag, setDdtcDetailsExpandFlag] = useState(false);
  const [templateDialog, setTemplateDialog] = useState(false)
  const licenseExemptionCode = watch("licenseExemptionCode")
  const uom1 = watch("uom1")
  const uom2 = watch("uom2")
  const exportInformationCode = watch("exportInformationCode")
  const originOfGoods = watch("originOfGoods")

  useEffect(() => {
    let licenseExemptionCode = getValues("licenseExemptionCode")
    let exportLicenseNumber = getValues("exportLicenseNumber")
    let eccn = getValues("eccn")
    let exportInformationCode = getValues("exportInformationCode")
    let licenseValue = getValues("licenseValue")
    /*if ((licenseExemptionCode != null && licenseExemptionCode != undefined && licenseExemptionCode != "") ||
      (exportLicenseNumber != null && exportLicenseNumber != undefined && exportLicenseNumber != "") ||
      (eccn != null && eccn != undefined && eccn != "") ||
      (exportInformationCode != null && exportInformationCode != undefined && exportInformationCode != "") ||
      (licenseValue != null && licenseValue != undefined && licenseValue != "")
    ) {
      setLicenseDetailsExpandFlag(true)
    }*/

    let itarExemptionCode = getValues("itarExemptionCode")
    let usmlCategoryCode = getValues("usmlCategoryCode")
    let registrationNumber = getValues("registrationNumber")
    let ddtcQuantity = getValues("ddtcQuantity")
    let ddtcUom = getValues("ddtcUom")
    let eligiblePartyCertificationInd = getValues("eligiblePartyCertificationInd")
    let significantMilitaryEquipmentInd = getValues("significantMilitaryEquipmentInd")

    if ((itarExemptionCode != null && itarExemptionCode != undefined && itarExemptionCode != "") ||
      (usmlCategoryCode != null && usmlCategoryCode != undefined && usmlCategoryCode != "") ||
      (registrationNumber != null && registrationNumber != undefined && registrationNumber != "") ||
      (ddtcQuantity != null && ddtcQuantity != undefined && ddtcQuantity != "") ||
      (ddtcUom != null && ddtcUom != undefined && ddtcUom != "") ||
      (eligiblePartyCertificationInd != null && eligiblePartyCertificationInd != undefined && eligiblePartyCertificationInd != "") ||
      (significantMilitaryEquipmentInd != null && significantMilitaryEquipmentInd != undefined && significantMilitaryEquipmentInd != "")
    ) {
      setDdtcDetailsExpandFlag(true)
    }

  }, []);

  const usedVehicleDetails = watch("usedVehicleDetails")

  function handleAddUsedVehicleDetails(data) {
    const usedVehicleDetails = [...getValues('usedVehicleDetails'), data].map(
      (material, index) => ({ ...material, _id: index + 1 })
    )

    setValue('usedVehicleDetails', usedVehicleDetails)
  }

  function handleDeleteUsedVehicleDetails(data) {
    const clonedUsedVehicleDetailsDetails = [...getValues('usedVehicleDetails')].filter((material) =>
      material.id ? material.id !== data.id : material._id !== data._id
    )
    const usedVehicleDetails = clonedUsedVehicleDetailsDetails.map(
      (material, index) => ({ ...material, _id: index + 1 })
    )
    setValue('usedVehicleDetails', usedVehicleDetails)
  }

  const pgaDetails = watch("pgaDetails")

  function handleAddPgaDetails(data) {
    const pgaDetails = [...getValues('pgaDetails'), data].map(
      (material, index) => ({ ...material, _id: index + 1 })
    )

    setValue('pgaDetails', pgaDetails)
  }

  function handleDeletePgaDetails(data) {
    const clonedPgaDetailsDetails = [...getValues('pgaDetails')].filter((material) =>
      material.id ? material.id !== data.id : material._id !== data._id
    )
    const pgaDetails = clonedPgaDetailsDetails.map(
      (material, index) => ({ ...material, _id: index + 1 })
    )
    setValue('pgaDetails', pgaDetails)
  }


  const columns = [
    {
      field: 'productId',
      title: getTranslatedText('AES', 'productId')
    },
    {
      field: 'htsNumber',
      title: getTranslatedText('AES', 'htsNumber')
    },
    {
      field: 'licenseExemptionCode',
      title: getTranslatedText('AES', 'licenseExemptionCode')
    },
    {
      field: 'itarExemptionCode',
      title: getTranslatedText('AES', 'itarExemptionCode')
    }
  ]

  function handleApplyTemplate(template) {
    if (!template) {
      reset()
      return
    }

    const {
      productId,
      commodityDescription,
      htsNumber,
      originOfGoods,
      quantity1,
      uom1,
      quantity2,
      uom2,
      valueOfGoods,
      exportInformationCode,
      shippingWeight,
      licenseExemptionCode,
      eccn,
      exportLicenseNumber,
      licenseValue,
      itarExemptionCode,
      registrationNumber,
      eligiblePartyCertificationInd,
      usmlCategoryCode,
      ddtcQuantity,
      ddtcUom,
      significantMilitaryEquipmentInd,
      usedVehicleDetails,
      pgaDetails
    } = template

    setValue('productId', productId, { shouldDirty: true })
    setValue('commodityDescription', commodityDescription || '', { shouldDirty: true })
    setValue('htsNumber', htsNumber || '', { shouldDirty: true })
    setValue('originOfGoods', originOfGoods || '', { shouldDirty: true })
    setValue('quantity1', quantity1 || '', { shouldDirty: true })
    setValue('uom1', uom1 || '', { shouldDirty: true })
    setValue('quantity2', quantity2 || '', { shouldDirty: true })
    setValue('uom2', uom2 || '', { shouldDirty: true })
    setValue('valueOfGoods', valueOfGoods || '', { shouldDirty: true })
    setValue('exportInformationCode', exportInformationCode || '', { shouldDirty: true })
    setValue('shippingWeight', shippingWeight || '', { shouldDirty: true })
    setValue('licenseExemptionCode', licenseExemptionCode || '' , { shouldDirty: true })
    setValue('eccn', eccn || '', { shouldDirty: true })
    setValue('exportLicenseNumber', exportLicenseNumber || '', { shouldDirty: true })
    setValue('licenseValue', licenseValue || '', { shouldDirty: true })
    setValue('itarExemptionCode', itarExemptionCode || '', { shouldDirty: true })
    setValue('registrationNumber', registrationNumber || '', { shouldDirty: true })
    setValue('eligiblePartyCertificationInd', eligiblePartyCertificationInd || '', { shouldDirty: true })
    setValue('usmlCategoryCode', usmlCategoryCode || '', { shouldDirty: true })
    setValue('ddtcQuantity', ddtcQuantity || '', { shouldDirty: true })
    setValue('ddtcUom', ddtcUom || '', { shouldDirty: true })
    setValue('significantMilitaryEquipmentInd', significantMilitaryEquipmentInd || '', { shouldDirty: true })
    setValue('usedVehicleDetails', usedVehicleDetails || '', { shouldDirty: true })

    if ((itarExemptionCode != null && itarExemptionCode != undefined && itarExemptionCode != "") ||
      (usmlCategoryCode != null && usmlCategoryCode != undefined && usmlCategoryCode != "") ||
      (registrationNumber != null && registrationNumber != undefined && registrationNumber != "") ||
      (ddtcQuantity != null && ddtcQuantity != undefined && ddtcQuantity != "") ||
      (ddtcUom != null && ddtcUom != undefined && ddtcUom != "") ||
      (eligiblePartyCertificationInd != null && eligiblePartyCertificationInd != undefined && eligiblePartyCertificationInd != "") ||
      (significantMilitaryEquipmentInd != null && significantMilitaryEquipmentInd != undefined && significantMilitaryEquipmentInd != "")
    ) {
      setDdtcDetailsExpandFlag(true)
    }else{
      setDdtcDetailsExpandFlag(false)
    }

    if(pgaDetails && pgaDetails.length>0){
      for(let i=0; i<pgaDetails.length; i++){
        pgaDetails[i].epaLicenseRequired = pgaDetails[i].epaLicenseRequired == "Y" ? true : false;
      }
    }

    setValue('pgaDetails', pgaDetails || '', { shouldDirty: true })

    trigger()
  }

  return (
    <>
      <Grid container spacing={1}>
        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.saveFlag}>
          <CngCheckboxField
            label={
              <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                {getTranslatedText('AES', 'saveProductFlag')}
              </Typography>
            }
            name='saveFlag'
            onChange={(e) => setValue('saveFlag', e.target.checked)}
            size='small'
            style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.productId}>
          <CngTextField
            required
            name='productId'
            inputProps={{ maxLength: 35 }}
            label={getTranslatedText('AES', 'productId')}
            disabled={disabled}
            onChange={(e) => {
              setValue('productId', e.target.value.toUpperCase(), {
                shouldValidate: true
              })
            }}
            onBlur={(e) => {
              if (e.target.value !== undefined) {
                setValue('productId', e.target.value.trim(), {
                  shouldValidate: true
                })
              }
            }}
            size='small'
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} lg={4}>
          <NaAesProductMasterAutoCompleteField
            name={'productDropdown'}
            label={getTranslatedText('AES', 'productDropdown')}
            disabled={disabled}
            onChange={(_, options) => handleApplyTemplate(options.data)}
            lookupProps={{
              filters: [{
                field: 'partyId',
                operator: EQUAL,
                value: FileForUserGetPartyId()
              }
              ]
            }}
            size='small'
            fullWidth
            disableClearable
            textFieldProps={{
              InputProps: {
                customEndAdornment: () => (
                  <InputAdornment position='end' style={{ marginTop: -16 }}>
                    <IconButton onClick={(event) => {
                      event.stopPropagation()
                      setTemplateDialog(true)
                    }}>
                      <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                        <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                      </Box>
                    </IconButton>
                  </InputAdornment>
                )
              }
            }}
            forcePopupIcon={false}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} lg={8} shouldHide={shouldHideMap.commodityDescription}>
          <CngTextField
            name="commodityDescription"
            label={getTranslatedText('AES', 'commodityDescription')}
            disabled={disabled}
            size='small'
            onChange={(e) => {
              setValue('commodityDescription', e.target.value.toUpperCase(), {
                shouldValidate: true
              })
            }}
            onBlur={(e) => {
              if (e.target.value !== undefined) {
                setValue('commodityDescription', e.target.value.trim(), {
                  shouldValidate: true
                })
              }
            }}
            required
          />
        </CngGridItem>

        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.htsNumber}>
          <CngTextField
            name="htsNumber"
            label={getTranslatedText('AES', 'htsNumber')}
            disabled={disabled}
            size='small'
            onChange={(e) => {
              setValue('htsNumber', e.target.value.toUpperCase(), {
                shouldValidate: true
              })
            }}
            onBlur={(e) => {
              if (e.target.value !== undefined) {
                setValue('htsNumber', e.target.value.trim(), {
                  shouldValidate: true
                })
              }
            }}
            required
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.quantity1}>
          <CngTextField
            name="quantity1"
            label={getTranslatedText('AES', 'quantity1')}
            disabled={disabled}
            size='small'
            onChange={(e) => {
              setValue('quantity1', e.target.value.toUpperCase(), {
                shouldValidate: true
              })
            }}
            onBlur={(e) => {
              if (e.target.value !== undefined) {
                setValue('quantity1', e.target.value.trim(), {
                  shouldValidate: true
                })
              }
            }}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.uom1}>
          <CngCodeMasterAutocompleteField
            name="uom1"
            label={getTranslatedText('AES', 'uom1')}
            disabled={disabled}
            size='small'
            codeType='AES_UOM'
            lookupProps={{
              label: (record) => `${record.code};${record.descriptionEn}`
            }}
            key={uom1}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.valueOfGoods}>
          <CngTextField
            name="valueOfGoods"
            label={getTranslatedText('AES', 'valueOfGoods')}
            disabled={disabled}
            size='small'
            onChange={(e) => {
              setValue('valueOfGoods', e.target.value.toUpperCase(), {
                shouldValidate: true
              })
            }}
            onBlur={(e) => {
              if (e.target.value !== undefined) {
                setValue('valueOfGoods', e.target.value.trim(), {
                  shouldValidate: true
                })
              }
            }}
          />
        </CngGridItem>

        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.quantity2}>
          <CngTextField
            name="quantity2"
            label={getTranslatedText('AES', 'quantity2')}
            disabled={disabled}
            size='small'
            onChange={(e) => {
              setValue('quantity2', e.target.value.toUpperCase(), {
                shouldValidate: true
              })
            }}
            onBlur={(e) => {
              if (e.target.value !== undefined) {
                setValue('quantity2', e.target.value.trim(), {
                  shouldValidate: true
                })
              }
            }}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.uom2}>
          <CngCodeMasterAutocompleteField
            name="uom2"
            label={getTranslatedText('AES', 'uom2')}
            disabled={disabled}
            size='small'
            codeType='AES_UOM'
            lookupProps={{
              label: (record) => `${record.code};${record.descriptionEn}`
            }}
            key={uom2}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.shippingWeight}>
          <CngTextField
            name="shippingWeight"
            label={getTranslatedText('AES', 'shippingWeight')}
            disabled={disabled}
            size='small'
            onChange={(e) => {
              setValue('shippingWeight', e.target.value.toUpperCase(), {
                shouldValidate: true
              })
            }}
            onBlur={(e) => {
              if (e.target.value !== undefined) {
                setValue('shippingWeight', e.target.value.trim(), {
                  shouldValidate: true
                })
              }
            }}
            required={modeOfTransport == '10' || modeOfTransport == '11' || modeOfTransport == '12' || modeOfTransport == '20' || modeOfTransport == '21' || modeOfTransport == '30' || modeOfTransport == '31' || modeOfTransport == '40' || modeOfTransport == '41'}
          />
        </CngGridItem>

        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.originOfGoods}>
          <CngCodeMasterAutocompleteField
            name="originOfGoods"
            label={getTranslatedText('AES', 'originOfGoods')}
            disabled={disabled}
            size='small'
            codeType='AES_ORIGIN_OF_GOODS'
            lookupProps={{
              label: (record) => `${record.code};${record.descriptionEn}`
            }}
            key={originOfGoods}
          />
        </CngGridItem>

        <Grid item xs={12}>
          <CollapsibleSection
            defaultExpanded={licenseDetailsExpandFlag}
            onExpandedChange={(expanded) => {
            }}
            title={getTranslatedText('AES', 'licenseDetails')}
          >
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.licenseExemptionCode}>
                <CngCodeMasterAutocompleteField
                  name="licenseExemptionCode"
                  label={getTranslatedText('AES', 'licenseExemptionCode')}
                  disabled={disabled}
                  size='small'
                  codeType='AES_LICENSE_EXEMPTION'
                  lookupProps={{
                    label: (record) => `${record.code};${record.descriptionEn}`
                  }}
                  required
                  key={licenseExemptionCode}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.exportLicenseNumber}>
                <CngTextField
                  name="exportLicenseNumber"
                  label={getTranslatedText('AES', 'exportLicenseNumber')}
                  disabled={disabled}
                  size='small'
                  onChange={(e) => {
                    setValue('exportLicenseNumber', e.target.value.toUpperCase(), {
                      shouldValidate: true
                    })
                  }}
                  onBlur={(e) => {
                    if (e.target.value !== undefined) {
                      setValue('exportLicenseNumber', e.target.value.trim(), {
                        shouldValidate: true
                      })
                    }
                  }}
                  required
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.eccn}>
                <CngTextField
                  name="eccn"
                  label={getTranslatedText('AES', 'eccn')}
                  disabled={disabled}
                  size='small'
                  onChange={(e) => {
                    setValue('eccn', e.target.value.toUpperCase(), {
                      shouldValidate: true
                    })
                  }}
                  onBlur={(e) => {
                    if (e.target.value !== undefined) {
                      setValue('eccn', e.target.value.trim(), {
                        shouldValidate: true
                      })
                    }
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.exportInformationCode}>
                <CngCodeMasterAutocompleteField
                  name="exportInformationCode"
                  label={getTranslatedText('AES', 'exportInformationCode')}
                  disabled={disabled}
                  size='small'
                  codeType='AES_EXPORT_INFORMATION'
                  lookupProps={{
                    label: (record) => `${record.code};${record.descriptionEn}`
                  }}
                  required
                  key={exportInformationCode}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.licenseValue}>
                <CngTextField
                  name="licenseValue"
                  label={getTranslatedText('AES', 'licenseValue')}
                  disabled={disabled}
                  size='small'
                  onChange={(e) => {
                    setValue('licenseValue', e.target.value.toUpperCase(), {
                      shouldValidate: true
                    })
                  }}
                  onBlur={(e) => {
                    if (e.target.value !== undefined) {
                      setValue('licenseValue', e.target.value.trim(), {
                        shouldValidate: true
                      })
                    }
                  }}
                />
              </CngGridItem>
            </Grid>
          </CollapsibleSection>
        </Grid>

        <Grid item xs={12}>
          <CollapsibleSection
            defaultExpanded={ddtcDetailsExpandFlag}
            onExpandedChange={(expanded) => {
            }}
            title={getTranslatedText('AES', 'ddtcDetails')}
          >
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.itarExemptionCode}>
                <CngCodeMasterAutocompleteField
                  name="itarExemptionCode"
                  label={getTranslatedText('AES', 'itarExemptionCode')}
                  disabled={disabled}
                  size='small'
                  codeType='AES_ITAR_EXEMPTION'
                  lookupProps={{
                    label: (record) => `${record.code};${record.descriptionEn}`
                  }}
                  key={getValues("itarExemptionCode")}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.usmlCategoryCode}>
                <CngCodeMasterAutocompleteField
                  name="usmlCategoryCode"
                  label={getTranslatedText('AES', 'usmlCategoryCode')}
                  disabled={disabled}
                  size='small'
                  codeType='AES_USML_CATEGORY'
                  lookupProps={{
                    label: (record) => `${record.code};${record.descriptionEn}`
                  }}
                  key={getValues("usmlCategoryCode")}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.registrationNumber}>
                <CngTextField
                  name="registrationNumber"
                  label={getTranslatedText('AES', 'registrationNumber')}
                  disabled={disabled}
                  size='small'
                  onChange={(e) => {
                    setValue('registrationNumber', e.target.value.toUpperCase(), {
                      shouldValidate: true
                    })
                  }}
                  onBlur={(e) => {
                    if (e.target.value !== undefined) {
                      setValue('registrationNumber', e.target.value.trim(), {
                        shouldValidate: true
                      })
                    }
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.ddtcQuantity}>
                <CngTextField
                  name="ddtcQuantity"
                  label={getTranslatedText('AES', 'ddtcQuantity')}
                  disabled={disabled}
                  size='small'
                  onChange={(e) => {
                    setValue('ddtcQuantity', e.target.value.toUpperCase(), {
                      shouldValidate: true
                    })
                  }}
                  onBlur={(e) => {
                    if (e.target.value !== undefined) {
                      setValue('ddtcQuantity', e.target.value.trim(), {
                        shouldValidate: true
                      })
                    }
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.ddtcUom}>
                <CngCodeMasterAutocompleteField
                  name="ddtcUom"
                  label={getTranslatedText('AES', 'ddtcUom')}
                  disabled={disabled}
                  size='small'
                  codeType='AES_UOM'
                  lookupProps={{
                    label: (record) => `${record.code};${record.descriptionEn}`
                  }}
                  key={getValues("ddtcUom")}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.eligiblePartyCertificationInd}>
                <CngSelectField
                  name="eligiblePartyCertificationInd"
                  label={getTranslatedText('AES', 'eligiblePartyCertificationInd')}
                  disabled={disabled}
                  size='small'
                  items={[
                    { text: "--" + getTranslatedText('AES', 'select') + "--", value: '' },
                    { text: "Y;" + getTranslatedText('AES', 'yes'), value: 'Y' },
                    { text: "N;" + getTranslatedText('AES', 'no'), value: 'N' },
                  ]}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.significantMilitaryEquipmentInd}>
                <CngSelectField
                  name="significantMilitaryEquipmentInd"
                  label={getTranslatedText('AES', 'significantMilitaryEquipmentInd')}
                  disabled={disabled}
                  size='small'
                  items={[
                    { text: "--" + getTranslatedText('AES', 'select') + "--", value: '' },
                    { text: "Y;" + getTranslatedText('AES', 'yes'), value: 'Y' },
                    { text: "N;" + getTranslatedText('AES', 'no'), value: 'N' },
                  ]}
                />
              </CngGridItem>
            </Grid>
          </CollapsibleSection>
        </Grid>

        <Grid item xs={12}>
          <CollapsibleSection
            defaultExpanded={usedVehicleDetails.length > 0}
            onExpandedChange={(expanded) => {
              const { initialValues } = UsedVehicleDetailsFormProperties.formikProps
              setValue('usedVehicleDetails', expanded ? [{ ...initialValues, _id: 1 }] : [])
            }}
            title={getTranslatedText('AES', 'usedVehicleDetails')}
          >
            <Grid container spacing={1}>
              {usedVehicleDetails.map((goods, index) => {
                const isLast = usedVehicleDetails.length === 1
                return (
                  <React.Fragment key={goods._id || goods.id || index}>
                    <Grid item xs={12}>
                      <UsedVehicleDetailsEntry
                        index={index}
                        isLast={isLast}
                        onAddUsedVehicleDetails={handleAddUsedVehicleDetails}
                        onDeleteUsedVehicleDetails={handleDeleteUsedVehicleDetails}
                        usedVehicleDetails={goods}
                        showNotification={showNotification}
                      />
                    </Grid>
                    {usedVehicleDetails.length !== index + 1 && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </React.Fragment>
                )
              })}
            </Grid>
          </CollapsibleSection>
        </Grid>
        <Grid item xs={12}>
          <CollapsibleSection
            defaultExpanded={pgaDetails.length > 0}
            onExpandedChange={(expanded) => {
              const { initialValues } = PgaDetailsFormProperties.formikProps
              setValue('pgaDetails', expanded ? [{ ...initialValues, _id: 1 }] : [])
            }}
            title={getTranslatedText('AES', 'pgaDetails')}
          >
            <Grid container spacing={1}>
              {pgaDetails.map((goods, index) => {
                const isLast = pgaDetails.length === 1
                return (
                  <React.Fragment key={goods._id || goods.id || index}>
                    <Grid item xs={12}>
                      <PgaDetailsEntry
                        index={index}
                        isLast={isLast}
                        onAddPgaDetails={handleAddPgaDetails}
                        onDeletePgaDetails={handleDeletePgaDetails}
                        pgaDetails={goods}
                        showNotification={showNotification}
                      />
                    </Grid>
                    {pgaDetails.length !== index + 1 && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </React.Fragment>
                )
              })}
            </Grid>
          </CollapsibleSection>
        </Grid>
      </Grid>
      <TemplateSelectDialog
        columns={columns}
        defaultSorts={[{ field: 'productId', direction: 'ASC' }]}
        fetch={{ url: AesProductMasterApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        onApplyTemplate={handleApplyTemplate}
        onClose={() => setTemplateDialog(false)}
        open={templateDialog}
        search={{ field: 'productId', operator: LIKE }}
        title={getTranslatedText('AES', 'productMasterTemplate')}
      />
    </>
  )
}

const CommodityDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default CommodityDetailsFormProperties
