import makeValidationSchema from './PgaDetailsMakeValidationSchema'
import React, { useContext, useEffect, useState } from 'react'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'
import { AesTranslationContext } from '../contexts/AESTranslationContext'
import Switch from 'src/components/Switch'
import { useFormContext } from 'react-hook-form'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngSelectField,
      CngTextField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  _commodityDetailsId: 0,
  commodityId: "",
  pgaId: "",
  amsCertificateNumber: "",
  epaLicenseRequired: "",
  epaConsentNumber: "",
  rcraHazardousWasteTrackingNumber: "",
  epaNetQuantity: "",
  epaUom: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  index
}) {
  const { getTranslatedText } = useContext(AesTranslationContext)
  const { getValues, setValue, reset, watch, trigger } = useFormContext()

  const [amsDetails, setAmsDetails] = useState(false)
  const [epaDetails, setEpaDetails] = useState(false)
  const [epaLicenseRequiredDetails, setEpaLicenseRequiredDetails] = useState(false)

  const pgaId = watch(typeof index === 'number' ? `pgaDetails.${index}.pgaId` : 'pgaId')
  const epaLicenseRequired = watch(typeof index === 'number' ? `pgaDetails.${index}.epaLicenseRequired` : 'epaLicenseRequired')

  useEffect(() => {
    if (pgaId == "AM1") {
      setAmsDetails(true)
      setEpaDetails(false)
      setValue(typeof index === 'number' ? `pgaDetails.${index}.epaLicenseRequired` : 'epaLicenseRequired', null)
      setValue(typeof index === 'number' ? `pgaDetails.${index}.epaConsentNumber` : 'epaConsentNumber', "")
      setValue(typeof index === 'number' ? `pgaDetails.${index}.rcraHazardousWasteTrackingNumber` : 'rcraHazardousWasteTrackingNumber', "")
      setValue(typeof index === 'number' ? `pgaDetails.${index}.epaNetQuantity` : 'epaNetQuantity', null)
      setValue(typeof index === 'number' ? `pgaDetails.${index}.epaUom` : 'epaUom', "")
      setEpaLicenseRequiredDetails(false)
    }
    if (pgaId == "EP1") {
      setEpaDetails(true)
      setAmsDetails(false)
      setValue(typeof index === 'number' ? `pgaDetails.${index}.amsCertificateNo` : 'amsCertificateNo', "")
    }
  }, [pgaId]);

  useEffect(()=>{

    if(epaLicenseRequired!=null && epaLicenseRequired!=undefined && epaLicenseRequired!="" && epaLicenseRequired == true){
      setEpaLicenseRequiredDetails(true)
    }else{
      setEpaLicenseRequiredDetails(false)
    }
  },[epaLicenseRequired])


  return (

    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.pgaId}>
        <CngCodeMasterAutocompleteField
          name={typeof index === 'number' ? `pgaDetails.${index}.pgaId` : 'pgaId'}
          label={getTranslatedText('AES', 'pgaId')}
          disabled={disabled}
          size='small'
          codeType='AES_PGA'
          required
        />
      </CngGridItem>

      {amsDetails && (
        <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.amsCertificateNumber}>
          <CngTextField
            name={typeof index === 'number' ? `pgaDetails.${index}.amsCertificateNumber` : 'amsCertificateNumber'}
            label={getTranslatedText('AES', 'amsCertificateNumber')}
            disabled={disabled}
            size='small'
            onChange={(e) => {
              setValue(typeof index === 'number' ? `pgaDetails.${index}.amsCertificateNumber` : 'amsCertificateNumber', e.target.value.toUpperCase(), {
                shouldValidate: true
              })
            }}
            onBlur={(e) => {
              if (e.target.value !== undefined) {
                setValue(typeof index === 'number' ? `pgaDetails.${index}.amsCertificateNumber` : 'amsCertificateNumber', e.target.value.trim(), {
                  shouldValidate: true
                })
              }
            }}
            required
          />
        </CngGridItem>
      )}

      {epaDetails && (
       
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.epaLicenseRequired}>
            <Switch
              name={typeof index === 'number' ? `pgaDetails.${index}.epaLicenseRequired` : 'epaLicenseRequired'}
              label={getTranslatedText('AES', 'epaLicenseRequired')}
              disabled={disabled}
              size='small'
              fullWidth
            />
          </CngGridItem>
      )}
      { epaLicenseRequiredDetails && (
        <>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.epaConsentNumber}>
            <CngTextField
              name={typeof index === 'number' ? `pgaDetails.${index}.epaConsentNumber` : 'epaConsentNumber'}
              label={getTranslatedText('AES', 'epaConsentNumber')}
              disabled={disabled}
              size='small'
              onChange={(e) => {
                setValue(typeof index === 'number' ? `pgaDetails.${index}.epaConsentNumber` : 'epaConsentNumber', e.target.value.toUpperCase(), {
                  shouldValidate: true
                })
              }}
              onBlur={(e) => {
                if (e.target.value !== undefined) {
                  setValue(typeof index === 'number' ? `pgaDetails.${index}.epaConsentNumber` : 'epaConsentNumber', e.target.value.trim(), {
                    shouldValidate: true
                  })
                }
              }}
              required
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.rcraHazardousWasteTrackingNumber}>
            <CngTextField
              name={typeof index === 'number' ? `pgaDetails.${index}.rcraHazardousWasteTrackingNumber` : 'rcraHazardousWasteTrackingNumber'}
              label={getTranslatedText('AES', 'rcraHazardousWasteTrackingNumber')}
              disabled={disabled}
              size='small'
              onChange={(e) => {
                setValue(typeof index === 'number' ? `pgaDetails.${index}.rcraHazardousWasteTrackingNumber` : 'rcraHazardousWasteTrackingNumber', e.target.value.toUpperCase(), {
                  shouldValidate: true
                })
              }}
              onBlur={(e) => {
                if (e.target.value !== undefined) {
                  setValue(typeof index === 'number' ? `pgaDetails.${index}.rcraHazardousWasteTrackingNumber` : 'rcraHazardousWasteTrackingNumber', e.target.value.trim(), {
                    shouldValidate: true
                  })
                }
              }}
              required
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.epaNetQuantity}>
            <CngTextField
              name={typeof index === 'number' ? `pgaDetails.${index}.epaNetQuantity` : 'epaNetQuantity'}
              label={getTranslatedText('AES', 'epaNetQuantity')}
              disabled={disabled}
              size='small'
              onChange={(e) => {
                setValue(typeof index === 'number' ? `pgaDetails.${index}.epaNetQuantity` : 'epaNetQuantity', e.target.value.toUpperCase(), {
                  shouldValidate: true
                })
              }}
              onBlur={(e) => {
                if (e.target.value !== undefined) {
                  setValue(typeof index === 'number' ? `pgaDetails.${index}.epaNetQuantity` : 'epaNetQuantity', e.target.value.trim(), {
                    shouldValidate: true
                  })
                }
              }}
              required
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.epaUom}>
            <CngCodeMasterAutocompleteField
              name={typeof index === 'number' ? `pgaDetails.${index}.epaUom` : 'epaUom'}
              label={getTranslatedText('AES', 'epaUom')}
              disabled={disabled}
              size='small'
              codeType='AES_UOM'
              lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
              required
            />

          </CngGridItem>
        </>
      )}
    </Grid>
  )
}

const PgaDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default PgaDetailsFormProperties
