import React, { useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { components } from 'cng-web-lib'
import { Grid, Typography } from '@material-ui/core'
import { A6TranslationContext } from '../contexts/A6TranslationContext'
import { NaCustomsOfficeAutocompleteField } from 'src/components/na'
import CngSection from 'src/components/cngcomponents/CngSection'

const {
    form: {
        field: {
            CngTextField,
            CngSwitchField,
            CngPortAutocompleteField,
            CngDateTimeField,
        }
    },
    CngGridItem,
} = components


function A6ScheduleSection(props) {
    const { disabled, shouldHideMap, editPage } = props
    const { setValue } = useFormContext();
    const { getTranslatedText } = useContext(A6TranslationContext)

    const templateFlag = useWatch({ name: "templateFlag" })
    const customsProc = useWatch({ name: "customsProc" })
    const a6aStatus = useWatch({ name: "a6aStatus" })

    let isEditable = false;
    const templateStatus = a6aStatus === "1002" ? true : false
    if (editPage && templateFlag && templateStatus) {
        isEditable = true;
    }

    return (
        <Grid item xs={12}>
            <CngSection title={getTranslatedText('A6', 'schedule')}
                subheader={
                    <Typography color='textSecondary' variant='caption'>
                        <Typography variant='inherit' color='error'>*</Typography>
                        Mandatory fields
                    </Typography>
                }>
                <Grid container spacing={1}>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.departurePort}>
                        <CngPortAutocompleteField
                            name='departurePort'
                            label={getTranslatedText('A6', 'departurePort')}
                            disabled={disabled}
                            lookupProps={{ label: (record) => (`${record.code};${record.descriptionEn}`) }}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.departureTerminal}>
                        <CngTextField
                            name='departureTerminal'
                            label={getTranslatedText('A6', 'departureTerminal')}
                            inputProps={{ maxLength: 30 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('departureTerminal', e.target.value.toUpperCase()) }}
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.departurePier}>
                        <CngTextField
                            name='departurePier'
                            inputProps={{ maxLength: 4 }}
                            label={getTranslatedText('A6', 'departurePier')}
                            disabled={disabled}
                            onBlur={(e) => { setValue('departurePier', e.target.value.toUpperCase()) }}
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.etd}>
                        <CngDateTimeField
                            name='etd'
                            format='YYYY-MM-DD HH:mm'
                            label={getTranslatedText('A6', 'etd')}
                            disabled={disabled}
                            required
                            size='small'
                        />
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6} shouldHide={customsProc !== '22'}>
                        <NaCustomsOfficeAutocompleteField
                            name='customsOfficeOfExit'
                            label={getTranslatedText('A6', 'customsOfficeOfExit')}
                            disabled={disabled}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={customsProc !== '22'}>
                        <CngPortAutocompleteField
                            name='portOfDischarge'
                            label={getTranslatedText('A6', 'portOfDischarge')}
                            disabled={disabled}
                            lookupProps={{ label: (record) => (`${record.code};${record.descriptionEn}`) }}
                            required
                            size='small'
                        />
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6} shouldHide={customsProc === '22'}>
                        <CngPortAutocompleteField
                            name='arrivalPort'
                            label={getTranslatedText('A6', 'arrivalPort')}
                            disabled={disabled}
                            lookupProps={{ label: (record) => (`${record.code};${record.descriptionEn}`) }}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={customsProc === '22'}>
                        <CngTextField
                            name='arrivalTerminal'
                            label={getTranslatedText('A6', 'arrivalTerminal')}
                            inputProps={{ maxLength: 30 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('arrivalTerminal', e.target.value.toUpperCase()) }}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={customsProc === '22'}>
                        <CngTextField
                            name='arrivalPier'
                            label={getTranslatedText('A6', 'arrivalPier')}
                            inputProps={{ maxLength: 4 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('arrivalPier', e.target.value.toUpperCase()) }}
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={customsProc === '22'}>
                        <CngDateTimeField
                            name='eta'
                            format='YYYY-MM-DD HH:mm'
                            label={getTranslatedText('A6', 'eta')}
                            disabled={disabled}
                            required
                            size='small'
                        />
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.templateFlag}>
                        <CngSwitchField
                            name='templateFlag'
                            label={getTranslatedText('A6', 'templateFlag')}
                            disabled={disabled || isEditable}
                        />
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6} shouldHide={!templateFlag}>
                        <CngTextField
                            name='templateName'
                            label={getTranslatedText('A6', 'templateName')}
                            inputProps={{ maxLength: 50 }}
                            disabled={disabled || isEditable}
                            onBlur={(e) => { setValue('templateName', e.target.value.toUpperCase()) }}
                            required
                            size='small'
                        />
                    </CngGridItem>
                </Grid>
            </CngSection>
        </Grid>
    )
}

export default A6ScheduleSection