import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import DbAdminStatusConfigKeys from 'src/constants/locale/key/DbAdminStatusConfig'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  let errMsgAlphaNumericNoSpecialChar = translate(
    Namespace.DB_ADMIN_STATUS_CONFIG,
    DbAdminStatusConfigKeys.Validations.ERROR_MSG_ALPHA_NUMERIC_NO_SPECIAL_CHAR
  )

  let errMsgMaxLength = translate(
    Namespace.DB_ADMIN_STATUS_CONFIG,
    DbAdminStatusConfigKeys.Validations.ERROR_MSG_MAX_LENGTH
  )

  let regexAlphaNumeric= "^[a-zA-Z0-9 ,+\\-$#@%&()!'`^_:;.?\\[\\]\\{\\}\\/=\"\\|\\\\ ]+$"

  return Yup.object({
    module: Yup.string().nullable().required(requiredMessage),
    type: Yup.string().nullable().required(requiredMessage),
    searchCriteria1: Yup.string().nullable()
    .when(['module','type'], {
      is: (module,type) => {
        if((module == "ACEHW" && type == "MANIFEST") || 
          (module == "ACIHW" && type == "MANIFEST") ||
          (module == "ACIOCN" && type == "A6") ||
          (module == "ACIOCN" && type == "A6A") ||
          (module == "SHARED" && type == "CREW_MASTER") ||
          (module == "SHARED" && type == "EQUIPMENT_MASTER") ||
          (module == "SHARED" && type == "CONVEYANCE_MASTER") ||
          (module == "FDAPN" && type == "FDA_PN") ||
          (module == "EHBL" && type == "CLOSE_MSG")
        ){
          return true;
        }
        return false;
      },
      then: Yup.string().nullable().matches(regexAlphaNumeric,errMsgAlphaNumericNoSpecialChar).required(requiredMessage),
    }).matches(regexAlphaNumeric,errMsgAlphaNumericNoSpecialChar),
    searchCriteria2: Yup.string().nullable().when(['module','type', 'searchCriteria1'], {
      is: (module,type,searchCriteria1) => {
        if (((module == "ACEHW" && type == "SHIPMENT") || (module == "EHBL" && type == "HOUSE_BILL")) && searchCriteria1 == null) {
          return true;
        }
        return false;
      },
      then: Yup.string().nullable().matches(regexAlphaNumeric,errMsgAlphaNumericNoSpecialChar).required(requiredMessage)
    }).matches(regexAlphaNumeric,errMsgAlphaNumericNoSpecialChar),
    currentStatus: Yup.string().nullable().when('actionCode', {
      is: (actionCode) => (actionCode === "update"),
      then: Yup.string().nullable().required(requiredMessage)
    }),
    newStatus: Yup.string().nullable().when('actionCode', {
      is: (actionCode) => (actionCode === "update"),
      then: Yup.string().nullable().required(requiredMessage)
    }),
    userId: Yup.string().nullable().required(requiredMessage)
  })
}

export default makeValidationSchema
