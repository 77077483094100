import { constants, useServices, useTranslation } from 'cng-web-lib'

import AnnouncementApiUrls from 'src/apiUrls/AnnouncementApiUrls'
import React, { useRef, useState } from 'react'
import AnnouncementKeys from 'src/constants/locale/key/Announcement'
import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment'
import pathMap from '../../paths/pathMap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AlertDialog from '../../components/aciacehighway/AlertDialog'
import { generatePath } from 'react-router'
import Table from '../../components/aciacehighway/Table'
import _ from 'lodash'

const {
  filter: { BETWEEN, IN, LIKE }
} = constants

function TablePage(props) {
  const { history, showNotification } = props

  const [alertDialog, setAlertDialog] = useState({ open: false, announcement: null })
  const tableRef = useRef(null)
  const { deleteRecord } = useServices()
  const { translate } = useTranslation(Namespace.ANNOUNCEMENT)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let id = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.ID
    )
    let content = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.CONTENT
    )
    let startDate = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.START_DATE
    )
    let endDate = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.END_DATE
    )
    let priority = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.PRIORITY
    )
    let active = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.ACTIVE
    )
    let createdBy = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.ANNOUNCEMENT,
      AnnouncementKeys.UPDATED_DATE
    )

    return {
      id,
      content,
      startDate,
      endDate,
      priority,
      active,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
    }
  }

  const columns = [
    {
      field: "content",
      title: translatedTextsObject.content,
    },
    {
      field: "startDate",
      title: translatedTextsObject.startDate,
      render: (data) =>
          data.startDate && moment.utc(data.startDate).format('YYYY-MM-DD')
    },
    {
      field: "endDate",
      title: translatedTextsObject.endDate,
      render: (data) =>
          data.endDate && moment.utc(data.endDate).format('YYYY-MM-DD')
    },
    {
      field: "priority",
      title: translatedTextsObject.priority,
    },
    {
      field: "active",
      title: translatedTextsObject.active,
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.content,
      type: 'textfield',
      name: 'content',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.startDate,
      type: 'daterange',
      name: 'startDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.endDate,
      type: 'daterange',
      name: 'endDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.priority,
      type: 'textfield',
      name: 'priority',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.active,
      type: 'checkbox',
      name: 'active',
      operatorType: IN,
      options: [
        {
          fullWidth: true,
          label: 'Active',
          value: 'true',
          filterValue: {
            value: 'true'
          }
        },
        {
          fullWidth: true,
          label: 'Inactive',
          value: 'false',
          filterValue: {
            value: 'false'
          }
        },
      ]
    },
  ]

  function handleDeleteAnnouncement() {
    if (!_.isEmpty(alertDialog.announcement)) {
      deleteRecord.execute(
        AnnouncementApiUrls.DELETE,
        alertDialog.announcement,
        () => {
          showNotification('success', 'Delete succeeded')
          setAlertDialog({ open: false, announcement: null })

          if (tableRef.current?.performRefresh) {
            tableRef.current.performRefresh()
          }
        },
        (error) => console.log(error)
      )
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.ANNOUNCEMENT_ADD_VIEW)
            },
            label: 'Create announcement'
          }
        ]}
        columns={columns}
        compact
        exportData={{ url: AnnouncementApiUrls.EXPORT }}
        fetch={{ url: AnnouncementApiUrls.GET }}
        filters={filters}
        onRowClick={(rowData) =>
          history.push(
            generatePath(pathMap.ANNOUNCEMENT_EDIT_VIEW, { id: rowData.id })
          )
        }
        rowActions={[
          {
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: 'Edit',
            onClick: (rowData) =>
              history.push(
                generatePath(pathMap.ANNOUNCEMENT_EDIT_VIEW, { id: rowData.id })
              )
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: 'Delete',
            onClick: (rowData) => setAlertDialog({ open: true, announcement: rowData })
          }
        ]}
        showNotification={showNotification}
        persistSettings
        tableRef={tableRef}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={alertDialog.open}
        onClose={() => setAlertDialog({ open: false, announcement: null })}
        onCancel={() => setAlertDialog({ open: false, announcement: null })}
        onConfirm={handleDeleteAnnouncement}
        title={translatedTextsObject.deleteButton}
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default TablePage
