import makeValidationSchema from './UsedVehicleDetailsMakeValidationSchema'
import React, { useContext } from 'react'
import {
  components,
} from 'cng-web-lib'
import { AesTranslationContext } from '../contexts/AESTranslationContext'
import { NaAceStateCodeAutoCompleteField } from 'src/components/na'
import { useFormContext } from 'react-hook-form'
import { Card, CardContent, Grid } from '@material-ui/core'


const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngSelectField,
      CngTextField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  _commodityDetailsId: 0,
  commodityId: "",
  vehicleIdType: "",
  vehicleIdNumber: "",
  vehicleTitleNumber: "",
  vehicleTitleState: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  index
}) {

  const { getTranslatedText } = useContext(AesTranslationContext)
  const { getValues, setValue, reset, watch, trigger } = useFormContext()

  return (

    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.vehicleIdType}>
        <CngCodeMasterAutocompleteField
          name={typeof index === 'number' ? `usedVehicleDetails.${index}.vehicleIdType` : 'vehicleIdType'}
          label={getTranslatedText('AES', 'vehicleIdType')}
          disabled={disabled}
          size='small'
          codeType='AES_VEHICLE_ID_TYPE'
          lookupProps={{
            label: (record) => `${record.code};${record.descriptionEn}`
          }}
          required
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.vehicleIdNumber}>
        <CngTextField
          name={typeof index === 'number' ? `usedVehicleDetails.${index}.vehicleIdNumber` : 'vehicleIdNumber'}
          label={getTranslatedText('AES', 'vehicleIdNumber')}
          disabled={disabled}
          size='small'
          onChange={(e) => {
            setValue(typeof index === 'number' ? `usedVehicleDetails.${index}.vehicleIdNumber` : 'vehicleIdNumber', e.target.value.toUpperCase(), {
              shouldValidate: true
            })
          }}
          onBlur={(e) => {
            if (e.target.value !== undefined) {
              setValue(typeof index === 'number' ? `usedVehicleDetails.${index}.vehicleIdNumber` : 'vehicleIdNumber', e.target.value.trim(), {
                shouldValidate: true
              })
            }
          }}
          required
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.vehicleTitleNumber}>
        <CngTextField
          name={typeof index === 'number' ? `usedVehicleDetails.${index}.vehicleTitleNumber` : 'vehicleTitleNumber'}
          label={getTranslatedText('AES', 'vehicleTitleNumber')}
          disabled={disabled}
          size='small'
          onChange={(e) => {
            setValue(typeof index === 'number' ? `usedVehicleDetails.${index}.vehicleTitleNumber` : 'vehicleTitleNumber', e.target.value.toUpperCase(), {
              shouldValidate: true
            })
          }}
          onBlur={(e) => {
            if (e.target.value !== undefined) {
              setValue(typeof index === 'number' ? `usedVehicleDetails.${index}.vehicleTitleNumber` : 'vehicleTitleNumber', e.target.value.trim(), {
                shouldValidate: true
              })
            }
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.vehicleTitleState}>
        <NaAceStateCodeAutoCompleteField
          name={typeof index === 'number' ? `usedVehicleDetails.${index}.vehicleTitleState` : 'vehicleTitleState'}
          label={getTranslatedText('AES', 'vehicleTitleState')}
          disabled={disabled}
          size='small'
          countryCode='US'
        />
      </CngGridItem>
    </Grid>
  )
}

const UsedVehicleDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default UsedVehicleDetailsFormProperties
