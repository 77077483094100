import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import AesShipmentKeys from 'src/constants/locale/key/AesShipment'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const errMsgAlphaSpace = translate(
    Namespace.AES_SHIPMENT,
    AesShipmentKeys.ValidationMessage.ERROR_MSG_ALPHA_SPACE
  )

  const errMsgAlphaNumericSpace = translate(
    Namespace.AES_SHIPMENT,
    AesShipmentKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_SPACE
  )
  const errMsgAlphaNumericWithSpecialChar = translate(
    Namespace.AES_SHIPMENT,
    AesShipmentKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_SPECIAL_CHAR
  )

  const errMsgNumeric = translate(
    Namespace.AES_SHIPMENT,
    AesShipmentKeys.ValidationMessage.ERROR_MSG_NUMERIC
  )

  const errMsgMinLength = translate(
    Namespace.AES_SHIPMENT,
    AesShipmentKeys.ValidationMessage.ERROR_MSG_MIN_LENGTH
  )
  const errMsgMaxLength = translate(
    Namespace.AES_SHIPMENT,
    AesShipmentKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )
  const regexAlphaSpace = '^[a-zA-Z ]*$'
  const regexNumeric = '^[0-9]*$'
  const regexAlphaNumericSpace = '^[a-zA-Z0-9 ]*$'
  const regexAlphaNumericWithSpecialChar =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

  return Yup.object({
    partyType: Yup.string().nullable().required(requiredMessage),
    partyIdentifierType: Yup.string()
      .when('partyType', {
        is: (value) => value == 'F' || value == 'E',
        then: Yup.string().required(requiredMessage),
        otherwise: Yup.string().nullable()
      }).nullable(),
    partyIdentifier: Yup.string()
      .when('partyType', {
        is: (value) => value == 'F' || value == 'E',
        then: Yup.string().nullable().required(requiredMessage).matches(regexAlphaNumericSpace, errMsgAlphaNumericSpace).max(11, errMsgMaxLength + " 11"),
        otherwise: Yup.string().nullable().matches(regexAlphaNumericSpace, errMsgAlphaNumericSpace).max(11, errMsgMaxLength + " 11")
      }).nullable(),
    name: Yup.string().nullable().required(requiredMessage).matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(30, errMsgMaxLength + " 30"),
    contactFirstName: Yup.string().nullable().matches(regexAlphaSpace, errMsgAlphaSpace).max(13, errMsgMaxLength + " 13"),
    contactLastName: Yup.string()
      .when('partyType', {
        is: (value) => value == 'E',
        then: Yup.string().nullable().required(requiredMessage).matches(regexAlphaSpace, errMsgAlphaSpace).max(20, errMsgMaxLength + " 20"),
        otherwise: Yup.string().nullable().matches(regexAlphaSpace, errMsgAlphaSpace).max(20, errMsgMaxLength + " 20"),
      }).nullable(),
    addressLine1: Yup.string()
      .when('partyType', {
        is: (value) => value == 'F' || value == 'E' || value == 'I',
        then: Yup.string().nullable().required(requiredMessage).matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(32, errMsgMaxLength + " 32"),
        otherwise: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(32, errMsgMaxLength + " 32"),
      }).nullable(),
    addressLine2: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(32, errMsgMaxLength + " 32"),
    contactPhoneNumber: Yup.string()
      .when('partyType', {
        is: (value) => value == 'F' || value == 'E',
        then: Yup.string().nullable().required(requiredMessage).matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(13, errMsgMaxLength + " 13"),
        otherwise: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(13, errMsgMaxLength + " 13"),
      }).nullable(),
    cityName: Yup.string().nullable().required(requiredMessage).matches(regexAlphaNumericSpace, errMsgAlphaNumericSpace).max(25, errMsgMaxLength + " 25"),
    stateCode: Yup.string()
      .when(['partyType', 'countryCode'], {
        is: (partyType, countryCode) => (partyType == 'F' || partyType == 'E') && countryCode == 'US',
        then: Yup.string().nullable().required(requiredMessage),
        otherwise: Yup.string().nullable()
      }).nullable(),
    postalCode: Yup.string()
      .when('countryCode', {
        is: (value) => value == 'US',
        then: Yup.string().nullable().required(requiredMessage).matches(regexAlphaNumericSpace, errMsgAlphaNumericSpace).max(9, errMsgMaxLength + " 9"),
        otherwise: Yup.string().nullable().matches(regexAlphaNumericSpace, errMsgAlphaNumericSpace).max(9, errMsgMaxLength + " 9")
      }).nullable(),
    countryCode: Yup.string().nullable().required(requiredMessage),
    usppiIrsNumber: Yup.string().nullable().matches(regexAlphaNumericSpace, errMsgAlphaNumericSpace).max(9, errMsgMaxLength + " 9"),
    usppiIrsNumberType: Yup.string().nullable(),
    ultimateConsigneeType: Yup.string()
      .when('partyType', {
        is: (value) => value == 'C',
        then: Yup.string().nullable().required(requiredMessage),
        otherwise: Yup.string().nullable()
      }).nullable(),
    email: Yup.string().email().nullable().max(100, errMsgMaxLength + " 100"),
    partyId: Yup.string().nullable().matches(regexAlphaNumericSpace, errMsgAlphaNumericSpace).max(11, errMsgMaxLength + " 11"),
  })
}

export default makeValidationSchema
